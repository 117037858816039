import {addClickEvent, getScrollTop, setScrollTop, time, toggleClass} from '../helpers';
import GenericView from '../GenericView';

export default class passengers extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'passengers'), data);
        this.start_uri = this.uri;
        this.uri = this.endpoint_uri = 'passengers/';
        this.btn_main_add = document.getElementById('btn-add-passenger').innerHTML;
        this.passenger_new_class = 'passenger';
    }

    bindLinks() {
        super.bindLinks();
        addClickEvent(this.view, '[data-bind="btn-main-add"]', () => this.add());
        addClickEvent(this.view, '[data-bind="next"]', () => this.pagination());
    }

    pagination() {
        this.scrollTop = document.body.scrollTop;
        const that = this;
        const button = this.view.querySelector('[data-bind="next"]');
        button.disabled = true;
        this.get_next(() => {
            button.disabled = false;
            const scrollTop = getScrollTop();
            that.render();
            setScrollTop(scrollTop);
        });
    }

    get(forceUpdate, callback, fail) {
        this.app.subscribe('passengers/\\d+/$', this);
        this._get(forceUpdate, callback, fail, false);
        return this;
    }

    get_next(callback) {
        return this._get(true, callback, null, true);
    }

    _get(forceUpdate, callback, fail, newpage) {
        const that = this;
        const params = [];
        let params_uri = '';
        if (newpage && this.data.pagination) {
            params.push(`offset=${this.data.pagination.offset}`);
        }
        if (this.data.filter) {
            params.push(`filter=${this.data.filter}`);
        }
        if (this.data.search) {
            params.push(`search=${encodeURIComponent(this.data.search)}`);
        }
        if (params.length) {
            params_uri += `?${params.join('&')}`;
        }
        const url = this.endpoint_uri + params_uri;

        const proccess = (response, update_cache) => {
            if (update_cache) {
                that.app.dataStorage.set(url, response);
                that.updated = time();
            }
            if (!that.data) {
                that.data = {}
            }
            if (response.pagination) {
                response.pagination.offset += response.data.length;
                response.pagination.next =
                    response.pagination.offset < response.pagination.count;
            }
            that.data.pagination = response.pagination;
            if (!newpage) {
                that.data.data = []
            }
            response.data.forEach((psg, index) => {
                const psgUri = `${this.uri + psg.id}/`;
                if (update_cache) {
                    this.app.dataStorage.set(psgUri, {data: psg});
                }
                const obj = this.app.initObject(psgUri, 'passenger', {
                    data: psg,
                    forceUpdate: update_cache
                }, update_cache);
                obj.parent = that;
                that.data.data.push(obj);
            });
            that.relations = {
                passenger: that.data.data
            };
            if (callback) callback();
        };
        const _backend = () => {
            this.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + url,
                callback: (response) => {
                    that.loading(false);
                    proccess(response, true);
                },
                error(status, data) {
                    that.onNetworkError(status, data);
                    if (typeof (fail) === 'function') fail();
                }
            });
        };

        if (forceUpdate) {
            _backend();
        } else {
            this.app.dataStorage.get(url, cache => {
                proccess(cache);
                this.app.revalidateData(this);
            }, _backend);
        }

        return this;
    }

    add() {
        const new_uri = this.app.getUriForNewInstanse('passengers/');
        const new_psg = this.app.initObject(new_uri, this.passenger_new_class, {
            data: {}
        });
        new_psg.parent = this;
        new_psg.is_new = true;

        this.data.edit_passenger = new_psg;
        new_psg.get(false, () => {
            this.render();
            new_psg.edit(true);
        });
    }

    edit(status = true) {
        toggleClass(this.view, 'active-thread-edit', status);
        this.app.hideToolTip();
    }

    relation_update(relation) {
        const index = this.data.data.indexOf(relation);
        if (index < 0) {
            this.data.data.unshift(relation);
            this.render();
        } else if (index > -1 && relation.is_deleted) {
            this.data.data.splice(index, 1);
            this.render();
        }
    }
}
