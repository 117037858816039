import {
    addClickEvent,
    addEvent,
    deepcopy,
    is_empty,
    is_expired,
    time,
    toggleClass
} from './helpers';
import autoComplete from './autocomplete';

export default class GenericComponent {
    constructor(uri, app, className, data) {
        this.uri = uri;
        this.app = app;
        this.data = data || {data: {}};
        this.scrollTop = 1;
        this.events = {};
        this.template = className || 'unknown_view';
        this.title = className;
        this.className = className;
        this.relations = {};
        this.min_loading_time = 400;
        this.fake_loading_delay = 200;
        this.getTemplateTranslations();
    }

    checkView(event) {
        const view = event && event.target
            && event.target.closest(`.subcomponent[data-uri="${this.uri}"]`);
        if (view && view !== this.view) {
            this.view = view;
            this.render();
            return true;
        }

        return false;
    }

    bindLinks() {
        this.app.bindLinks(this.view);
        addClickEvent(this.view, '[data-bind="btn-refresh"]', event => {
            event.preventDefault();
            this.refresh();
        });
        addClickEvent(this.view, '[data-bind="btn-cancel"]', event => {
            event.preventDefault();
            this.destroy();
        });
        addClickEvent(this.view, '[data-bind="btn-delete"]', event => {
            event.preventDefault();
            this.checkView(event);
            this.delete();
        });
        addClickEvent(this.view, '[data-bind="btn-edit"]', event => {
            event.preventDefault();
            this.checkView(event);
            this.edit(true);
        });
        addClickEvent(this.view, '[data-bind="btn-stop-edit"]', event => {
            event.preventDefault();
            this.checkView(event);
            this.edit(false, null, event.target && event.target.dataset.scroll);
        });
        addClickEvent(this.view, '[data-bind="reportbug"]', event => {
            this.reportbug(event);
        });
        addClickEvent(this.view, '[data-bind="tabs"] [data-target]', event => {
            const target = event.target.closest('[data-target]');
            const activeTab = target.closest('[data-bind="tab"]');
            const tabs = target.closest('[data-bind="tabs"]');
            const tabsContent = tabs.parentElement
                .querySelector('[data-bind="tab-content"]');
            const isActive = tabs.dataset.closeble &&
                activeTab.classList.contains('active');
            Array.prototype.forEach.call(
                tabs.querySelectorAll('[data-bind="tab"]'),
                tab => toggleClass(
                    tab, 'active',
                    activeTab === tab && !isActive
                )
            );
            Array.prototype.forEach.call(
                tabsContent.querySelectorAll('[data-tab]'),
                tab => toggleClass(
                    tab, 'in',
                    tab.dataset.tab === target.dataset.target && !isActive
                )
            );
        });
        addClickEvent(this.view, '[data-bind="buyCheckins"]', () => {
            this.app.platform.sendEvent('click_buy_checkins');
            this.app.platform.buyCheckins();
        });
    }

    reportbug() {
        this.app.error({
            title: this.app.lang.bugTitle,
            data: {message_human: ' ', message_code: 'UserBugReport'},
            show_report: true
        })
    }

    edit(status = true) {
        this.data.edit_mode = status;
        if (!status && this.is_new) {
            this.delete();
        } else {
            this.render();
        }
    }

    getTemplateTranslations() {
        const template = document.getElementById(this.template);
        if (!template) return;
        Object.keys(template.dataset).forEach(key => {
            this[key] = template.dataset[key];
        });
    }

    render(level = 0) {
        const msg = `rendering ${level} ${this.uri}`;
        // if (this.app.settings.dev) console.time(msg);
        const template = document.getElementById(this.template);
        if (!template) {
            console.error(`Can't find template ${this.template} when rendering ${this.uri}`);
            return;
        }
        if (level > 10) {
            console.error(`maximum render deep exceed ${level} when rendering ${this.uri}`);
            return;
        }
        const className = `obj-${this.template}`;

        this.data.is_mobile = this.app.platform && this.app.platform.is_mobile;
        this.data.uri = this.uri;
        this.data.is_new = this.is_new;
        this.data.user = this.app.settings.user_profile || {};
        this.data.config = this.app.settings;

        if (this.parent) {
            this.data.parent_edit_mode = this.parent.data.edit_mode;
        }
        if (!this.view) {
            this.view = document
                .querySelector(`.${className}[data-uri="${this.uri}"]`);
            if (!this.view) {
                console.error(`Can't render component ${this.uri} because view not found`);
                if (this.app.settings.dev) console.timeEnd(msg);
                return;
            }
        }
        this.view.innerHTML = this.app.Mustache.render(
            template.innerHTML,
            this.data
        );

        this.bindLinks();
        this.initForm();
        this.app.renderIcons(this.view);

        this.renderSubcomponents(this.view, level + 1);
    }

    renderSubcomponents(container, level) {
        container = container || this.view;
        container.querySelectorAll('.subcomponent').forEach((view) => {
            const sub = this.app.initObject(view.dataset.uri, view.dataset.class);
            sub.parent = this;
            sub.view = view;
            if (!sub.is_initialized) {
                sub.is_initialized = true;
                sub.get(false, () => sub.render(0));
            } else {
                sub.render(0);
            }
        });
    }

    update() {
        if (this.events.update && this.events.update.length) {
            this.events.update.forEach((callback) => {
                callback();
            });
        }
        this.app.getSubscriptions(this.uri).forEach((obj) => {
            obj.relation_update(this);
        });
    }

    get(forceUpdate, callback, fail) {
        if (callback) callback();
        return this;
    }

    refresh() {
        this.get(true, () => {
            this.render();
        });
    }

    show() {
        console.warn(`Unimplemented method show for ${this.className}`);
    }

    delete(callback) {
        const message = this.deleteConfirmation
            || this.app.lang.deleteConfirmation;
        if (!this.is_new || (this.changes && this.changes.length)) {
            if (!this.app.confirm(message)) return;
        }

        const that = this;
        const onDelete = function onDelete(data) {
            that.loading(false);
            if (that.events.destroy) {
                that.events.destroy.forEach(func => func())
            }
            that.destroy();
            if (typeof (callback) === 'function') {
                callback(data);
            }
        };
        if (!this.is_new) {
            this.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + this.uri,
                method: 'DELETE',
                callback: onDelete,
                error: (status, data) => this.submitOnError(null, status, data)
            });
        } else {
            onDelete();
        }
    }


    on(event_type, callback) {
        if (!this.events[event_type]) {
            this.events[event_type] = [];
        }
        this.events[event_type].push(callback);
    }

    off(event_type, callback) {
        if (!this.events[event_type]) {
            return;
        }
        if (!callback) {
            this.events[event_type] = [];
        }
        const i = this.events[event_type].indexOf(callback);
        if (i > -1) {
            this.events[event_type].splice(i, 1);
        }
    }

    relation_update(relation) {
        let relations = this.relations[relation.className];
        if (typeof (relations) === 'function') {
            relations = relations(relation);
        }
        console.log(`relation_update on ${this.uri}`);
        if (!relations) {
            console.log(`relation not found ${relation.className}`);
            return;
        }
        if (!('length' in relations)) {
            const copy = deepcopy(relation.data.data);
            Object.keys(relations).forEach(key => {
                delete relations[key]
            });
            Object.keys(copy).forEach(key => {
                relations[key] = copy[key]
            });
        } else {
            const index = relations.indexOf(relation);
            if (relation.is_deleted && index > -1) {
                relations.splice(index, 1);
            }
            if (!relation.is_deleted && index === -1) {
                if (this.relation_update_unshift) {
                    relations.unshift(relation.data.data);
                } else {
                    relations.push(relation.data.data);
                }
            }
            if (!relation.is_deleted && index > -1) {
                relations[index] = relation.data.data;
            }
        }
        this.render();
    }

    toolTip(input, text) {
        input.dataset.title = text;
        if (input.dataset.toggle !== 'tooltip') {
            input.dataset.toggle = 'tooltip';
            this.app.bindTooltips(input);
        }
    }

    inputError(input, error) {
        const group = input.closest('[data-bind="form-group"]') || input;
        toggleClass(group, 'has-error', true);
        group.dataset.error = error;
    }

    inputRemoveError(input) {
        const group = input.closest('[data-bind="form-group"]') || input;
        toggleClass(group, 'has-error', false);
        delete group.dataset.error;
    }

    inputRequired(input) {
        const group = input.closest('[data-bind="form-group"]') || input;
        toggleClass(group, 'has-required', true);
        group.dataset.error = this.app.lang.requested;
    }

    inputRemoveRequired(input) {
        const group = input.closest('[data-bind="form-group"]') || input;
        toggleClass(group, 'has-required', false);
        delete group.dataset.title;
    }

    loading(flag = true) {
        if (this.app.is_revalidating && flag) return;
        let obj = this;
        while (obj && (!obj.view || obj.is_new)) {
            obj = obj.parent;
        }
        if (!obj || !obj.loading) {
            this.app.loading(flag);
            return;
        }
        if (flag) {
            if (obj === this) {
                this.loading_start = time();
                this.view.classList.add('loading');
            } else {
                obj.loading(flag);
            }
        } else {
            let loading_holder = obj.view.closest('.loading');
            let timeout = 1;
            if (obj.loading_start) {
                if (!is_expired(obj.loading_start, this.min_loading_time / 1000)) {
                    timeout = this.fake_loading_delay;
                }
                delete obj.loading_start;
            }
            setTimeout(() => {
                loading_holder = obj.view.closest('.loading') || loading_holder;
                if (loading_holder) loading_holder.classList.remove('loading')
            }, timeout);
        }
    }

    serializeControl(el) {
        const name = el.name;
        let value;
        switch (el.type) {
            case 'checkbox':
                value = el.checked;
                break;
            default:
                value = el.dataset.value ? el.dataset.value : el.value;
        }
        if (el.classList.contains('flatpickr-input') || el.type === 'date') {
            value = value.replace(/(\d+)\.(\d+)\.(\d+)/, '$3-$2-$1');
        }
        return value;
    }

    serializeChanges(form) {
        const that = this;
        if (!form) form = that.view;
        let data = {};
        const addValue = function (data, name, value) {
            if (name) {
                data[name] = value;
            }
            return data;
        };
        if (that.changes && that.changes.length) {
            data = that.changes.reduce((data, el) => {
                let value = that.serializeControl(el);
                if (value === '') value = null;
                return addValue(data, el.name, value);
            }, {});
        }
        return data;
    }

    serialize(form) {
        const that = this;
        let data = {};
        if (!form) form = that.view;
        const addValue = function (data, name, value) {
            if (!value) return;
            if (name && data[name]) {
                if (
                    typeof (data[name]) !== 'object' ||
                    !('length' in data[name])
                ) {
                    data[name] = new Array(data[name]);
                }
                data[name].push(value);
            } else {
                data[name] = value;
            }
            return data;
        };
        form.querySelectorAll('input,select,textarea').forEach(el => {
            addValue(data, el.name, that.serializeControl(el))
        });
        if (this.data.data && this.data.data.id) {
            data.id = this.data.data.id;
        }
        return data;
    }

    submitOnError(form, status, data) {
        this.loading(false);

        if (status === 0) {
            this.app.alertOffline(true);
            return;
        }

        if (
            status === 422 && data && (
                data.message_code === 'ValidationError'
                || data.message_code === 'FormError'
            )
        ) {
            const elements = form
                ? form.elements
                : this.view.querySelectorAll('input,select,textarea');

            this.validation = data;
            const validation = deepcopy(data.data);
            Array.prototype.forEach.call(elements, element => {
                if (validation[element.name]) {
                    this.inputError(
                        element,
                        Array.prototype.join.call(validation[element.name], '\n')
                    );
                }
            });
            const root = form || this.view;
            this.app.scrollTo(root.querySelector('.has-error'));
            return;
        }

        if (status === 422 && data && data.message_code === 'InvalidData') {
            this.app.loading(false);
            this.app.error({status, data});
            return;
        }

        this.onNetworkError(status, data);
    }

    onNetworkError(status, data) {
        this.loading(false);

        switch (status) {
            case 0:
                this.app.alertOffline();
                break;
            case 403:
            case 401:
                this.app.authFailed(this.app.lang.wrongtoken, this.uri);
                break;
            default:
                this.app.error({status, data});
        }
    }

    validateForm(form) {
        const root = form || this.view;
        let status = true;
        root.querySelectorAll('.required').forEach(input => {
            if (input.name && !input.value) {
                status = false;
                this.inputRequired(input);
            }
        });
        return status;
    }

    formSubmit(form, event) {
        event.preventDefault();
        if (!this.validateForm(form)) {
            const root = form || this.view;
            this.app.scrollTo(root.querySelector('.has-required'));
            return;
        }
        const data = this.serializeChanges(form);
        if (is_empty(data)) {
            console.log(`No changes in ${this.uri}`);
            this.edit(false);
            return;
        }
        const form_url = form.getAttribute('action') ||
            (this.is_new ? this.uri.replace(/new\/?$/, '') : this.uri);
        this.loading(true);
        this.app.ajax({
            url: this.app.settings.endpoints + form_url,
            method: this.is_new ? 'POST' : 'PUT',
            data: {data},
            callback: (data) => {
                this.changes = [];
                this.loading(false);
                this.data = this.converter ? this.converter(data) : data;
                this.update();
                if (this.is_new) {
                    const new_uri = this.uri.replace(/new\/?$/, `${this.data.data.id}/`);
                    this.app.changeObjectURI(this, new_uri);
                    this.is_new = false;
                    this.app.platform.sendEvent(`${this.className}_created`);
                }
                this.app.dataStorage.set(this.uri, data);
                this.updated = time();
                this.get(false, () => {
                    this.edit(false);
                    this.render();
                    this.show();
                });
                this.formSaved();
            },
            error: (status, data) => {
                this.submitOnError(form, status, data);
            }
        });
    }

    formSaved() {
        const alertbox = this.view.querySelector('[data-bind="alert-success"]');
        if (alertbox) {
            alertbox.classList.remove('hidden');
            alertbox.classList.add('in');
            setTimeout(() => {
                alertbox.classList.remove('in');
                setTimeout(() => {
                    alertbox.classList.add('hidden');
                }, 1000);
            }, 2000);
        }
    }

    formSubmitToggle(type = false) {
        const form = this.view.querySelector('form');
        if (form) {
            form.querySelectorAll('[type="submit"],[type="reset"]').forEach((button) => {
                button.disabled = type;
            });
        }
    }

    initForm() {
        const that = this;
        if (typeof (this.changes) === 'undefined') {
            this.changes = [];
        }
        if (this.changes.length) {
            this.formSubmitToggle(false);
            this.changes = this.changes.map(
                el => this.view.querySelector(`[name="${el.name}"]`) || el
            );
        }
        this.view.querySelectorAll('[data-toggle="buttons"] button, [data-bind="checkbox"]')
            .forEach(btn => {
                const input = btn.querySelector('input[type="checkbox"]')
                    || btn.querySelector('input[type="radio"]');
                if (input) toggleClass(btn, 'active', input.checked);
            });
        const onChange = function (event) {
            if (
                this.type === 'text'
                && this.classList.contains('flatpickr-input')
                && !event.is_flatpickr_trusted) {
                return;
            }
            if (this.dataset.ac) {
                if (!this.dataset.ready) {
                    return;
                }
                delete this.dataset.ready;
            }
            if (that.data.data[this.name] === this.value) {
                return;
            }
            if (that.changes.indexOf(this) === -1) {
                that.changes.push(this);
            }
            if (that.auto_serialize) {
                that.data.data[this.name] = that.serializeControl(this);
            }
            that.inputRemoveError(this);
            that.inputRemoveRequired(this);
            that.formSubmitToggle(false);
        };
        addClickEvent(this.view, '[data-toggle="buttons"] button', event => {
                const target = event.target.closest('[data-toggle="buttons"] button');
                const checkbox = target.querySelector('input[type="checkbox"]');
                const radio = target.querySelector('input[type="radio"]');
                const group = target.closest('[data-toggle="buttons"]');
                if (checkbox) {
                    checkbox.checked = !checkbox.checked;
                    toggleClass(target, 'active', checkbox.checked);
                    onChange.call(checkbox, event);
                } else if (radio) {
                    group.querySelectorAll(`[name="${radio.name}"]`)
                        .forEach((el) => {
                            el.checked = false;
                        });
                    radio.checked = true;
                    group.querySelectorAll('button')
                        .forEach((el) => {
                            toggleClass(el, 'active', false);
                        });
                    toggleClass(target, 'active', true);
                    onChange.call(radio, event);
                }
            }
        );
        addClickEvent(this.view, '[data-bind="checkbox"]', function () {
            const checkbox = this.querySelector('input[type="checkbox"]');
            checkbox.checked = !checkbox.checked;
            toggleClass(this, 'active', checkbox.checked);
            checkbox.dispatchEvent(new UIEvent('change', {
                bubbled: false,
                cancelable: true
            }));
        });

        this.view.querySelectorAll('select[data-inital_value]').forEach((select) => {
            const tmp = Array.prototype.find.call(
                select.options,
                opt => opt.value === select.dataset.inital_value
            );
            if (tmp) tmp.selected = true;
        });

        this.initDateControls(this.view, onChange);
        this.initAutocompleteControls(this.view);

        this.view.querySelectorAll('input[placeholder]').forEach(input => {
            if (input.type === 'hidden') return;
            const div = document.createElement('div');
            const placeholder = document.createElement('span');
            div.className = 'placeholder';
            input.parentElement.insertBefore(div, input);
            div.appendChild(input);
            div.appendChild(placeholder);
            placeholder.textContent = input.getAttribute('placeholder');
            input.removeAttribute('placeholder');
            const checkInputValue = function checkInputValue() {
                toggleClass(this, 'has-value', this.value);
            };
            addEvent(input, 'input', checkInputValue);
            addEvent(input, 'change', checkInputValue);
            addEvent(input, 'blur', checkInputValue);
            checkInputValue.call(input)
        });
        const inputs = this.view.querySelectorAll('input, select, textarea');
        addEvent(inputs, 'touchstart', event => {
            // mobile safari hack. .focus() not showing keyboard
            // only in touchstart event
            // using .dispatchEvent('touchstart') instead of .focus()
            if (!event.isTrusted) event.target.focus();
        });
        addEvent(inputs, 'change', onChange);
        addEvent(inputs, 'focus',
            event => {
                const card = event.target.closest('[data-bind="card"]');
                const sticky = card && card.querySelector('[data-bind="sticky"]');
                if (card && sticky) setTimeout(() => {
                    try {
                        const card_rect = card.getBoundingClientRect();
                        const sticky_rect = sticky.getBoundingClientRect();
                        if (
                            card_rect.height < sticky_rect.height * 3
                        ) {
                            card.classList.add('no-sticky');
                        }
                    } catch (err) {
                        log.error(err)
                    }
                }, 100);
            }
        );
        addEvent(inputs, 'blur',
            event => {
                const card = event.target.closest('[data-bind="card"]');
                if (card) card.classList.remove('no-sticky');
            }
        );
        addEvent(
            this.view.querySelectorAll('input, textarea'),
            'input',
            onChange
        );
        this.view.querySelectorAll('form').forEach((form) => {
            addEvent(form, 'submit', function (event) {
                event.preventDefault();
                that.formSubmit.call(that, this, event);
            });
            addEvent(form, 'reset', () => {
                that.changes = [];
                that.render();
                that.show();
            });
            if (!that.data || !that.data.data) return;
            if (that.data.data.user_request) {
                for (let name in that.data.data.user_request) {
                    if (name === 'list') {
                        continue;
                    }
                    let input = form.querySelector(`[name="${name}"],[name="${name}_id"]`);
                    if (input) {
                        that.inputRequired(input);
                    }
                }
            }
            if (that.data.data.user_response) {
                const tmpl = document.getElementById('user_response').innerHTML;
                this.app.Mustache.parse(tmpl);
                for (let name in that.data.data.user_response) {
                    if (name === 'list') {
                        continue;
                    }
                    const a = that.data.data.user_response[name];
                    let input = form.querySelector(`[name="${name}"],[name="${name}_id"]`);
                    if (input) {
                        if (a.errors && a.errors.length) {
                            that.inputError(input, a.errors.join('<br>'));
                        }
                        const div = document.createElement('div');
                        div.innerHTML = this.app.Mustache.render(tmpl, {
                            name: input.name,
                            autocomplete: input.dataset.ac,
                            value: a.val
                        });
                        input.parentElement.appendChild(div);
                    }
                }
            }
        });
    }

    initDateControls(root, onChange) {
        const that = this;
        root.querySelectorAll('input[type="date"],input[type="time"]')
            .forEach((element) => {
                const opt = {
                    allowInput: true
                };
                element.setAttribute('autocomplete', 'off');
                if (element.type === 'time' || element.dataset.type === 'time') {
                    opt.enableTime = true;
                    opt.noCalendar = true;
                    opt.time_24hr = true;
                    element.dataset.example = '00:00';
                    element.setAttribute('pattern', '\\d{2}:\\d{2}');
                } else {
                    element.setAttribute('pattern', that.app.flatpickr.defaultConfig.datePattern);
                    element.dataset.example = that.app.flatpickr.defaultConfig.dateExample;
                    opt.parseDate = function (dateStr) {
                        const m = dateStr.match(/(\d+)\-(\d+)\-(\d+)/);
                        if (m) {
                            const d = new Date();
                            d.setDate(1);
                            d.setYear(m[1]);
                            d.setMonth(m[2] - 1);
                            d.setDate(m[3]);
                            return d;
                        }
                        return that.app.flatpickr.parseDate(
                            dateStr,
                            that.app.flatpickr.defaultConfig.dateFormat, true
                        );
                    };
                }
                ['maxDate', 'minDate'].forEach((param) => {
                    if (element.dataset[param]) {
                        opt[param] = element.dataset[param];
                    }
                });
                that.app.flatpickr(element, opt);
                if (element.nextElementSibling) {
                    element.nextElementSibling.removeAttribute('tabindex');
                }
                element.dataset.oldValue = element.value;
                addEvent(element, 'keydown', function (event) {
                    if (event.which === 13 || event.key === 'Enter') {
                        this._flatpickr.setDate(this.value);
                        onChange.call(this, {is_flatpickr_trusted: true});
                        this._flatpickr.close();
                    }
                });
                addEvent(element, 'input', function (event) {
                    if (this.style.display === 'none' || this.type === 'hidden') {
                        return;
                    }
                    const placeholder = this.getAttribute('placeholder');
                    const pattern = this.getAttribute('pattern');
                    const example = this.dataset.example;
                    const selectionStart = 'selectionStart' in this ?
                        this.selectionStart :
                        null;
                    if (!placeholder || !pattern) return;
                    const re = new RegExp(pattern);

                    const new_value = this.value;
                    const old_value = this.dataset.old_value || '';
                    let test_value = new_value + example.substr(new_value.length);
                    if (!test_value.match(re)) {
                        test_value = old_value + example.substr((`${this.value}`).length);
                        if (test_value.match(re)) {
                            this.value = old_value;
                            if (selectionStart) {
                                this.setSelectionRange(selectionStart, selectionStart);
                            }
                        }
                    } else if (placeholder) {
                        const chr = placeholder.substr(new_value.length, 1);
                        if (chr !== '_' && (new_value + chr !== old_value)) {
                            this.value = new_value + chr;
                            if (selectionStart) {
                                this.setSelectionRange(selectionStart + 1, selectionStart + 1);
                            }
                        }
                    }
                    this.dataset.old_value = this.value;
                });
                addEvent(element, 'focus', function onFocus() {
                    if (this.getAttribute('placeholder')) {
                        this.dataset.placeholder = this.getAttribute('placeholder');
                    }
                    ;
                    if (this.type === 'time' || this.dataset.type === 'time') {
                        this.setAttribute('placeholder', '__:__');
                    } else {
                        this.setAttribute(
                            'placeholder',
                            that.app.flatpickr.defaultConfig.datePlaceholder
                        );
                    }
                });
                addEvent(element, 'blur', function onBlur(event) {
                    if (this.dataset.placeholder) {
                        this.setAttribute('placeholder', this.dataset.placeholder);
                        this.dataset.placeholder = '';
                    } else {
                        this.removeAttribute('placeholder');
                    }
                    if (this.dataset.oldValue !== this.value) {
                        this._flatpickr.setDate(this.value);
                        onChange.call(this, {is_flatpickr_trusted: true});
                    }
                });
            });
    }

    initAutocompleteControls(root) {
        const that = this;
        const source = function source(term, response) {
            that.app.ajax({
                url: `${that.app.settings.autocomplete}?name=${this.dataset.ac}&pattern=${encodeURIComponent(term)}`,
                callback(data) {
                    response(data.result, term);
                }
            });
        };
        addEvent(root.querySelectorAll('[data-ac]'), 'focus', event => {
            const target = event.target;
            if (!target.sc) {
                autoComplete({
                    source,
                    selector: target,
                });
            }
            setTimeout(() => {
                if (target.value && target === document.activeElement) {
                    target.setSelectionRange(0, target.value.length);
                }
            }, 100);
        });
    }

    destroy() {
        this.is_deleted = true;
        this.update();
        this.app.deleteObject(this.uri);
        this.app.dataStorage.remove(this.uri);
        this.app.hideToolTip();
    }
}
