import threads from './threads';

export default class threads_upcoming extends threads {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'threads'), data);
    }

    get(forceUpdate, callback, fail) {
        this.data.filter = 'upcoming';
        super.get(forceUpdate, callback, fail);
        this.data.is_upcomming = true;
    }
}
