import {addEvent, date_format} from '../helpers';
import GenericComponent from '../GenericComponent';

export default class flight extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'flight'), data);
        this.auto_serialize = true;
    }

    static getFlightTitle(flight) {
        const departure_code = (flight.departure && flight.departure.code)
            || '-';
        const destination_code = (flight.destination && flight.destination.code)
            || '-';
        const airline_part = flight.airline &&
            `${flight.airline.code}-${flight.flight_num}`;

        return `${departure_code} → ${destination_code}, ${
            date_format(flight.flight_date)
        }${airline_part ? `, ${airline_part}` : ''}`;
    }

    getTitle(flight) {
        return flight.getFlightTitle(this.data.data);
    }

    formSubmit(form, event) {
        if (this.is_new) {
            this.parent.formSubmit(this.view.closest('form'), event);
        }
        super.formSubmit(form, event);
    }

    initForm() {
        super.initForm();
        const that = this;
        addEvent(this.view.querySelectorAll('input[data-ac]'), 'change', function () {
            const name = this.name.replace(/_id|_code$/, '');
            if (!that.data.data[name]) that.data.data[name] = {};
            that.data.data[name].code = this.dataset.value;
            that.data.data[name].name = this.value;
        });
    }
}
