import GenericComponent from '../GenericComponent';
import {addEvent} from '../helpers';

export default class user_menu extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_menu'), data);
    }

    get(forceUpdate, callback) {
        this.data = JSON.parse(
            document.getElementById('user_menu_translations').innerHTML
        );
        this.data.menu.forEach(i => {
            if (!i.title) return;
            i.title = this.app.Mustache.render(
                i.title, this.app.settings.user_profile
            );
        });
        if (callback) callback();
        return this;
    }

    initForm() {
        addEvent(
            this.view.querySelectorAll('[data-bind="btn-logout"]'),
            'click',
            () => {
                if (!this.data.user.email) {
                    alert(this.logoutAlert);
                } else if (this.app.confirm(this.app.lang.logout_confirmation)) {
                    this.app.platform.logout();
                }
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="btn-removetoken"]'),
            'click',
            () => {
                this.app.dataStorage.removeToken(() => {
                    window.location.reload();
                });
            }
        );
    }

    render(level) {
        this.data.menu.forEach(item => {
            item.is_active = item.url === '#' + this.parent.uri;
        });
        super.render(level);
    }
}
