import GenericComponent from '../../GenericComponent';

export default class chat_widget_request extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_request'), data);
    }

    initUserRequest() {
        const checkin = this.data.data.checkin;
        const ur = checkin.user_request;
        if (ur.flight) ur.flight[0].flight = checkin.flights[0];
        if (ur.passengers) {
            ur.passengers.forEach(cpsg => {
                cpsg.psg = checkin.passengers.find(psg => psg.num === cpsg.num);
                cpsg.psg_fields = Object.keys(cpsg).some(
                    key => [
                        'gender', 'middle_name', 'birth_date', 'citizenship', 'residence'
                    ].indexOf(key) > -1
                );
                ['passport', 'visa', 'ffn'].forEach(doc => {
                    cpsg[doc] = Object.keys(cpsg).some(
                        key => key.match(new RegExp('^' + doc))
                    );
                });
                Object.keys(cpsg).forEach(key => {
                    if (typeof (cpsg[key]) == 'object' && cpsg[key].length == 0) {
                        cpsg[key] = true;
                    }
                });
            });
        }
    }

    get(forceUpdate, callback, fail) {
        const checkin = this.data.data.widget.data && this.data.data.widget.data.checkin;
        if (checkin) {
            checkin.user_request = this.data.data.widget.data.user_request;
            this.data.data.checkin = checkin;
            this.data.data.user_request = checkin.user_request;
            const checkinUri = `checkins/${checkin.id}/`;
            if (this.data.isThisLastMessage() && checkinUri) {
                this.data.checkinUri = checkinUri;
            }
            this.initUserRequest();
        }
        if (callback) callback();
        return this;
    }
}
