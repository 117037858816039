import GenericView from './GenericView';

export default class GenericViewOffline extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, className, data);
        this.noPullToRefresh = true;
    }

    show() {
        this.render();
        this.app.showView(this);
    }

    get(forceUpdate, callback) {
        if (callback) callback();
        return this;
    }
}
