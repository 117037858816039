import GenericViewOffline from '../../GenericViewOffline';

export default class user_delete extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_delete'), data);
    }

    delete() {
        this.loading(true);
        this.app.ajax({
            url: this.app.settings.endpoints + 'user/',
            method: 'DELETE',
            callback: () => {
                this.app.platform.sendEvent('user_deleted');
                this.loading(false);
                if (this.data.user.email) {
                    this.app.get('user/after_delete/');
                } else {
                    this.app.platform.logout();
                }
            },
            error: (status, data) => {
                this.loading(false);
                this.submitOnError(null, status, data);
            }
        });
    }
}
