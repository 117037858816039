import threads from './threads';

export default class threads_completed extends threads {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'threads'), data);
    }

    get(forceUpdate, callback, fail) {
        this.data.filter = 'completed';
        super.get(forceUpdate, callback, fail);
        this.data.is_completed = true;
    }
}