import {addClickEvent, addEvent, date_format, deepcopy, is_empty, toggleClass} from '../../helpers';
import flight from '../flight';
import GenericRestComponent from '../../GenericRestComponent';

export default class thread extends GenericRestComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'thread'), data);
        this.bp_uri = this.uri
            //replace checkin_boardings for download bp url
            .replace('checkin_boardings', 'checkins')
            //replace thread  prefix for threads
            .replace(/^threads\/\d+\//, '');
        this.flight_class = 'flight';
        this.checkin_passenger_class = 'checkin_passenger';
        this.max_passengers = 12;
        if (this.data.data) {
            this.data = this.converter(this.data);
        }
        this.init();
    }

    get(forceUpdate, callback, fail) {
        if (this.data.data) {
            if (callback) callback();
            return this;
        } else {
            super.get(forceUpdate, callback, fail)
        }
    }

    init() {
        if (this.data.data) this.is_initialized = true;
        this.app.subscribe(`${this.uri}passengers/[^\\/]+/`, this);
        this.app.subscribe(`${this.uri}seatmap_preference/.*`, this);
        this.app.subscribe('user_settings/seatmap_settings/', this);
        this.app.subscribe(`.*/seatmap/`, this);
    }

    create_checkin_passenger(id, data, forceUpdate) {
        if (!id) id = 'new';
        if (!data) data = {};
        const obj = this.app.initObject(
            `${this.uri}passengers/${id}/`,
            this.checkin_passenger_class,
            data,
            forceUpdate
        );
        obj.data = data;
        obj.is_new = id === 'new';
        obj.parent = this;
        obj.init();
        obj.is_initialized = false;
        return obj;
    }

    relation_update(relation) {
        if (relation.className === this.checkin_passenger_class && relation.is_deleted) {
            const index = this.data.data.passengers.indexOf(relation);
            if (index > -1) {
                this.data.data.passengers.splice(index, 1);
                if (!this.data.data.passengers.length) {
                    this.data.data.passengers.push(
                        this.create_checkin_passenger()
                    );
                }
                this.render();
                this.app.scrollTo(this.view, 'bottom');
            }
        }
        if (relation.className === 'seatmap_settings_component') {
            const rdata = relation.data.data;
            const psg_count = this.data.data.passengers.length;
            let first_strategy = rdata.strategies
                && rdata.strategies[psg_count] && rdata.strategies[psg_count][0];
            if (!first_strategy) return;
            if (first_strategy.name) first_strategy = first_strategy.name;
            this.data.data.strategy = rdata.selection_policies === 'AUTO'
                ? first_strategy
                : rdata.selection_policies;
            this.render();
        }
        if (relation.className === 'seatmap_request') {
            const test_uri = relation.uri.replace(/(flights|seatmap).*/, '');
            if (this.uri.match(test_uri)) {
                relation.data.data.passengers.forEach((psg, i) => {
                    this.data.data.passengers[i].data.data.seat = psg.seat;
                });
                this.render();
            }
        }
    }

    delete() {
        this.edit(false);
        super.delete();
    }

    edit(status = true) {
        const container = this.app.getObject('threads_edit/');
        container.data.thread = status ? this : null;
        container.parent = this.app.current_view;
        container.view = this.app.current_view.view
            .querySelector('.subcomponent[data-uri="threads_edit/"]') || container.view;
        this.data.edit_mode = status;
        if (status) {
            container.render();
        } else {
            const view = this.parent && this.parent.parent
                && this.parent.parent.view.querySelector(`[data-uri="${this.uri}"]`);
            if (view) {
                this.view = view;
                this.render();
            }
            // fix for flatpickr-calendar
            document.querySelectorAll('.flatpickr-calendar').forEach(
                node => node.remove()
            );
        }
        this.parent.edit(status);
    }

    render(level) {
        const getStrategy = (name, psgs) => {
            const seatmap_strategies = this.app.settings.seatmap_strategies;
            const str = seatmap_strategies[psgs]
                ? seatmap_strategies[psgs].find(el => el.id === name)
                : null;
            return str ? str.name : null;
        };

        const psgs = this.data.data.passengers;
        this.data.is_btn_psg_add = (!psgs.length || !psgs[psgs.length - 1].is_new)
            && !this.data.data.is_in_progress
            && psgs.length < this.max_passengers;
        this.data.data.strategy = getStrategy(this.data.data.strategy, psgs.length)
            || this.app.lang[this.data.data.strategy]
            || this.data.data.strategy;
        super.render(level);
        const flight = (
            this.data.data.flights[0] && this.data.data.flights[0].data.data
        );
        const airline = (flight && flight.airline && flight.airline.name) || '';
        const flight_num = (flight && flight.flight_num) || '';
        const flight_date = (flight && date_format(flight.flight_date)) || '';
        this.deleteConfirmation = this.deleteConfirmation
            && this.deleteConfirmation.replace(
                'FLIGHT',
                `${flight_date} ${airline} ${flight_num}`.trim()
            );
        toggleClass(this.view, 'has-required', this.data.data.user_request);
    }

    converter(data) {
        const checkin = data.data;
        const forceUpdate = data.forceUpdate;
        if (forceUpdate) delete data.forceUpdate;
        if (!checkin) {
            console.error('Can\'t use converter on empty checkin');
            return data;
        }
        let result = {
            id: checkin.id,
            thread_id: checkin.thread_id,
            passengers: [],
            flights: [],
            user_response: checkin.user_response,
            status: checkin.status,
            comp_status: checkin.comp_status,
        };
        if (checkin.remote_id) result.remote_id = checkin.remote_id;
        const filesCounter = {};
        const userRequest = {};
        if (!checkin.files) checkin.files = [];

        result.files = checkin.files.map(el => {
            if (!filesCounter[el.filetype]) {
                filesCounter[el.filetype] = 0;
            }
            filesCounter[el.filetype] += 1;
            el.ext = el.fileurl.match(/\.(\w{2,6})$/);
            el.ext = el.ext ? el.ext[1] : '';
            el.fileurl = `${
                this.app.settings.endpoints
            }${this.bp_uri}files/${el.id}/?token=${this.app.token}`;
            el.name = `${(
                el.passengers
                    ? el.passengers.map(
                    psg => `${psg.first_name} ${psg.last_name}`
                    ).join(', ')
                    : `#${filesCounter[el.filetype]}`
            )} (${el.ext === 'pkpass' ? 'Wallet' : el.ext})`;
            return el;
        });
        result.has_booking_confirmation = filesCounter['checkinConfirmation'] > 0;
        if (filesCounter['boardingPass'] || filesCounter['checkinConfirmation']) {
            result.files = result.files.filter(
                file => file.filetype === 'boardingPass'
                    || file.filetype === 'checkinConfirmation'
            );
        } else {
            result.files = result.files.filter(
                file => file.filetype !== 'booking'
            );
        }

        [result.first_flight] = checkin.flights;
        if (checkin.scheduled_checkin_time) {
            [result.schedule_date, result.schedule_time] = checkin
                .scheduled_checkin_time.split(/T|\s+/);
            if (result.schedule_time) {
                result.schedule_time = result.schedule_time
                    .replace(/(\d+:\d+).*/, '$1');
            }
            result.schedule_date_time =
                `${date_format(result.schedule_date)} ${result.schedule_time}`;
        }
        result.is_in_progress = checkin.is_in_progress;
        result.is_success = (result.status === 'success');
        result.is_failed = (result.status === 'failed');
        result.is_finished = result.is_failed || result.is_success;
        result.is_need_payment = (result.status === 'unpaid');
        result.is_add_psg_available = !result.is_success && !result.is_in_progress
            && (checkin.passengers.length < this.max_passengers);
        result.is_delete_available = result.id && !result.is_in_progress;
        result.is_idle = (result.status === 'idle' && !result.is_in_progress);
        result.is_seatmap_request = checkin.status === 'requested_seatmap';
        result.cmd_download = ['failed', 'success'].indexOf(checkin.status) > -1;
        result.cmd_checkin = result.is_idle;
        result.cmd_abort = result.is_in_progress;
        result.cmd_abort_locked = checkin.finish_code === 207;
        result.cmd_deschedule = result.is_idle;
        result.show_flight_date = !result.cmd_checkin || !result.schedule_date;
        result.show_schedule_date = result.schedule_date || !result.cmd_checkin;
        result.strategy = checkin.strategy;
        result.finish_msg = checkin.finish_msg;
        result.finish_comment = checkin.finish_comment;

        const flightInfo = result.first_flight.flight_info;
        if (flightInfo) {
            flightInfo.actual_time = flightInfo.actual_time || flightInfo.estimated_time;
            flightInfo.delayed = flightInfo.scheduled_time && flightInfo.actual_time
                && flightInfo.scheduled_time < flightInfo.actual_time;
        }
        if (
            checkin.last_finished_task
            && parseInt(checkin.last_finished_task.code) >= 300
            && parseInt(checkin.last_finished_task.code) <= 399
        ) {
            result.last_finished_task = checkin.last_finished_task;
        }
        result.seatmap_uri = `${this.uri}seatmap/`;
        result.seatmap_pref_uri = `${this.uri}seatmap_preference/${checkin.passengers.length}/`;
        if (checkin.flights) {
            checkin.flights.forEach(() => {
                if (
                    checkin.user_request && checkin.user_request.flights
                    && checkin.user_request.flights[0]
                    && !is_empty(checkin.user_request.flights[0])
                ) {
                    userRequest.flights = true;
                }
                const obj = this.app.initObject(
                    `${this.uri}flights/${result.first_flight.id ? result.first_flight.id : 'new'}/`,
                    this.flight_class,
                    {
                        data: result.first_flight
                    },
                    forceUpdate
                );
                obj.data.data = result.first_flight;
                obj.is_initialized = false;
                obj.parent = this;
                result.flights.push(obj);
            });
        }
        if (checkin.passengers) {
            checkin.passengers.forEach((cpsg, index) => {
                if (
                    checkin.user_request && checkin.user_request.passengers
                    && checkin.user_request.passengers
                ) {
                    cpsg.user_request = checkin.user_request.passengers.find(
                        psg => psg.num === index + 1
                    );
                    userRequest.passengers = true;
                }
                result.passengers.push(this.create_checkin_passenger(
                    cpsg.id,
                    {
                        data: cpsg,
                        delete_locked: result.is_in_progress,
                        parent_uri: this.uri
                    },
                    forceUpdate
                ));
            });
            result.has_pkpass = checkin.passengers.some(cpsg => cpsg.has_pkpass);
            result.only_one_passenger = (checkin.passengers.length === 1);
        }
        result.one_file = result.files && result.files.length === 1;
        result.many_files = result.files && result.files.length > 1;

        if (
            ('phone_code' in userRequest || 'phone_country' in userRequest) &&
            !('phone' in userRequest)
        ) {
            userRequest.phone = true;
        }

        userRequest.any = userRequest.phone
            || result.is_seatmap_request
            || userRequest.flights
            || userRequest.passengers;
        result.user_request = userRequest.any ? userRequest : false;
        return Object.assign(data, {data: result});
    }

    command(cmd, callback) {
        const flightId = this.data.data.flights[0].data.data.id;
        if (!cmd || !flightId) {
            return;
        }
        this.loading(true);
        const data = {};
        if (cmd === 'schedule') {
            const dtDate = this.serializeControl(
                this.view.querySelector('[name="schedule_date"]')
            );
            const dtTime = this.serializeControl(
                this.view.querySelector('[name="schedule_time"]')
            );
            data.datetime = `${dtDate}T${dtTime}`;
            cmd = 'checkin';
        }
        data.cmd = cmd;
        const afterSubmit = () => {
            this.loading(false);
            if (callback) callback();
        };

        this.app.ajax({
            url: `${this.app.settings.endpoints}${this.uri}flights/${flightId}/${cmd}/`,
            method: 'POST',
            data: {data},
            callback: data => {
                this.app.alert(data.data.message ? data.data.message : data.data.message_human);
                this.submitOnSucccess(data);
                afterSubmit();
            },
            error: (status, data) => {
                afterSubmit();
                this.submitOnError(null, status, data);
            }
        });
    }

    bindLinks() {
        const that = this;
        super.bindLinks();
        addEvent(this.view.querySelectorAll('[name="eticket"]'), 'input',
            event => {
                event.target.value = event.target.value.replace(/\D/g, '');
            }
        );
        addClickEvent(this.view, '[data-cmd]', function cmdClick(event) {
            event.preventDefault();
            const btn = this;
            const btnText = btn.textContent;
            if (btn.dataset.loadingText) {
                btn.textContent = btn.dataset.loadingText;
            }
            btn.disabled = true;
            that.command(btn.dataset.cmd, () => {
                btn.textContent = btnText;
                btn.disabled = false;
                if (['checkin', 'schedule'].indexOf(btn.dataset.cmd) > -1) {
                    const collapse = btn.closest('.collapse');
                    if (collapse) collapse.classList.remove('in');
                }
            });
        });
        addClickEvent(this.view, '[data-bind="btn-add-flight"]', () => {
            const firstFlight = this.data.data.flights[0].data.data;
            const data = deepcopy({
                booking_ref: firstFlight.booking_ref,
                airline: firstFlight.airline,
            });
            const obj = this.app.initObject(
                `${this.uri}flights/new-${this.data.data.flights.length}/`,
                'flight',
                {data}
            );
            obj.parent = this;
            obj.is_new = true;
            this.data.data.flights.push(obj);
            this.render();
        });
        addClickEvent(this.view, '[data-bind="btn-psg-add"]', () => {
            const firstPsg = this.data.data.passengers[0];
            if (firstPsg && firstPsg.is_new) return;
            const newPsg = this.create_checkin_passenger();
            this.data.data.passengers.push(newPsg);
            newPsg.get(false, () => this.render());
        });
        addClickEvent(this.view, '[data-bind="boarding"]', event => {
            if (!this.app.platform.showBoardings(this.data.data)) {
                event.preventDefault();
            }
        });
        addClickEvent(this.view, 'a[download]', event => {
            const id = parseInt(event.target.dataset.id);
            const url = event.target.href;
            const file = this.data.data.files.find(f => f.id === id);
            if (!this.app.platform.showFile(
                id, url, file.ext, file.content_type,
                this.data.data.id, file.filetype
            )) {
                event.preventDefault();
            }
        });
        addClickEvent(this.view, '[data-bind="chat-new-thread"]', event => {
            const target = event.target.closest('[data-bind="chat-new-thread"]');
            const threadType = target.dataset.type;
            this.createNewChatServiceThread(threadType);
        });
        addClickEvent(this.view, '[data-bind="seatmap-settings"]', () => {
            this.app.dialogComponent(
                this.data.data.seatmap_pref_uri,
                'seatmap_settings_component',
                {subclass: 'modal-dialog-seatmap-settings'},
                this
            );
        });
    }

    createNewChatServiceThread(threadType) {
        const chatMessage = document.getElementById(`chatMessage-${threadType}`).innerText;
        const serviceDescription = document.getElementById(`serviceDescription-${threadType}`).innerHTML;
        const title = this[`chat_${threadType}`];
        const chat = this.app.initObject('chats/', 'chat');
        const flightTitle = flight.getFlightTitle(this.data.data.first_flight);

        chat.createNewThread({
            name: flightTitle,
            type: threadType,
            type_name: title,
            prepared: serviceDescription.trim(),
            text: `${chatMessage.trim()} #${this.data.data.id}: ${flightTitle}`,
            checkinId: this.data.data.id
        });
        this.app.get(chat.uri);
    }

    submitOnError(form, status, data) {
        super.submitOnError(form, status, data);
        if (status === 422 && data.data) {
            const validation = this.data.message = data.data;
            ['flights', 'passengers'].forEach(type => {
                if (validation[type]) {
                    validation[type].forEach((message, index) => {
                        if (this.data.data[type] && this.data.data[type][index]) {
                            this.data.data[type][index].submitOnError(
                                null,
                                status,
                                {
                                    message_code: data.message_code,
                                    message: data.message,
                                    data: message
                                }
                            );
                        }
                    });
                }
            });
        }
    }

    submitOnSucccess(data) {
        this.view.classList.remove(`card-${this.data.data.status}`);
        this.data = this.converter({
            data: data.data.checkins.find(
                checkin => this.data.data.id === checkin.id
            ),
            forceUpdate: true
        });
        this.view.classList.add(`card-${this.data.data.status}`);
        this.render();
        this.app.scrollTo(this.view)
    }

    formSubmit(form, event) {
        const checkin = this.data.data;
        const flight = checkin.flights[0];
        const url = `checkins/${checkin.id}/link_checkin_psg_fields/`;
        let callbackCount = 1;
        const data = [];

        const waitCallbacks = () => {
            callbackCount--;
            if (callbackCount) return;
            if (data.length) {
                this.loading(true);
                this.app.ajax({
                    url: `${this.app.settings.endpoints}${url}`,
                    method: 'PUT',
                    data: {data},
                    callback: data => {
                        this.loading(false);
                        this.submitOnSucccess(data);
                    },
                    error: (status, data) => {
                        this.submitOnError(null, status, data);
                    }
                });
            } else {
                this.data.data.passengers.forEach(psg => {
                    psg.data.edit_mode = false;
                });
                this.edit(false);
            }
        };
        flight.formSubmit(form, event);
        this.data.data.passengers.forEach(psg => {
            const part = psg.serializeChanges();
            if (!is_empty(part)) {
                part.psg_id = psg.data.data.id;
                part.flight_id = flight.data.data.id;
                Object.keys(part).forEach(key => {
                    if (key.match(/_id$/)) {
                        part[key] = parseInt(part[key]);
                    }
                });
                data.push(part);
            }
            if (psg.data.passenger) {
                callbackCount++;
                psg.on('update', waitCallbacks);
                psg.data.passenger.formSubmit(null, event);
            }
        });
        waitCallbacks();
    }
}
