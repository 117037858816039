import {time} from './helpers';
import GenericComponent from './GenericComponent';

export default class GenericRestComponent extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, className, data);
    }

    get(forceUpdate, callback, fail) {
        if (!this.app.token) {
            if (typeof (fail) === 'function') fail(403);
            return;
        }
        if (typeof (callback) !== 'function') {
            callback = () => {
            };
        }
        if (typeof (this.converter) !== 'function') {
            this.converter = data => data;
        }
        if (this.is_new) {
            if (!this.data) this.data = {};
            this.render();
            callback();
            return;
        }
        const uri = (this.endpoint_uri || this.uri);
        const _backend = () => {
            this.app.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + uri,
                callback: (data) => {
                    this.app.loading(false);
                    if (!this.data) this.data = {};
                    this.app.dataStorage.set(uri, data);
                    this.data = this.converter(data);
                    this.updated = time();
                    this.needReRender = true;
                    callback();
                },
                error: (status, data) => {
                    this.onNetworkError(status, data);
                    if (typeof (fail) === 'function') fail(status, data);
                }
            });
        };
        if (!forceUpdate) {
            this.app.dataStorage.get(uri, cache => {
                this.data = this.converter(cache);
                this.app.revalidateData(this);
                callback();
            }, _backend);
        } else {
            _backend();
        }

        return this;
    }
}
