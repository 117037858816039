import GenericComponent from '../GenericComponent';
import SeatMap from './common/SeatMap';

export default class seatmap_preview extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'seatmap_preview'), data);
        this.events = {};
    }

    init() {
        if (this.initialized) return;
        const seatmap_preview_presets = document.getElementById('seatmap_preview_presets');
        const seatmap = document.getElementById('seatmap');
        if (seatmap_preview_presets && seatmap) {
            this.preset = JSON.parse(seatmap_preview_presets.innerHTML);
            this.seatmap = new SeatMap({
                seatmap: JSON.parse(JSON.stringify(this.preset)),
                Mustache: this.app.Mustache,
                is_selectable: false,
                is_example: true,
                templates: {
                    seatmap: seatmap.innerHTML,
                }
            });
            this.initialized = true;
        }
    }

    render() {
        this.init();
        super.render(this);
        this.seatmap.container = this.view.querySelector('[data-bind="seatmap-container"]');
        this.seatmap.renderSeatmap();
    }

    on(event_type, callback) {
        this.events[event_type] = callback;
    }

    setCurrentSeats(seats) {
        seats.forEach(seat => {
            this.seatmap.getSeat(seat).type = SeatMap.SEATS_SELECTED;
        });
        this.seatmap.renderSeatmap();
    }

    clearCurrentSeats() {
        this.seatmap.getCurrentSeats().forEach(seat => {
            this.seatmap.setSeat(seat, {type: SeatMap.SEATS_EMPTY});
        });
    }
}
