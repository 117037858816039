import {addEvent} from '../helpers';
import GenericViewOffline from '../GenericViewOffline';

export default class about extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'about'), data);
    }

    bindLinks() {
        super.bindLinks();
        addEvent(this.view.querySelectorAll('[data-bind="tips"]'), 'click',
            () => this.app.platform.showTipDialog()
        );
    }

    render() {
        const urls = {
            ios: 'https://itunes.apple.com/app/1checkin/id1358278386',
            android: 'https://play.google.com/store/apps/details?id=in.och.onecheckin',
        };
        this.data.rateURL = urls[this.app.platform.id];
        super.render();
    }
}
