import {addClickEvent} from './helpers';

export function addResendEmailEvent(component) {
    addClickEvent(component.view, '[data-bind="btn-resend"]', event => {
        component.loading(true);
        component.app.ajax({
            url: component.app.settings.endpoints + 'user_settings/email_aliases/resend/',
            method: 'POST',
            data: {data: {email: event.target.dataset.email}},
            callback: () => {
                component.loading(false);
                alert(component.confirmationResent);
            },
            error: (status, data) => {
                component.submitOnError(null, status, data);
            }
        });
    });
}
