import {addClickEvent, addEvent, toggleClass} from '../helpers';
import GenericComponent from '../GenericComponent';
import passenger_document from './passenger_document';

export default class checkin_passenger extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'checkin_passenger'), data);
        this.auto_serialize = true;
        this.passenger_class = 'passenger';
        this.passenger_document_class = 'passenger_document';
    }

    init() {
        if (!this.data) this.data = {};
        if (!this.data.data) this.data.data = {};
        this.data = this.converter(this.data);
    }

    get(forceUpdate, callback, fail) {
        if (this.is_new) {
            this.data.edit_mode = true;
            this.getPassengers(forceUpdate, callback);
        } else if (callback) callback();
        return this;
    }

    render() {
        const thread_data = this.parent.data.data;
        this.data.is_need_controls = this.passenger || this.is_new;
        this.data.parent_edit_or_edit_mode = this.data.edit_mode
            || this.parent.data.edit_mode;
        this.data.is_cancel_enabled = this.data.parent_edit_or_edit_mode &&
            (this.parent.data.data.passengers.length > 1);
        this.data.data.passengers_count = thread_data.passengers.length;
        this.data.data.is_seatmap_request = thread_data.is_seatmap_request;
        this.data.data.seatmap_uri = thread_data.seatmap_uri;
        this.data.data.seatmap_pref_uri = thread_data.seatmap_pref_uri;
        if (this.is_new && !this.data.passenger) {
            this.getPassengers();
        }
        super.render();
    }

    getPassengers(forceUpdate, callback) {
        const that = this;
        const psg_url = 'passengers/';
        let psgs = !forceUpdate && this.app.getObject(psg_url);

        const onSuccess = function () {
            that.data.user_passengers = [];
            if (psgs.data && psgs.data.data && psgs.data.data.length) {
                that.data.user_passengers = psgs.data.data.filter(
                    psg => !that.parent.data.data.passengers.find(
                        el => el.data.data.id === psg.data.data.id
                    )
                )
            }
            if (typeof (callback) === 'function') {
                callback();
            }
        };
        if (psgs) {
            onSuccess();
        } else {
            psgs = this.app.initObject(psg_url, 'passengers', {});
            psgs.get(forceUpdate, onSuccess);
        }
    }

    create_new_document(type, edit) {
        const tmp = this.create_document(type, 'new', {
            edit_mode: edit,
            is_new: true,
            data: {}
        }, true);
        const psg = this.data.data;
        tmp.is_new = true;
        tmp.on('cancel', () => {
            if (psg[type] && psg[type].filter(rel => !rel.is_new).length) {
                delete this.data.data[`new_${type}`];
            }
            this.render();
        });
        tmp.on('update', () => {
            const key = passenger_document.documentTypeIDKey(type);
            const doc = this.data.data[`new_${type}`];
            this.data.data[type].push(doc);
            this.data.data[key] = doc.data.data.id;
            delete this.data.data[`new_${type}`];
            this.render();
            this.changes.push(this.view.querySelector(`[name="${key}"]`));
        });
        return tmp;
    }

    create_document(type, id, data, is_new) {
        const url = `passengers/${this.data.data.id}/${type}/${id}/`;
        const obj = this.app.initObject(
            url,
            this.passenger_document_class,
            Object.assign({document_type: type}, data),
            is_new
        );
        obj.passenger = obj.parent = this;
        return obj;
    }

    converter(data) {
        const psg = (data && data.data) || {};
        // set id, passenger_id  for all ffns and visas inside passenger
        passenger_document.documentTypes().forEach(type => {
            if (psg[type]) {
                psg[type] = psg[type].map(relation => {
                    if (relation.className === 'passenger_document') {
                        return relation;
                    }
                    const key = passenger_document.documentTypeIDKey(type);
                    relation.id = relation[key];
                    relation.passenger_id = psg.id;
                    return this.create_document(type, relation.id, {
                        data: relation
                    });
                });
            }
            if (!psg[type] || !psg[type].filter(rel => !rel.is_new).length) {
                psg[`new_${type}`] = this.create_new_document(type, false);
            }
        });
        if (psg.user_request) {
            const list = [];
            Object.keys(psg.user_request).sort().forEach(field => {
                let prefix = field.replace(/^([^_]+).*$/, '$1');
                if (['visa', 'ffn', 'passport'].indexOf(prefix) >= 0) {
                    psg.user_request[prefix] = true;
                    if (!(`${prefix}_doc` in psg.user_request)) {
                        const docs_key = passenger_document.documentTypeByIDKey(
                            prefix + '_id'
                        );
                        const doc = psg[docs_key].find(
                            doc => doc.data.data.id === psg[prefix + '_id']
                        );
                        psg.user_request[prefix + '_doc'] = doc;
                        if (doc) {
                            doc.data.user_request = {};
                            Object.keys(psg.user_request).filter(
                                field => field.match(prefix)
                            ).forEach(field => doc.data.user_request[field] = true);
                        }
                    }
                } else {
                    prefix = '';
                }
                const last = list.length && list[list.length - 1];
                if (['num'].indexOf(field) > -1) return;
                const item = {
                    field,
                    prefix,
                    name: this.app.lang[field] || field.replace(/_/g, ' ')
                };
                if (last && (prefix === last.prefix && prefix) && last.sublist) {
                    last.sublist.push(item);
                } else {
                    if (prefix) {
                        item.sublist = [{name: item.name}];
                        item.name = this.app.lang[prefix] || prefix.replace(/_/g, ' ');
                        item.field = prefix;
                    }
                    list.push(item);
                }
            });
            psg.user_request.all_list = list;
            psg.user_request.list = list.filter(
                item => !item.field.match(/(visa|passport|ffn|eticket)/)
            );
        }
        return data || {data: {}};
    }

    initForm() {
        const that = this;
        super.initForm();
        addEvent(this.view.querySelectorAll('[data-bind="user-passengers"]'), 'change',
            function definePassenger(event) {
                const value = parseInt(this.value);
                if (!value) return;
                const psg = that.data.user_passengers.find(
                    psg => psg.data.data.id === value
                );
                that.data.data = psg.data.data;
                if (!that.parent.is_new) {
                    that.formSubmit(null, event);
                } else {
                    that.afterSubmit();
                }
            }
        );
        addEvent(this.view.querySelectorAll('[data-bind="document-selector"]'), 'change',
            function changeDocument(event) {
                if (event.target.value !== 'new') return;
                const type = passenger_document.documentTypeByIDKey(
                    event.target.name
                );
                that.data.data[`new_${type}`] = that.create_new_document(type, true);
                that.render();
            }
        );
        addClickEvent(this.view, '[data-bind="btn-psg-add"]', () => {
            this.create_passenger();
            this.render();
        });
        addClickEvent(this.view, '[data-bind="btn-cancel"]', () => {
            this.destroy();
        });
        addEvent(this.view.querySelectorAll('select[name]'), 'change',
            function onChange() {
                that.data.data[this.name] = parseInt(this.value);
            }
        );
        addClickEvent(this.view, '[data-bind="seat"]', event => {
            event.stopPropagation();
            this.app.dialogComponent(
                this.data.data.seatmap_uri,
                'seatmap_request',
                {subclass: 'modal-dialog-seatmap-settings'},
                this
            )
        });
        addEvent(this.view.querySelectorAll('[name="eticket"]'), 'input',
            event => {
                event.target.value = (event.target.value + '').replace(/[^\d\w]+/, '');
            }
        );
    }

    create_passenger() {
        this.data.passenger = this.app.initObject(
            `passengers/${this.data.data.id || 'new'}/`,
            this.passenger_class,
            {
                edit_mode: true,
                in_thread: true,
                is_new: this.is_new,
                data: this.data.data
            },
            true
        );
        this.data.passenger.is_new = this.is_new;
        this.data.passenger.parent = this;
        this.data.passenger.on('update', () => {
            this.data.data = Object.assign(
                this.data.data,
                this.data.passenger.data.data
            );
            passenger_document.documentTypes().forEach(key => {
                const arr = [];
                this.data.data[key].forEach(obj => {
                    if (obj.data && obj.data.data) {
                        arr.push(obj.data.data);
                    }
                });
                this.data.data[key] = arr;
            });
            delete this.data.passenger;
            this.update();
            if (!this.parent.is_new) {
                this.formSubmit(null, event);
            } else {
                this.afterSubmit();
            }
        });
    }

    delete() {
        const parent = this.parent;
        if (parent.is_new) {
            this.app.scrollTo(this.view);
            this.destroy();
        } else {
            this.deleteConfirmation = this.deleteConfirmation
                && this.deleteConfirmation.replace(
                    'PSG',
                    `${this.data.data.first_name} ${this.data.data.last_name}`
                );
            super.delete((data) => {
                parent.submitOnSucccess(data);
            });
        }
    }

    edit(flag) {
        if (!flag && this.data.passenger) {
            delete this.data.passenger;
        }
        if (!flag && this.is_new) {
            this.destroy();
        } else {
            this.data.edit_mode = typeof (flag) === 'undefined'
                ? true
                : flag;
            this.render();
        }
    }

    afterSubmit() {
        this.app.changeObjectURI(
            this,
            `${this.parent.uri}passengers/${this.data.data.id}/`
        );
        this.is_new = false;
        this.data.edit_mode = false;
        this.parent.data.is_btn_psg_add = (
            this.parent.data.data.passengers.length < this.parent.max_passengers
        );
        this.parent.data.need_passengers = false;
        this.render();
        this.app.scrollTo(this.view);
        const pview = this.parent.view;
        const passengers_view = pview.querySelector('[data-bind="passengers"]');
        passengers_view.classList.remove('has-error');
        passengers_view.querySelectorAll('[data-bind="psg-error"]').forEach(
            err => err.parentElement.removeChild(err)
        );
        pview.querySelectorAll('[data-bind="btn-psg-add"]').forEach(btn => {
            const collapse = btn.closest('.collapse');
            if (collapse) toggleClass(collapse, 'in', this.parent.data.is_btn_psg_add);
        })
    }

    formSubmit(form, event) {
        if (this.data.passenger) {
            this.data.passenger.on('update', () => {
                // clear psg request on save psg
                const user_request = this.data.data.user_request;
                if (user_request) {
                    user_request.all_list = user_request.all_list.filter(
                        item => !user_request.list.some(s => item.field === s.field)
                    );
                    user_request.list.forEach(item => delete user_request[item.field]);
                    user_request.list = [];
                }
                this.render();
            });
            this.data.passenger.formSubmit(null, event);
            return;
        }
        if (this.is_new) {
            const that = this;
            const url = `${this.parent.uri}passengers/`;
            that.parent.loading(true);
            this.app.ajax({
                url: `${this.app.settings.endpoints}${url}`,
                method: 'POST',
                data: {data: {id: this.data.data.id}},
                callback(data) {
                    that.parent.loading(false);
                    that.parent.submitOnSucccess(data)
                },
                error(status, data) {
                    that.submitOnError(null, status, data);
                }
            });
        } else {
            const stack = [() => {
                this.parent.formSubmit(form, event);
            }];
            const next = () => {
                if (stack.length) {
                    stack.pop().call(null, next);
                }
            };
            // checking new passenger_document.documentTypes()
            passenger_document.documentTypes().forEach(type => {
                const doc = this.data.data[`new_${type}`];
                if (doc && doc.data.edit_mode) {
                    stack.push(callback => {
                        doc.on('update', callback);
                        doc.formSubmit(form, event);
                    })
                }
                this.data.data[type].forEach(doc => {
                    if (doc && doc.data && doc.data.edit_mode) {
                        stack.push(callback => {
                            doc.on('update', callback);
                            doc.formSubmit(form, event);
                        })
                    }
                });
            });
            next();
        }
    }

    serializeChanges() {
        const filter = ['eticket', 'visa_id', 'passport_id', 'ffn_id'];
        const changes = super.serializeChanges();
        const user_request = this.data.data.user_request;
        if (user_request) {
            filter.forEach(key => {
                if (this.data.data[key]) changes[key] = this.data.data[key];
            });
        }
        const filtered = {};
        Object.keys(changes).forEach(key => {
            if (filter.indexOf(key) > -1) filtered[key] = changes[key];
        });
        return filtered;
    }
}
