import {addClickEvent} from '../helpers';
import GenericComponent from '../GenericComponent';

export default class tips extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'tips'), data);
        this.auto_destroy = true;
        this.is_ready = false;
        this.data = {data: {}};
        this.data.tags = this.uri.split('/');
        this.template = this.uri.replace('/', '_');
    }

    get(forceUpdate, callback, fail) {
        this.app.dataStorage.getTips(tips => {
            this.is_ready = true;
            this.data.tips = tips;
            callback();
        });
        return this;
    }

    render() {
        if (this.is_ready) super.render();
    }

    bindLinks() {
        super.bindLinks();
        addClickEvent(this.view, '[data-bind^="tip-"]', event => {
            const tip = event.target.closest('[data-bind]').dataset.bind
                .replace(/-/g, '_');
            if (tip === 'tip_just_activated') {
                this.app.settings.just_activated = false;
            }
            this.data.tips[tip] = !(this.data.tips[tip] || false);
            this.app.dataStorage.setTip(tip, this.data.tips[tip]);
            setTimeout(() => this.render(), 100);
        });
    }
}
