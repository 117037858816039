import {addClickEvent, addEvent, toggleClass} from '../helpers';
import GenericViewOffline from '../GenericViewOffline';

export default class login extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'login'), data);
        this.auto_destroy = true;
    }

    get(forceUpdate, callback, error) {
        super.get(forceUpdate, callback, error);
        const userdata = this.app.dataStorage.get('/user/');
        this.data = {
            profile_link: this.app.settings.profile_link,
            login: this.app.settings.profile_link,
            data: {
                email: (userdata && userdata.data && userdata.data.email)
                    || this.app.settings.user_profile.email
            }
        };
        return this;
    }

    initForm() {
        const form = this.view.querySelector('form');
        addClickEvent(this.view, '[data-toggle="buttons"]', function click(event) {
            const buttons = this;
            Array.prototype.forEach.call(
                buttons.querySelectorAll('button'),
                button => {
                    const checked = (button === event.target);
                    toggleClass(button, 'active', checked);
                    Array.prototype.forEach.call(
                        form.querySelectorAll(button.dataset.toggle),
                        el => toggleClass(el, 'in', checked)
                    );
                }
            );
        });
        addEvent(form, 'submit', event => this.formSubmit(form, event));
    }

    formSubmit(form, event) {
        this.loading(true);
        const is_email = form.querySelector('button[data-bind="email"].active');
        if (form.token) {
            event.preventDefault();
            this.app.checkToken(
                form.token.value,
                () => {
                    this.loading(false);
                    console.log('token approved');
                    this.app.token = form.token.value;
                    this.app.default_headers[
                        this.app.settings.token_header
                    ] = form.token.value;
                    this.app.clearObjects();

                    this.app.get(this.data && this.data.backurl ? this.data.backurl :
                        this.app.start_uri);
                },
                () => {
                    this.loading(false);
                    console.log('token failed');
                    if (this.data) {
                        this.data = {};
                    }
                    this.data.login_failed = true;
                    this.render();
                }
            );
        } else if (is_email) {
            event.preventDefault();
            this.app.ajax({
                url: this.app.settings.profile_link,
                method: 'POST',
                data: {data: {email: form.email.value}},
                callback: () => {
                    this.loading(false);
                    form.querySelector('[data-bind="email-error"]').classList.add('hidden');
                    form.querySelector('[data-bind="email-success"]').classList.remove('hidden');
                },
                error: (code, text) => {
                    this.loading(false);
                    form.querySelector('[data-bind="email-success"]').classList.add('hidden');
                    if (code === 422) {
                        form.querySelector('[data-bind="email-error"]').classList.remove('hidden');
                    } else {
                        this.onNetworkError(code, text);
                    }
                }
            });
        } else {
            form.action = window.location.pathname;
        }
    }
}
