import GenericViewOffline from '../../GenericViewOffline';
import {addEvent, toggleClass} from '../../helpers';

export default class user_verification extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_verification'), data);
    }

    initForm() {
        const settings = this.app.settings;
        addEvent(this.view.querySelector('form'), 'submit', event => {
            event.preventDefault();
            const input = event.target.code;
            const code = input.value;
            if ((`${code}`).length < 5 || !(`${code}`).match(/^\w+$/)) {
                toggleClass.call(input, 'has-error', true);
                this.toolTip(input, input.dataset.errorMsg);
                return;
            }
            toggleClass(input, 'has-error', false);
            window.location.href = `${settings.origin}verification/?code=${code}&user_id=${settings.user_profile.id}`;
        });
        addEvent(this.view.querySelector('[data-bind="btn-again"]'), 'click', event => {
            const btn = event.target;
            this.loading(true);
            btn.disabled = true;
            this.app.ajax({
                url: `${settings.origin}send_verification/`,
                method: 'POST',
                data: {
                    data: {
                        user_id: settings.user_profile.id,
                        email: settings.user_profile.email
                    }
                },
                callback: () => {
                    this.loading(false);
                    btn.disabled = false;
                    this.data.error = true;
                },
                error: (status, data) => {
                    this.loading(false);
                    btn.disabled = false;
                    this.onNetworkError(status, data);
                }
            });
        });
        return false;
    }
}