import GenericComponent from '../../GenericComponent';
import {addClickEvent} from '../../helpers';

export default class chat_widget_seat_preferences extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_seat_preferences'), data);
    }

    get(forceUpdate, callback, fail) {
        const psgNum = this.data.data.widget.passengersNum;
        const id = this.data.thread.checkinId;
        if (!psgNum || !id) {
            delete this.data.data.widget;
            super.get(forceUpdate, callback, fail);
        }
        this.data.data.uri = `checkins/${id}/seatmap_preference/${psgNum}/`;
        if (callback) callback(this);
        return this;
    }

    bindLinks() {
        super.bindLinks();
        addClickEvent(this.view, '[data-bind="show"]', () => {
            this.app.dialogComponent(
                this.data.data.uri,
                'seatmap_settings_component',
                {subclass: 'modal-dialog-seatmap-settings'},
                this
            )
        });
    }
}
