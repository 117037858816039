import GenericComponent from '../../GenericComponent';
import GenericView from '../../GenericView';
import {addEvent, deepcopy, time} from '../../helpers';

export default class user_notifications extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_notifications'), data);
        this.endpoint_uri = 'user_settings/';
    }

    get(forceUpdate, callback, fail) {
        super.get(forceUpdate, () => {
            this.notifications = JSON.parse(
                document.getElementById('notifications').innerHTML
            ).data;
            const us = this.data.data.user_settings;
            if (us && this.notifications) {
                us.post_notification_other = this.notifications[
                this.notifications.length - 1
                    ].group.some(el => us[el.name]);
            }
            if (callback) callback();
            this.render();
        }, fail);
        return this;
    }

    render() {
        const us = this.data.data && this.data.data.user_settings;
        if (us) {
            this.data.notifications = this.notifications.map(el => {
                el.enabled = us[el.name];
                if (el.group) {
                    el.group = el.enabled
                        ? el.group.map(subel => Object.assign(subel, {
                            enabled: us[subel.name]
                        }))
                        : el.group.map(subel => {
                            us[subel.name] = false;
                            return Object.assign(subel, {enabled: false})
                        })
                }
                return el;
            });
            super.render();
        }
    }

    initForm() {
        const us = this.data.data.user_settings;
        addEvent(this.view.querySelectorAll('[data-bind="checkbox"] input'), 'change',
            event => {
                event.stopImmediatePropagation()
                const target = event.target;
                us[target.name] = target.checked;
                if (target.dataset.group) this.render();
            }
        );
        GenericComponent.prototype.initForm.call(this);
    }

    formSubmit(form, event) {
        event.preventDefault();
        this.loading(true);
        const data = deepcopy(this.data.data);
        delete data.settings_info;
        delete data.user_settings.post_notification_other;
        this.app.ajax({
            url: this.app.settings.endpoints + this.endpoint_uri,
            method: 'PUT',
            data: {data: data},
            callback: () => {
                this.loading(false);
                this.update();
                this.app.dataStorage.set(this.uri, this.data);
                this.updated = time();
            },
            error: (status, data) => {
                this.submitOnError(form, status, data);
            }
        });
    }
}
