import GenericView from '../../GenericView';

export default class user_password extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_password'), data);
    }

    get(forceUpdate, callback, fail) {
        this.data = {data: {}};
        if (typeof (callback) === 'function') {
            callback();
        }
        return this;
    }

    render() {
        super.render();
        this.view.querySelector('[name="password"]').focus()
    }

    formSubmit(form, event) {
        event.preventDefault();
        const that = this;
        const confirmation = this.view
            .querySelector('[data-name="confirmation"]').value;
        const data = that.serializeChanges(form);

        this.data.data = {
            password: data['password'],
            confirmation
        };
        if (data['password'] !== confirmation) {
            this.data.confirmation_error = true;
            this.render();
            return;
        } else {
            this.data.confirmation_error = false;
        }
        that.loading(true);
        this.app.ajax({
            url: this.app.settings.endpoints + this.uri,
            method: 'PUT',
            data: {data},
            callback(data) {
                that.loading(false);
                if (data.result) {
                    that.render();
                    that.formSaved();
                } else {
                    that.submitOnError(form, status, data);
                }
            },
            error(status, data) {
                that.submitOnError(form, status, data);
            }
        });
    }
}
