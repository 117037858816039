import {is_expired, time} from './helpers';

export default class appCache {
    constructor() {
        this.ttl = 1000;
        this.cache = {}
        this.timestamps = {};
    }
    get(uri, success, failed) {
        if (typeof(failed) !== 'function') {
            failed = () =>{};
        }
        if (uri in this.cache) {
            let obj;
            try {
                obj = JSON.parse(this.cache[uri]);
            }
            catch(e) {
                failed();
                return;
            }
            success(obj);
        }
        else {
            failed();
        }
    }
    set(uri, value) {
        try {
            this.cache[uri] = JSON.stringify(value)
        }
        catch(e) {
            console.error(`Can't stringify obj to JSON for url ${uri}: `, e);
            fail();
            return;
        }
        this.timestamps[uri] = time();
        return value;
    }
    remove(uri) {
        if (uri in this.cache) {
            delete this.cache[uri]
        }
    }
    needRevalidate(url) {
        return !(url in this.timestamps)
            || is_expired(this.timestamps[url], this.ttl);
    }

    getTips(callback) {
        try {
            callback(JSON.parse(localStorage.getItem('tips')) || {});
        }
        catch(err) {
            console.error(err);
            callback({});
        }
    }

    setTip(tip, value) {
        this.getTips(tips => {
            tips[tip] = value;
            try {
                localStorage.setItem('tips', JSON.stringify(tips));
            }
            catch(err) {
                console.error(err);
            }
        });
    }
    getNotification(callback, fail) {}
    clearNotification() {}
    setBadge(count, success, fail) {}
    getToken() {}
    removeToken(callback, fail) {
        try {
            window.localStorage.removeItem('token');
            this.app.settings.token = '';
        } catch (err) {
            if (fail) fail(err);
        }
        callback();
    }
    isBPUniq(checkin_id, callback) {
        try  {
            let bp = JSON.parse(localStorage.getItem('bp'));
            const flag = bp[checkin_id];
            bp[checkin_id] = true;
            localStorage.setItem('bp', JSON.stringify(bp))
            if (flag) callback();
        } catch (err) {
            callback(true);
        }
    }
    getStartURL(callback, fail) {

    }
    saveStartURL(url) {

    }
}
