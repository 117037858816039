import GenericComponent from '../../GenericComponent';

export default class chat_widget_bpass extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_bpass'), data);
    }

    get(forceUpdate, callback, fail) {
        const checkin = this.data.data.widget.data && this.data.data.widget.data.checkin;
        if (checkin) {
            const checkinUri = `checkin_boardings/${checkin.id}/`;
            this.data.data.widget = this.app.initObject(checkinUri, 'thread_boardings', {
                data: checkin,
                forceUpdate
            }, forceUpdate);
            this.data.data.widget.parent = this;
        } else {
            this.data.data.widget = null;
        }
        if (callback) callback();
        return this;
    }

    render(level) {
        const widget = this.data.data.widget;
        if (widget && widget.data) widget.data.withoutEmail = true;
        super.render(level);
        this.view.classList.remove('closed');
    }
}
