import GenericViewOffline from '../../GenericViewOffline';
import {addEvent} from '../../helpers';

export default class user_after_delete extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_after_delete'), data);
    }

    bindLinks() {
        addEvent(this.view.querySelectorAll('[data-bind="logout"]'), 'click',
            event => this.app.platform.logout()
        );
    }
}
