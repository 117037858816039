import GenericViewOffline from '../../GenericViewOffline';

export default class user_tos_and_privacy_policy extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_tos_and_privacy_policy'), data);
        this.form_url = 'user/accept_tos_and_privacy_policy/';
        this.auto_serialize = true;
    }

    render() {
        const landing = this.app.settings.landing;
        const lang = this.app.settings.lang === 'en' ? '' : 'ru/';

        const user_profile = this.app.settings.user_profile;
        if (
            user_profile.is_tos_and_privacy_policy_accepted
            && user_profile.is_active
        ) {
            this.app.get(this.app.start_uri);
            return;
        }
        this.data.privacy_policy = `${landing}/${lang}privacy-policy.html`;
        this.data.tos = `${landing}/${lang}tos.html`;

        const promoContainer = this.view && this.view.querySelector('#promo_code_input');
        // dumping collapse state from
        if (promoContainer) {
            this.data.promoContainerOpen = promoContainer &&
                promoContainer.classList.contains('in');
        }
        super.render();
    }

    initForm() {
        this.data.data = {};
        super.initForm();
    }

    formSubmit(form) {
        this.data.promoCode = this.view.querySelector('#promo_code_input')

        let allAccepted = true;
        this.view.querySelectorAll('input[type="checkbox"]').forEach(input => {
            allAccepted &= input.checked;
        });
        if (!allAccepted) {
            this.data.error = true;
            this.render();

            return;
        }

        this.loading(true);
        this.app.ajax({
            url: this.app.settings.endpoints + this.form_url,
            data: {promo_code: form.promo_code.value},
            method: 'POST',
            callback: () => {
                this.loading(false);
                if (this.app.settings.user_profile) {
                    this.app.settings.user_profile
                        .is_tos_and_privacy_policy_accepted = true;
                }
                this.app.toggleMainMenu(true);
                this.app.get('threads/');
            },
            error: (status, data) => {
                this.submitOnError(null, status, data);
            }
        });
    }
}
