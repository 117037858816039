import GenericRestComponent from '../GenericRestComponent';
import {addEvent, time, toggleClass} from '../helpers';

export default class seatmap_settings_component extends GenericRestComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'seatmap_settings_component'), data);
        this.endpoint_uri = this.uri.replace(
            'seatmap_settings', 'seatmap_preference'
        );
    }

    get(forceUpdate, callback, fail) {
        const m = this.uri.match(/(\d+)\/$/);
        this.psgRange = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        if (m) {
            this.passengers_count = parseInt(m[1]);
            this.endpoint_uri = this.endpoint_uri.replace(/\d+\/$/, '');
        }
        if (this.passengers_count) this.psgRange = [this.passengers_count];
        super.get(forceUpdate, () => {
            if (!this.data.state) this.data.state = {};
            if (!this.data.data.strategies) {
                this.data.data.strategies = {};
            }
            if (typeof (callback) === 'function') {
                callback();
            }
        }, fail);
    }

    render() {
        if (this.data.data) {
            const selection_policy_value = this.data.data.selection_policies;
            this.data.is_skip = selection_policy_value === 'SKIP';
            this.data.is_request = selection_policy_value === 'REQUEST';
            this.data.only_one_passenger = this.psgRange.length === 1;
            this.data.passengers = [];
            this.psgRange.forEach(i => {
                const str = this.app.initObject(
                    `${this.uri}strategies/${i}/`,
                    'seatmap_strategies',
                    {
                        strategies: this.app.settings.seatmap_strategies[i],
                        num: i
                    }
                );
                str.data.data = this.data.data.strategies[i];
                str.parent = this;
                str.get();
                this.data.passengers.push({
                    index: i - 1,
                    num: i,
                    open: this.data.state[i],
                    seatmap_strategies: str,
                    list_strategies: str.data.strategiesList
                });
            });
        }
        this.data.in_chat = this.parent.className === 'chat_widget_seat_preferences';
        this.data.in_thread = this.parent.className === 'thread';
        super.render();
    }

    getPolicyValue() {
        if (this.data.is_skip) {
            return 'SKIP';
        }
        if (this.data.is_request) {
            return 'REQUEST';
        }
        return 'AUTO';
    }

    formSubmit(form, event) {
        event.preventDefault();
        const apply_threads = this.view.querySelector('[name="apply_threads"]');
        const data = {
            selection_policies: this.getPolicyValue(),
            strategies: {},
            apply_threads: apply_threads && apply_threads.checked
        };
        this.data.passengers.forEach((psg, _) => {
            if (psg.seatmap_strategies.data.data) {
                data.strategies[psg.num] = psg.seatmap_strategies.data.data;
            }
        });
        this.was_applied_on_all_threads = data.apply_threads;
        this.loading(true);
        this.app.ajax({
            url: this.app.settings.endpoints + this.endpoint_uri,
            method: 'PUT',
            data: {data},
            callback: data => {
                this.app.platform.sendEvent('seatmap_settings_changed');
                this.loading(false);
                this.data = Object.assign(
                    this.data,
                    typeof (this.converter) === 'function'
                        ? this.converter(data)
                        : data
                );
                this.app.dataStorage.set(this.endpoint_uri, data);
                this.updated = time();
                this.update();
                this.render();
            },
            error: (status, data) => {
                this.submitOnError(form, status, data);
            }
        });
    }

    initForm() {
        const that = this;
        const skip_ctl = this.view.querySelector('[name="skip"]');
        const request_ctl = this.view.querySelector('[name="request"]');

        super.initForm();
        addEvent([skip_ctl, request_ctl], 'change', function () {
            that.data[`is_${this.name}`] = this.checked;
            that.data.data.selection_policies = that.getPolicyValue();
            if (that.data.is_skip && that.data.is_request) {
                const other_opt = this.name === 'skip' ? 'request' : 'skip';
                const other_ctl = that.view.querySelector(`[name="${other_opt}"]`);

                that.data[`is_${other_opt}`] = false;
                other_ctl.checked = false;
                other_ctl.parentElement.classList.remove('active');
            }

            that.render();
        });

        addEvent(
            this.view.querySelectorAll('[data-bind="btn-settings"]'), 'click',
            function () {
                const index = parseInt(this.dataset.index);
                that.data.state[index] = !that.data.state[index];
                const container = that.view.querySelector(`[data-bind="psg-${index}"]`);
                toggleClass(container, 'in', that.data.state[index]);
                that.data.passengers[index - 1].seatmap_strategies.open();
            }
        );

        this.view.querySelectorAll('[type="reset"]', 'click', () => {
            this.render();
        });
    }

}
