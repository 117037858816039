import GenericView from '../../GenericView';

export default class user_checkins extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_checkins'), data);
    }

    get(forceUpdate, callback, fail) {
        this.app.platform.sendEvent('checkins_shown');
        this.app.getUserData(callback, fail, forceUpdate);
        return this;
    }

    render(level) {
        if (this.uri.match(/success/)) {
            this.data.success = true;
        }
        super.render(level);
    }
}
