import GenericView from '../../GenericView';

export default class user_seat_preferences extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_seat_preferences'), data);
        this.endpoint_uri = 'user_settings/seatmap_preference/';
        this.auto_serialize = true;
    }

    converter(data) {
        data.data.section = 'front';
        data.data.seat = 'one_psg_window';

        if (data.data.strategies && data.data.strategies['1'] && data.data.strategies['1'][0]) {
            let strategy = data.data.strategies['1'][0];

            if (typeof (strategy) === 'string') {
                strategy = {name: strategy, modifiers: {}};
            }
            data.data.seat = strategy['name'];

            data.data.section = strategy.modifiers.front
                ? 'front' : strategy.modifiers.middle
                    ? 'middle'
                    : 'rear'
        }

        return data;
    }

    render() {
        this.data.front = this.data.data.section === 'front';
        this.data.middle = this.data.data.section === 'middle';
        this.data.rear = this.data.data.section === 'rear';
        this.data.aisle = this.data.data.seat !== 'one_psg_window';
        this.data.window = this.data.data.seat == 'one_psg_window'
        super.render();
    }

    formSubmit(form) {
        const modifiers = {};
        modifiers[this.data.data.section] = true;
        this.data.data.strategies = this.data.data.strategies || {};
        this.data.data.strategies['1'] = this.data.data.strategies['1'] || [];
        this.data.data.strategies['1'][0] = {
            name: this.data.data.seat,
            modifiers: modifiers
        }
        this.loading();
        this.app.ajax({
            url: this.app.settings.endpoints + this.endpoint_uri,
            data: {data: this.data.data},
            method: 'PUT',
            callback: data => {
                this.app.dataStorage.set(this.endpoint_uri, data);
                this.app.getUserData(
                    () => this.app.get('threads/'),
                    (status, data) => this.submitOnError(null, status, data),
                    true //force
                );
            },
            error: (status, data) => {
                this.submitOnError(null, status, data);
            }
        })
    }
}
