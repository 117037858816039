import {addEvent} from '../../helpers';
import thread from './thread';

export default class thread_boardings extends thread {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'thread_boardings'), data);
        this.template = 'thread_boardings';
    }

    sendBpass() {
        this.loading(true);
        this.app.platform.sendEvent('email_bpass');
        this.app.ajax({
            url: this.app.settings.endpoints + this.bp_uri + 'send_bpass/',
            method: 'POST',
            callback: () => {
                this.data.sent = true;
                this.loading(false);
                this.render();
            },
            error: (status, data) => this.onNetworkError(status, data)
        });
    }

    bindLinks() {
        super.bindLinks();
        const mouseover = event => {
            if (this.clearTimer) clearTimeout(this.clearTimer);
            this.clearTimer = 0;
            let t = event.target.closest('.icon-star');
            while (t) {
                t.classList.add('active');
                t = t.previousElementSibling;
            }
            t = event.target.closest('.icon-star').nextElementSibling;
            while (t) {
                t.classList.remove('active');
                t = t.nextElementSibling;
            }
        };
        addEvent(
            this.view.querySelectorAll('.stars .icon-star'),
            'mouseover',
            mouseover
        );
        addEvent(
            this.view.querySelectorAll('.stars'),
            'mouseout',
            () => {
                if (!this.data.done) {
                    if (this.clearTimer) clearTimeout(this.clearTimer);
                    this.clearTimer = setTimeout(
                        () => {
                            Array.prototype.forEach.call(
                                this.view.querySelectorAll('.stars .icon-star'),
                                star => star.classList.remove('active')
                            );
                        },
                        100
                    );
                }
            }
        );
        addEvent(
            this.view.querySelectorAll('.stars .icon-star'),
            'click',
            event => {
                mouseover(event);
                const target = event.target.closest('.icon-star');
                const rate = parseInt(target.dataset.rate);
                this.app.platform.sendEvent('bp_rate', {rate});
                this.data.done = true;
                [1, 2, 3, 4, 5].forEach(r => this.data[`rate${r}`] = r <= rate);
                this.render();
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="send_bpass"]'),
            'click',
            () => this.sendBpass()
        );
    }
}
