import {addResendEmailEvent} from '../common';
import {addClickEvent, getScrollTop, setScrollTop, time, toggleClass} from '../helpers';
import GenericView from '../GenericView';

export default class threads extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'threads'), data);
        this.btn_main_add = document.getElementById('btn-add-thread').innerHTML;
        this.endpoint_uri = 'checkins/';
        this.thread_new_class = 'thread_new';
        const m = this.uri.match(/search=([^&]+)/);
        if (m) {
            this.data.search = m[1];
        }
    }

    pagination() {
        this.scrollTop = document.body.scrollTop;
        const that = this;
        const button = this.view.querySelector('[data-bind="next"]');
        button.disabled = true;
        this.get_next(() => {
            button.disabled = false;
            const scrollTop = getScrollTop();
            that.render();
            setScrollTop(scrollTop);
        });
    }

    render(level) {
        this.is_mobile = this.app.platform.is_mobile;
        super.render(level);
        if (!this.data.data || !this.data.data.length) {
            setTimeout(() => this.help(), 200);
        }
    }

    refreshFirstFlight() {
        this.app.ajax({
            url: `${this.app.settings.endpoints}first_flight/`,
            callback: (data) => {
                // Possible statuses: none/parsing/complete/fail

                if (data.status === 'complete') {
                    this.refresh();
                } else {
                    if (!this.data.parsing && data.status === 'parsing') {
                        this.data.parsing = true;
                        this.render();
                    } else if (!this.data.fail && data.status === 'fail') {
                        this.data.parsing = false;
                        this.data.fail = true;
                        this.render();
                    }

                    if (data.status === 'parsing') {
                        this.scheduleRefreshFirstFlight(10000);
                    } else {
                        this.scheduleRefreshFirstFlight(1000);
                    }
                }
            },
            error(status, data) {
                this.scheduleRefreshFirstFlight(3000);
            }
        });
    }

    scheduleRefreshFirstFlight(timeout) {
        setTimeout(() => this.refreshFirstFlight(), timeout);
    }

    addFlightManual() {
        const new_uri = this.app.getUriForNewInstanse('threads_new/');
        const new_checkin = this.app.initObject(new_uri, this.thread_new_class, {});
        new_checkin.parent = this;
        this.data.edit_thread = new_checkin;
        new_checkin.get(false, () => {
            this.render();
            new_checkin.edit(true);
        });
    }

    add() {
        this.app.dialogComponent('add-flight/', 'threads_add_flight', {}, this);
    }

    edit(status = true) {
        toggleClass(this.view, 'active-thread-edit', status);
        this.app.hideToolTip();
    }

    converter(data) {
        return data.data.checkins;
    }

    relation_update(relation) {
        const index = this.data.data.indexOf(relation);
        if (index < 0) {
            this.data.data.unshift(relation);
            this.render();
        } else if (index > -1 && relation.is_deleted) {
            this.data.data.splice(index, 1);
            this.render();
        }
    }

    get(forceUpdate, callback, fail) {
        this.app.subscribe('checkins/\\d+/$', this);
        if (forceUpdate) {
            // always refreshing user_date on force reload
            this.app.getUserData(() => {
                this._get(forceUpdate, callback, fail, false);
            }, fail, forceUpdate);
        } else {
            this._get(forceUpdate, callback, fail, false);
        }

        return this;
    }

    get_next(callback) {
        return this._get(true, callback, null, true);
    }

    _get(forceUpdate, callback, fail, newpage) {
        const that = this;
        const params = [];
        let params_uri = '';
        if (newpage && this.data.pagination) {
            params.push(`offset=${this.data.pagination.offset}`);
        }
        if (this.data.filter) {
            params.push(`filter=${this.data.filter}`);
        }
        if (this.data.search) {
            params.push(`search=${encodeURIComponent(this.data.search)}`);
        }
        if (params.length) {
            params_uri += `?${params.join('&')}`;
        }
        const url = this.endpoint_uri + params_uri;

        const process = (response, update_cache) => {
            if (update_cache) {
                that.app.dataStorage.set(url, response);
                that.updated = time();
            }
            if (!that.data) {
                that.data = {}
            }
            if (response.pagination) {
                response.pagination.offset += response.data.checkins.length;
                response.pagination.next =
                    response.pagination.offset < response.pagination.count;
            }
            that.data.pagination = response.pagination;
            that.data.total_checkins = response.data.total_checkins;
            if (that.data.total_checkins === 0) {
                this.refreshFirstFlight();
            }
            if (!newpage) {
                that.data.data = []
            }
            that.converter(response).forEach((checkin, index) => {
                const checkinUri = `checkins/${checkin.id}/`;
                if (update_cache) {
                    that.app.dataStorage.set(checkinUri, {data: checkin});
                }
                const obj = that.app.initObject(checkinUri, 'thread', {
                    data: checkin,
                    forceUpdate: update_cache
                }, update_cache);
                obj.parent = that;
                that.data.data.push(obj);
            });
            that.relations = {
                thread: that.data.data,
                thread_new: that.data.data
            };
            if (callback) callback();
        };

        const _backend = () => {
            this.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + url,
                callback: (response) => {
                    that.loading(false);
                    process(response, true);
                },
                error(status, data) {
                    that.onNetworkError(status, data);
                    if (typeof (fail) === 'function') fail();
                }
            });
        };

        if (forceUpdate) {
            _backend();
        } else {
            this.app.dataStorage.get(url, cache => {
                process(cache);
                this.app.revalidateData(this);
            }, _backend);
        }

        return this;
    }

    formSubmit(form, event) {
        event.preventDefault();
        this.app.get(`threads/?search=${form.search.value}`);
    }

    bindLinks() {
        super.bindLinks();
        addClickEvent(this.view, '[data-bind="next"]', () => this.pagination());
        addClickEvent(this.view, '[data-bind="btn-main-add"]', () => this.add());
        addResendEmailEvent(this);
    }

    delete() {
        console.error('Unsupported method delete on threads view')
    }
}

