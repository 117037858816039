import {addEvent} from './helpers';


export default function autoComplete(options) {
    function hasClass(el, className) { return el.classList ? el.classList.contains(className) : new RegExp(`\\b${className}\\b`).test(el.className); }
    function live(elClass, event, cb, context) {
        addEvent(context || document, event, (e) => {
            let found,
                el = e.target || e.srcElement;
            while (el && !(found = hasClass(el, elClass))) el = el.parentElement;
            if (found) cb.call(el, e);
        });
    }

    const o = {
        selector: 0,
        source() {},
        minChars: 2,
        delay: 150,
        offsetLeft: 0,
        offsetTop: 1,
        cache: 1,
        menuClass: '',
        renderItem(item, search) {
            // escape special characters
            search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            const re = new RegExp(`(${search.split(' ').join('|')})`, 'gi');
            return `<div class="ac-s" data-val="${item.value}">${item.option.replace(re, '<b>$1</b>')}</div>`;
        }
    };
    for (const k in options) { if (options.hasOwnProperty(k)) o[k] = options[k]; }
    // init
    const elems = typeof o.selector === 'object' ? [o.selector] : document.querySelectorAll(o.selector);
    for (let i = 0; i < elems.length; i++) {
        let that = elems[i];

        // create suggestions container "sc"
        that.sc = document.createElement('div');
        that.sc.className = `ac-ss ${o.menuClass}`;

        that.setAttribute('autocomplete', 'off');
        that.cache = {};
        that.last_val = that.value;
        that.last_o_val = that.dataset.value;

        that.updateSC = function (resize, next) {
            try {
                const rect = that.getBoundingClientRect();
                that.sc.style.left = `${Math.round(rect.left + (window.pageXOffset || document.documentElement.scrollLeft) + o.offsetLeft)}px`;
                that.sc.style.top = `${Math.round(rect.bottom + (window.pageYOffset || document.documentElement.scrollTop) + o.offsetTop)}px`;
                that.sc.style.width = `${Math.round(rect.right - rect.left)}px`; // outerWidth
            } catch (err) {};
            if (!resize) {
                that.sc.style.display = 'block';
                if (!that.sc.maxHeight) { that.sc.maxHeight = parseInt((window.getComputedStyle ? getComputedStyle(that.sc, null) : that.sc.currentStyle).maxHeight); }
                if (!that.sc.suggestionHeight) that.sc.suggestionHeight = that.sc.querySelector('.ac-s').offsetHeight;
                if (that.sc.suggestionHeight) {
                    if (!next) that.sc.scrollTop = 0;
                    else {
                        let scrTop = that.sc.scrollTop,
                            selTop = next.getBoundingClientRect().top - that.sc.getBoundingClientRect().top;
                        if (selTop + that.sc.suggestionHeight - that.sc.maxHeight > 0) { that.sc.scrollTop = selTop + that.sc.suggestionHeight + scrTop - that.sc.maxHeight; } else if (selTop < 0) { that.sc.scrollTop = selTop + scrTop; }
                    }
                }
            }
        };
        addEvent(window, 'resize', that.updateSC);
        document.body.appendChild(that.sc);

        live('ac-s', 'mouseleave', (e) => {
            const sel = that.sc.querySelector('.ac-s.selected');
            if (sel) setTimeout(() => { sel.className = sel.className.replace('selected', ''); }, 20);
        }, that.sc);

        live('ac-s', 'mouseover', function (e) {
            const sel = that.sc.querySelector('.ac-s.selected');
            if (sel) sel.className = sel.className.replace('selected', '');
            this.className += ' selected';
        }, that.sc);

        live('ac-s', 'mousedown', function (e) {
            if (hasClass(this, 'ac-s')) { // else outside click
                that.value = this.textContent;
                that.dataset.value = this.dataset.val;
                that.last_val = that.value;
                that.last_o_val = that.dataset.value;

                that.readyHandler();
                that.sc.style.display = 'none';
                that.selectNextInput();
            }
        }, that.sc);

        that.selectNextInput = function() {
            const nextInput = that.form.elements[
                Array.prototype.indexOf.call(that.form.elements, that) + 1
            ];
            if (nextInput) {
                setTimeout(() => {
                    if (that == document.activeElement) {
                        nextInput.dispatchEvent(new Event('touchstart', {
                            bubbles: false, cancelable: true, touches: []
                        }));
                    }
                }, 50);
            };
        }

        that.setCurrentValue = function (success, fail) {
            if (that.waiting_suggest > 20) {
                console.log('setCurrentValue waiting_suggest timeout');
                that.waiting_suggest = 0;
            }
            else if (that.waiting_suggest) {
                console.log('setCurrentValue waiting_suggest');
                setTimeout(() => {
                    that.setCurrentValue(success, fail);
                }, o.delay);
                that.waiting_suggest ++;
                return;
            }
            const data = that.cache[that.value] || [];
            console.log('setCurrentValue', data, that.value);
            let found = false;
            data.forEach((opt) => {
                if (
                    opt.option.toUpperCase() == that.value.toUpperCase()
                    || opt.value == that.value.toUpperCase()
                    || (data.length == 1 && that.value && that.value.length > 2)
                ) {
                    that.value = opt.option;
                    that.dataset.value = opt.value;
                    that.last_val = that.value;
                    that.last_o_val = that.dataset.value;
                    that.readyHandler();
                    found = true;
                }
            });
            if (found && typeof (success) === 'function') success.call();
            if (!found && typeof (fail) === 'function') fail.call();
            return found;
        };

        that.blurHandler = function () {
            try { var over_sb = document.querySelector('.ac-ss:hover'); } catch (e) { var over_sb = 0; }
            if (!over_sb) {
                if (that.value !== '') {
                    that.setCurrentValue(null, () => {
                        that.value = that.last_val;
                        that.dataset.value = that.last_o_val;
                    });
                } else {
                    that.value = '';
                    that.dataset.value = '';
                }
                that.sc.style.display = 'none';
                setTimeout(() => { that.sc.style.display = 'none'; }, 350); // hide suggestions on fast input
            } else if (that !== document.activeElement) setTimeout(() => { that.focus(); }, 20);
        };
        addEvent(that, 'blur', that.blurHandler);

        var suggest = function (data, val) {
            if (!val) val = that.value;
            that.cache[val] = data;
            that.waiting_suggest = 0;
            if (data.length && val.length >= o.minChars) {
                let s = '';
                for (let i = 0; i < data.length; i++) {
                    s += o.renderItem(data[i], val);
                }
                that.sc.innerHTML = s;
                that.updateSC(0);
            } else { that.sc.style.display = 'none'; }
        };

        that.keydownHandler = function (e) {
            const key = window.event ? e.keyCode : e.which;
            const sel = that.sc.querySelector('.ac-s.selected');
            if ((key == 40 || key == 38) && that.sc.innerHTML) {
                let next;
                if (!sel) {
                    next = (key == 40) ? that.sc.querySelector('.ac-s') : that.sc.childNodes[that.sc.childNodes.length - 1]; // first : last
                    next.className += ' selected';
                    that.value = next.textContent;
                    that.dataset.value = next.dataset.val;
                } else {
                    next = (key == 40) ? sel.nextSibling : sel.previousSibling;
                    if (next) {
                        sel.className = sel.className.replace('selected', '');
                        next.className += ' selected';
                        that.value = next.textContent;
                        that.dataset.value = next.dataset.val;
                    } else { sel.className = sel.className.replace('selected', ''); that.value = that.last_val; that.dataset.value = that.last_o_val; next = 0; }
                }
                that.updateSC(0, next);
                return false;
            }
            // esc
            else if (key == 27) { that.value = that.last_val; that.dataset.value = that.last_o_val; that.sc.style.display = 'none'; }
            // enter
            else if (key == 13 || key == 9) {
                if (sel && that.sc.style.display != 'none') {
                    if (key == 13) {
                        e.stopPropagation();
                        e.preventDefault();
                        that.selectNextInput();
                    }
                    that.last_val = that.value;
                    that.last_o_val = that.dataset.value;
                    that.readyHandler();
                } else {
                    that.setCurrentValue();
                }
            }
        };
        addEvent(that, 'keydown', that.keydownHandler);

        that.keyupHandler = function (e) {
            const key = window.event ? e.keyCode : e.which;
            if (!key || (key < 35 || key > 40) && key != 13 && key != 27) {
                const val = that.value;
                if (val.length >= o.minChars) {
                    if (val != that.last_val) {
                        clearTimeout(that.timer);
                        if (o.cache) {
                            if (val in that.cache) { suggest(that.cache[val]); return; }
                            // no requests if previous suggestions were empty
                            for (let i = 1; i < val.length - o.minChars; i++) {
                                const part = val.slice(0, val.length - i);
                                if (part in that.cache && !that.cache[part].length) { suggest([]); return; }
                            }
                        }
                        that.timer = setTimeout(() => {
                            o.source.call(that, val, suggest);
                        }, o.delay);
                        that.waiting_suggest = 1;
                    }
                }
            }
        };
        addEvent(that, 'keyup', that.keyupHandler);

        that.focusHandler = function (e) {
            that.last_val = '\n';
            that.keyupHandler(e);
        };
        if (!o.minChars) addEvent(that, 'focus', that.focusHandler);

        that.readyHandler = function () {
            that.dataset.ready = true;
            let e;
            if ('createEvent' in document) {
                e = document.createEvent('HTMLEvents');
                e.initEvent('change', false, true);
            } else {
                e = new Event('change', {bubbles: false, cancelable: true});
            }
            that.dispatchEvent(e);
            setTimeout(() => { that.sc.style.display = 'none'; }, 20);
        };
    }
}
