import GenericComponent from '../GenericComponent';
import {addClickEvent} from '../helpers';
import wallet from './wallet';

export default class user_subscription_buy_modal extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_subscription_buy_modal'), data);
    }

    get(forceUpdate, callback, fail) {
        wallet.initWalletComponent(this, forceUpdate, () => {
            this.data.wallet.force_save_profile = true;
            this.data.sub = this.app.settings.user_profile.subscription;
            if (callback) callback();
        }, fail);
        return this;
    }

    initForm() {
        addClickEvent(this.view, '[data-bind="pay-subscription"]', event => {
            this.data.wallet.buySubscription(this.data.sub.external_id, () => {
                this.app.platform.paymentComplete();
            });
            setTimeout(() => this.app.closeModal(), 500);
        })
    }
}
