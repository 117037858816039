import {addEvent, is_expired} from './helpers';
import GenericRestComponent from './GenericRestComponent';

export default class GenericView extends GenericRestComponent {
    constructor(uri, app, className, data) {
        super(uri, app, className, data);
        if (this.uri.match(/new\/?$/)) {
            this.is_new = true;
            this.data.data = {};
        }
        this.is_view_component = true;
    }

    can_blur() {
        return (
            !this.changes || !this.changes.length
            || this.app.confirm(this.app.lang.unsaved)
        );
    }

    help(force) {
        const that = this;
        if (this.app.localStorage(`${this.className}_help_shown`) && !force) { return; }
        let flag = false;
        this.view.querySelectorAll('[data-help]').forEach((e) => {
            let help = document.createElement('div');
            const placement = 'placement' in e.dataset ? e.dataset.placement : 'bottom';
            help.innerHTML = this.app.Mustache.render(
                document.getElementById('popover').innerHTML,
                {
                    content: e.dataset.help.replace(/\n/, '<br>'),
                    type: placement
                }
            );
            help = help.children[0];

            that.view.appendChild(help);
            help.style.display = 'block';
            help.classList.add('in');

            const rect = e.getBoundingClientRect();
            const rel = that.view.getBoundingClientRect();
            const me = help.getBoundingClientRect();

            if (placement == 'bottom') { help.style.top = `${rect.bottom - rel.top}px`; } else if (placement != 'top') { help.style.top = `${(rect.top + rect.bottom) / 2 - me.height / 2 - rel.top}px`; }
            if (placement == 'top') { help.style.bottom = `${(rel.height - rect.top) + rel.top}px`; }
            if (placement == 'right') { help.style.left = `${rect.right - rel.left}px`; } else if (placement != 'left') { help.style.left = `${(rect.left + rect.right) / 2 - me.width / 2 - rel.left}px`; }
            if (placement == 'left') { help.style.right = `${(rel.width - rect.left) + rel.left}px`; }
            addEvent(help.querySelector('button'), 'click', () => {
                that.view.removeChild(help);
            });
            flag = true;
        });
        if (flag) {
            this.app.localStorage(`${this.className}_help_shown`, true);
        }
    }

    render(level) {
        const template = document.getElementById(this.template);
        const uri = this.wildcard_uri || this.uri;
        if (!template) {
            console.error(`Can't find template ${this.template} when rendering ${uri}`);
            return;
        }
        const className = `obj-${this.template}`;
        this.view = document.querySelector(`.${className}[data-uri="${uri}"]`);

        if (!this.view) {
            this.view = document.createElement('section');
            this.view.className = `${className} page-wrap hidden`;
        }
        this.view.dataset.uri = uri;
        if (this.is_new) {
            this.data.is_new = this.is_new;
        }
        if (template.dataset.title) {
            this.view.dataset.title = template.dataset.title;
        }
        if (this.app.settings.views && this.view.parentElement != this.app.settings.views) {
            this.app.settings.views.appendChild(this.view);
        }

        const display = this.view.style.display;
        this.view.style.display = 'none';
        super.render(level);
        this.view.style.display = display;

        if (this.view.dataset.help) {
            this.help();
        }
        if (!this.shortcuts) { this.shortcuts = []; }
        this.shortcuts.push({
            key: '?',
            event: () => { this.help(true); }
        });
    }

    show(callback) {
        if (!this.view || this.needReRender) {
            this.render();
            this.needReRender = false;
        }
        this.app.showView(this);
        if (callback) callback();
        return this;
    }

    destroy() {
        if (this.view && this.view.parentElement == this.app.settings.views) {
            this.app.settings.views.removeChild(this.view);
        }
        // clear history (remove this view)
        this.app.history = this.app.history.reduce((acc, item) => {
            if (item == this.uri || (acc.length && acc[acc.length-1] == item)) {
                return acc;
            }
            acc.push(item);
            return acc;
        }, []);

        const is_active = this.app.current_view === this;
        super.destroy();
        if (is_active) {
            if (this.history.length) {
                this.app.popstate({
                    uri: this.history[this.history.length-1],
                    length: this.history.length
                });
            } else {
                this.app.get(this.app.start_uri);
            }
        }
    }

    onHide() {
        if (this.auto_destroy) {
            this.destroy();
        }
        this.app.hideToolTip();
    }

    onShow() {
        if (this.updated && is_expired(this.updated, this.app.dataStorage.ttl)) {
            this.app.revalidateData(this);
        }
    }
}
