import GenericView from '../../GenericView';
import wallet from '../../components/wallet';

export default class user_buy_checkins extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_buy_checkins'), data);
        this.endpoint_uri = 'user/packages/';
    }

    show() {
        this.data.waiting_for_payment = false;
        super.show();
        if (this.data.wallet && this.data.wallet.data.transaction) {
            this.data.wallet.resetTransaction();
            this.data.wallet.render();
        }
    }

    get(forceUpdate, callback, fail) {
        wallet.initWalletComponent(this, forceUpdate, () => {
            const userData = this.app.settings.user_profile;
            const packages = (userData.billing_info
                && userData.billing_info.packages) || [];
            this.data.packages = packages.sort(
                (a, b) => (a.amount < b.amount ? -1 : 1)
            ).map((a, index) => {
                a.currency_sign = wallet.currencySign(a.currency);
                a.vip = a.amount >= 10;
                a.first = index === 0;
                return a;
            });
            this.data.wallet.on('retry', () => this.retry());
            if (callback) callback();
        }, fail);
        return this;
    }

    retry() {
        this.data.waiting_for_payment = false;
        this.render();
    }

    formSubmit(form, event) {
        event.preventDefault();
        this.loading(true);
        this.data.wallet.buyCheckins(
            form.product_id.value,
            ({transaction_status}) => {
                this.loading(false);
                if (transaction_status === 'finished') {
                    this.app.platform.paymentComplete();
                } else if (transaction_status !== 'canceled') {
                    this.data.waiting_for_payment = true;
                    this.render();
                }
            }
        );
    }
}
