import GenericComponent from '../GenericComponent';
import {addClickEvent} from '../helpers';

export default class user_subscription_banner extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_subscription_banner'), data);
    }
    initForm() {
        addClickEvent(this.view, '[data-bind="buy-subscription"]', event => {
            event.preventDefault();
            const sub = this.app.settings.user_profile.subscription;

            this.app.platform.buySubscription(sub.external_id);
        })
    }
}
