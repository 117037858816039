import {addResendEmailEvent} from '../../common';
import {addClickEvent, addEvent, deepcopy} from '../../helpers';
import GenericView from '../../GenericView';
import wallet from '../../components/wallet';

export default class user extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user'), data);
        if (this.app.platform.is_mobile) {
            this.endpoint_uri = 'user/?is_mobile=true';
        }
    }

    converter(rawData) {
        this.data = rawData;
        const data = this.data.data;
        const billing = data.billing || {};
        data.billing = billing;

        billing.plan = billing.plan || {service_class: 'free'};
        billing.checkin_balance = billing.checkin_balance || 0;
        billing.checkin_reserve = billing.checkin_reserve || 0;
        billing.checkin_available_amount = billing.checkin_balance
            - billing.checkin_reserve;

        data.is_free = !data.is_corp && billing.plan.service_class === 'free';
        data.is_paid = billing.plan.service_class === 'paid';
        data.is_vip = billing.plan.service_class === 'vip';

        if (data.proxy_email) {
            [data.proxy_email_prefix, data.proxy_email_suffix] =
                data.proxy_email.split('@');
        }
        if (data.email_aliases && data.email_aliases.length > 0) {
            data.email_alias = data.email_aliases[0].email;
        }

        const billing_info = data.billing_info || {};
        if (billing_info.plans) {
            [data.subscription] = billing_info.plans;
            data.subscription.currency_sign = wallet.currencySign(
                data.subscription.currency
            );
        }

        if (data.user_request) {
            const user_request = {};
            user_request.phone = data.user_request.reduce(
                (flag, req) => flag || Object.keys(req).some(
                    key => key.startsWith('phone')
                ),
                false
            );
            data.user_request = user_request;
        }
        return this.data;
    }

    get(forceUpdate, callback, fail) {
        const onGetUserData = () => {
            const user_data = this.data.data;
            this.app.settings.user_profile = deepcopy(user_data);

            if (forceUpdate) {
                this.app.reRenderAll();
            }

            callback();
        };
        super.get(forceUpdate, onGetUserData, (status, data) => {
            // trying to get data from cache if got an error on forceUpdate
            if (forceUpdate) {
                super.get(false, onGetUserData, fail);
            } else if (fail) {
                fail(status, data);
            }
        });
    }

    initForm() {
        if (!this.data.data) {
            this.data.data = [];
        }
        addClickEvent(this.view, '[data-bind="btn-logout"]', () => {
            if (!this.app.confirm(this.app.lang.logout_confirmation)) return;
            this.app.platform.logout();
        });
        addClickEvent(this.view, '[data-bind="topup-balance"]', () => {
            const amount = parseFloat(
                (this.view.querySelector('[name="amount"]').value + '')
                    .replace(/\,/g, '.')
            );
            const w = new wallet(null, this.app, 'wallet', {});
            w.topup_balance(amount);
        });
        addResendEmailEvent(this);
        addClickEvent(this.view, '[data-bind="btn-delete-email"]', event => {
            if (!confirm(event.target.dataset.confirm + ' ' + event.target.dataset.email)) {
                return;
            }
            this.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + 'user_settings/email_aliases/delete/',
                method: 'POST',
                data: {data: {email: event.target.dataset.email}},
                callback: () => {
                    this.loading(false);
                    this.data.data.email_aliases.splice(
                        this.data.data.email_aliases.findIndex(
                            el => el.email === event.target.dataset.email
                        ),
                        1
                    );
                    this.render();
                },
                error: (status, data) => {
                    this.submitOnError(null, status, data);
                }
            });
        });
        addEvent(this.view.querySelectorAll('input'), 'change', event => {
            this.data[event.target.name] = event.target.value;
        });
        super.initForm();
    }

    formSubmit(form, event) {
        event.preventDefault();
        if (form.name === 'add_email_alias') {
            const data = {email: form.email.value};
            this.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + 'user_settings/email_aliases/',
                method: 'POST',
                data: {data},
                callback: data => {
                    this.loading(false);
                    this.data.data.email_aliases.push(data);
                    this.render();
                },
                error: (status, data) => {
                    if (data && data.message_code === 'EmailAliasExist') {
                        this.loading(false);
                        Array.prototype.forEach.call(
                            form.querySelectorAll('[data-bind="duplicate-error"]'),
                            error => error.classList.add('in')
                        )
                    } else {
                        this.submitOnError(form, status, data);
                    }
                }
            });
        } else {
            super.formSubmit(form, event);
        }
    }

    render(level) {
        this.data.is_ios = this.app.platform.id === 'ios';
        this.data.is_android = this.app.platform.id === 'android';
        this.data.is_web = this.app.platform.id === 'web';

        super.render(level);
    }

}
