import {addEvent, is_empty} from '../helpers';
import GenericComponent from '../GenericComponent';

export default class passenger_document extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'passenger_document'), data);
        this.auto_serialize = true;
        if (!data.data || !data.data.id) {
            this.is_new = true;
        }
        if (!this.data.document_type) {
            [this.data.document_type] = this.uri.replace(this.parent.uri, '')
                .split('/');
        }
        this.document_type = this.data.document_type;
        if (
            this.document_type && !('document_type_locked' in this.data)
        ) {
            this.document_type_locked = true;
        }
        this.is_initialized = true;
    }

    render(level) {
        const doc = this.data.data;
        this.data.is_visa = this.document_type === 'visas';
        this.data.is_ffn = this.document_type === 'ffns';
        this.data.is_passport = !this.data.is_visa && !this.data.is_ffn;
        this.data.parent_edit_mode = this.parent.data.edit_mode
            || this.parent.className === 'checkin_passenger';
        this.data.document_type_locked = this.document_type_locked;
        this.data.title = this.app.lang[`document_type_${this.data.document_type}`];
        this.data.country = ['visa', 'passport'].reduce((acc, type) => {
            if (doc[`${type}_issue_country`]) {
                return doc[`${type}_issue_country`].country;
            }
            return acc;
        }, '');
        this.data.num = ['visa', 'passport', 'ffn'].reduce((acc, type) => {
            if (doc[`${type}_num`]) return doc[`${type}_num`];
            return acc;
        }, '');
        this.data.airline = doc.ffn_airline && doc.ffn_airline.name;
        super.render(level);
        this.deleteConfirmation = this.deleteConfirmation
            && this.deleteConfirmation.replace(
                'DOC',
                `${this.data.title} ${
                    this.data.country || this.data.airline || ''
                } ${this.data.num}`.trim()
                    .replace(/\s+, ' '/).trim()
            );
    }

    bindLinks() {
        super.bindLinks();
        addEvent(this.view.querySelectorAll('[name="document_type"]'), 'change',
            event => {
                this.document_type = this.data.document_type = event.target.value;
                if (this.get_document_type() === 'passports') {
                    this.data.data.passport_type = event.target.value;
                }
                this.render();
            }
        );
    }

    delete() {
        const that = this;
        if (this.is_new) {
            this.data.edit_mode = false;
            this.render();
            if (that.events.cancel) {
                that.events.cancel.forEach(func => func())
            }
        } else {
            super.delete(() => {
                const index = that.parent.data.data[that.get_document_type()]
                    .findIndex(el => that === el);
                if (index > -1) {
                    that.parent.data.data[that.get_document_type()].splice(index, 1);
                    that.parent.init();
                    that.parent.render();
                }
            });
        }
    }

    get_document_type() {
        return this.document_type === 'visas'
            ? 'visas'
            : this.document_type === 'ffns'
                ? 'ffns'
                : 'passports';
    }

    serializeDocumentType(data) {
        const new_data = data;
        if (this.get_document_type() === 'passports') {
            new_data.passport_type = data.document_type ||
                this.data.data.document_type ||
                'InternationalPassport';
        }
        if ('document_type' in new_data) delete new_data.document_type;
        return new_data;
    }

    serialize() {
        const data = {};
        const type = this.get_document_type();
        const schema = {
            'passports': [
                'id', 'passport_num', 'passport_issue_country_id',
                'passport_issue_date', 'passport_expiry_date',
                'passport_default', 'document_type', 'passport_type'
            ],
            'visas': [
                'id', 'visa_num', 'visa_issue_country_id', 'visa_valid_from',
                'visa_valid_to'],
            'ffns': ['id', 'ffn_ffn', 'ffn_airline_id']
        };
        schema[type].forEach(key => {
            if (key in this.data.data) {
                data[key] = this.data.data[key];
            }
        });
        return this.serializeDocumentType(data);
    }

    serializeChanges() {
        let data = super.serializeChanges();
        if (!is_empty(data)) {
            data = this.serializeDocumentType(data);
        }
        if (this.data.data.id) data.id = this.data.data.id;
        return data;
    }

    formSubmit(form, event) {
        if (!this.validateForm(form)) {
            const root = form || this.view;
            this.app.scrollTo(root.querySelector('.has-required'));
            return;
        }
        if (this.parent.className === 'passenger') {
            if (this.parent.edit_mode) {
                this.parent.formSubmit(form, event);
                return;
            }
            if (this.is_new && form) {
                form.setAttribute(
                    'action',
                    `${this.parent.uri + this.get_document_type()}/`
                );
            }
        }
        super.formSubmit(form, event);
    }

    static documentTypes() {
        return ['passports', 'visas', 'ffns'];
    }

    static documentTypeIDKey(type) {
        return {
            visas: 'visa_id',
            ffns: 'ffn_id',
            passports: 'passport_id'
        }[type];
    }

    static documentTypeByIDKey(id_key) {
        return {
            visa_id: 'visas',
            ffn_id: 'ffns',
            passport_id: 'passports'
        }[id_key];
    }

}
