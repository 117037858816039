import {addEvent, is_touch_device} from '../../helpers';

export default class DragList {
    /* Drag list component */

    constructor(container, opt) {
        this.container = container;
        this.events = {};
        this.threshold = 0.65;
        this.drag_holder_class = opt && opt.drag_holder_class || 'drag-holder';
        this.drag_class = opt && opt.drag_class || 'drag';
        this.dragging_class = opt && opt.dragging_class || 'dragging';

        this.holders = container.querySelectorAll(`.${this.drag_holder_class}`);

        if (is_touch_device()) {
            addEvent(this.container, 'touchend', event => this.stopDrag(event));
            addEvent(this.container, 'touchcancel', event => this.stopDrag(event));
            addEvent(this.holders, 'touchstart', event => this.startDrag(event));
            addEvent(this.holders, 'touchend', event => this.stopDrag(event));
            addEvent(this.holders, 'touchcancel', event => this.stopDrag(event));
            addEvent(this.holders, 'touchmove', event => this.Drag(event));
        } else {
            addEvent(this.container, 'mouseleave', event => this.stopDrag(event));
            addEvent(this.container, 'mouseup', event => this.stopDrag(event));
            addEvent(this.holders, 'mousedown', event => this.startDrag(event));
            addEvent(this.holders, 'mouseup', event => this.stopDrag(event));
            addEvent(this.holders, 'mousemove', event => this.Drag(event));
        }
        addEvent(this.holders, 'click', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });
    }

    getTop(event) {
        return (event.type.match('mouse') ?
                event.clientY :
                event.touches[0].clientY
        ) - this.startY
    }

    Drag(event) {
        const target = event.target;
        const element = target.closest(`.${this.drag_class}`);
        const parent = element.parentElement;
        let holder = this.draggHolder;

        event.preventDefault();
        event.stopPropagation();

        if (!holder || !element.classList.contains(this.dragging_class)) {
            return;
        }

        let top = this.getTop(event);
        holder.style.top = `${top}px`;

        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            let fired = false;
            if (top > element.clientHeight * this.threshold) {
                let next = element.nextElementSibling;
                if (next && next.nextElementSibling) {
                    parent.insertBefore(
                        element,
                        next.nextElementSibling
                    );
                } else {
                    parent.appendChild(element);
                }
                this.changed = true;
                fired = true;
                this.startY += element.clientHeight;
            }
            if (-top > element.clientHeight * this.threshold &&
                element.previousElementSibling) {
                let prev = element.previousElementSibling;
                while (prev && prev.classList.contains('hidden')) {
                    prev = prev.previousElementSibling;
                }
                if (prev) {
                    parent.insertBefore(element, prev);
                } else {
                    parent.insertBefore(element, element.firstChild);
                }
                this.changed = true;
                fired = true;
                this.startY -= element.clientHeight;
            }
            if (fired) {
                holder.style.top = `0px`;
            }
            this.timer = 0;
        }, 10);
    }

    startDrag(event) {
        event.preventDefault();
        event.stopPropagation();
        const element = event.target;
        this.container.querySelectorAll(`.${this.dragging_class}`).forEach((el) => {
            el.classList.remove(this.dragging_class);
        });
        element.closest(`.${this.drag_class}`).classList.add(this.dragging_class);
        this.draggHolder = element.closest(`.${this.drag_holder_class}`)
            || element.querySelector(`.${this.drag_holder_class}`);
        this.startY = 0;
        this.startY = this.getTop(event);
    }

    stopDrag(event) {
        this.container.querySelectorAll(`.${this.dragging_class}`).forEach(drag => {
            drag.classList.remove(this.dragging_class);
            drag.querySelector(`.${this.drag_holder_class}`).style.cssText = '';
        });
        if (this.changed) {
            this.change();
            this.changed = false;
        }
    }

    change() {
        if (this.events.change) {
            this.events.change.call(this);
        }
    }

    on(e, func) {
        this.events[e] = func;
    }
}
