import GenericViewOffline from '../../GenericViewOffline';

export default class user_feedback extends GenericViewOffline {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_feedback'), data);
        this.auto_destroy = true;
        this.data = {data: {}}
    }

    formSubmit(form, event) {
        event.preventDefault();
        this.loading(true);
        const data = this.serialize(form);
        this.app.ajax({
            url: this.app.settings.endpoints + 'user/feedback/',
            method: 'POST',
            data: {data},
            callback: (data) => {
                this.loading(false);
                this.formSaved();
            },
            error: (status, data) => {
                this.submitOnError(form, status, data);
            }
        });
    }
}