export default class wallet_helpers {
    static currencySign(currency) {
        const signs = {
            GBP: '&pound;',
            RUB: '&#8381;',
            EUR: '&euro;',
            USD: '&dollar;'
        };
        return signs[currency];
    }

    static parsePrice(str = '') {
        const currency = {
            RUB: ['rub', 'rur', '₽', 'р'],
            EUR: ['eur', '€', 'евро'],
            USD: ['usd', '$', 'доллар'],
        };
        const m = str.replace(',', '.').match(/\d+\.?\d*/);
        return {
            currency: Object.keys(currency).find(
                cur => currency[cur].some(
                    pattern => str.toLowerCase().match(pattern)
                )
            ),
            amount: m && parseFloat(m[0])
        };
    }
}
