import GenericView from '../GenericView';

export default class seatmap_settings extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'seatmap_settings'), data);
        this.endpoint_uri = this.uri;
    }
    get(forceUpdate, callback, fail) {
        this.data.componentUri = this.uri.replace('seatmap_preference', 'seatmap_settings');
        const comp = this.app.initObject(
            this.data.componentUri, 'seatmap_settings_component', {}
        );
        comp.parent = this;
        comp.get(forceUpdate, callback, fail);
        return this;
    }
    can_blur() { return true; }
}
