import flight from '../components/flight';
import threads from './threads';

export default class single_thread extends threads {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'single_thread'), data);
        let m = this.uri.match(/(threads\/|single_thread\/)(completed\/)?(\d+)\//);
        if (m) {
            this.thread_id = parseInt(m[3]);
        } else {
            m = this.uri.match(/checkins\/(\d+)/);
            this.checkin_id = m[1];
        }
        this.template = 'threads';
        this.endpoint_uri = this.thread_id
            ? `threads/${this.thread_id}/`
            : `checkins/${this.checkin_id}/`;
        // to prevent uri duplication with thread
        this.uri = this.thread_id
            ? `single_thread/${this.thread_id}/`
            : `single_thread/checkins/${this.checkin_id}/`
    }

    converter(data) {
        return this.thread_id ? data.data.checkins : [data.data];
    }

    get(forceUpdate, callback, fail) {
        super.get(forceUpdate, () => {
            this.data.is_single = true;
            this.data.checkin = this.data.data[0];
            this.data.threadTitle = flight.getFlightTitle(
                this.data.checkin.data.data.first_flight
            );
            if (callback) callback();
        }, fail);
    }
}
