import {
    addEvent,
    deepcopy,
    getScrollTop,
    is_expired,
    is_xs,
    setScrollTop,
    time,
    time_delta,
    toggleClass
} from '../helpers';

import GenericView from '../GenericView';
import chat_widget_buy_checkins from '../components/chat/chat_widget_buy_checkins';
import chat_widget_bpass from '../components/chat/chat_widget_bpass';
import chat_widget_request from '../components/chat/chat_widget_request';
import chat_widget_invoice from '../components/chat/chat_widget_invoice';
import chat_widget_seatmap_request from '../components/chat/chat_widget_seatmap_request';
import chat_widget_seat_preferences from '../components/chat/chat_widget_seat_preferences';
import chat_widget_form from '../components/chat/chat_widget_form';
import flight from '../components/flight';

const CHAT_MESSAGE_CREATE_THREAD = 0;
const CHAT_MESSAGE_REMOVE_THREAD = 1;
const CHAT_MESSAGE_THREAD_MESSAGE = 2;
const CHAT_MESSAGE_MESSAGE_READ = 3;
const CHAT_MESSAGE_FILE_UPLOAD = 4;
const CHAT_STATUS_CLOSED = 'closed';
const CHAT_STATUS_WAITING_FOR_USER = 'waiting_for_user';
const CHAT_STATUS_WAITING_FOR_OPERATOR = 'waiting_for_operator';
const CHAT_STATUS_IDLE = 'idle';
const CHAT_GENERAL_THREAD = 'general';
const CHAT_CHECKIN_STATE_SUCCESS = 'CheckinSucceeded';
const CHAT_CHECKIN_STATE_FAILED = 'CheckinFailed';
const CHAT_CHECKIN_STATE_DEFAULT = 'CheckinDefault';
const CHAT_FLUSH_TIME = '2019-05-31';
const IS_BOTTOM_THEASHOLD = 50;

export default class chat extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat'), data);
        this.wildcard_uri = 'chats/';

        const chat = this.app.getObject(this.wildcard_uri);
        if (chat) {
            chat.needReRender = true;
            chat.uri = uri;
            return chat;
        }
        this.generalThread = document.getElementById('chat-general')
            .innerHTML.trim();
        this.wsSentryReportAfter = 5;
        this.firstReconnectAfter = 3;
        this.secondReconnectAfter = 10;
        this.renderTimeoutDelta = 300;
        this.renderTimeoutFlush = 1500;
        this.wsReconnectTry = 0;
        this.timers = {};
        this.wsReconnectTimeout = 1;
        this.opPrefix = 'cinop_';
        this.bindOnkeyboard = false;
    }

    initForm() {}

    getReconnectTimeout() {
        const times = [1, 1, 3, 3, 5, 5, 5, 5, 10];
        const result = (this.wsReconnectTry > 10)
            ? times[times.length - 1]
            : times[this.wsReconnectTry];
        this.wsReconnectTry++;
        return result;
    }

    reconnectWithTimeout(error_code = 0) {
        this.app.online(false);
        this.app.revalidating(false);
        if (this.wsReconnectTry < this.wsSentryReportAfter) {
            if (error_code > 4000 && error_code < 4999) {
                this.reportBug(`Socket open error ${
                    this.wsReconnectTry
                } times with code ${error_code - 4000}`);
                if (error_code  === 4000 + 401 || error_code  === 4000 + 403 ) {
                    this.getUserData(() => {}, () => {}, true);
                }
            }
        }
        else if (this.wsReconnectTry % 10 == 0) {
            this.reportBug(`Socket closed with code ${error_code}`);
        }
        this.ws = undefined;
        setTimeout(() => this.connectWS(), this.getReconnectTimeout() * 1000);
    }

    getLastMessageId() {
        if (!this.data.data) return 0;
        return this.data.data.reduce((maxId, thread) => {
            maxId = Math.max(maxId, thread.msgId || 0);
            if (!thread.messages) return maxId;
            return thread.messages.reduce(
                (maxId, message) => Math.max(maxId, message.msgId || 0),
                maxId
            );
        }, 0);
    }

    connectWS() {
        if (!this.userData) {
            this.getUserData(
                () => this.connectWS(),
                () => this.reconnectWithTimeout()
            );
            return;
        }
        ;
        if (this.ws) {
            switch (this.ws.readyState) {
                case 0:
                    console.log('Chat ws already tried');
                    return;
                case 1:
                    console.log('Chat ws already open');
                    return;
                case 2:
                    console.log('Chat ws already closing');
                    return;
            }
            this.ws = undefined;
        }
        ;

        const ws_endpoint = this.app.settings.chat_endpoint;
        this.app.revalidating(true);
        try {
            const lastMessageId = this.getLastMessageId();
            const ws_uri = `${ws_endpoint}/ws/user/${this.userData.id}?token=${this.app.token}${
                lastMessageId ? `&lastMessageId=${lastMessageId}` : ''
            }`;
            console.log(`Connecting via webSocket ${ws_uri}`);
            this.ws = new WebSocket(ws_uri);
        } catch (err) {
            console.error(err);
            this.reportBug(`Unexpected socket error ${err}`);
            if (fail) fail();
        }
        this.ws.onmessage = event => this.receive(event.data, event);

        this.ws.onclose = event => {
            console.log(`WebSocket closed ${event.code}`);
            this.reconnectWithTimeout(event.code);
        }
        this.ws.onopen = () => {
            this.wsWaitingForFirstData = true;
        }
    }

    onHide() {
        this.app.toggleMainMenu(true);
    }

    onShow() {
        this.resize();
        this.app.toggleMainMenu(this.data.activeThread ? false : true);
    }

    is_connected() {
        return this.ws && this.ws.readyState === 1 && !this.wsWaitingForFirstData
            && !this.waitingForUserData;
    }

    getUserData(callback, fail, force = false) {
        if (this.waitingForUserData) return;
        this.waitingForUserData = true;

        console.log('Chat gettings userdata ...');
        this.app.getUserData(userProfile => {
            this.waitingForUserData = false;
            console.log('Got userdata');
            this.userData = userProfile;
            callback(this.userData);
        }, (userView, status) => {
            if (status === 401 || status === 403) {
                console.log('User unauthorized ');
                return;
            }
            console.log('Failed to get userdata, will retry');
            this.waitingForUserData = false;
            if (fail) fail();
        }, force);
    }

    show(callback) {
        super.show(callback);
        this.render();
    }

    get(forceUpdate, callback, fail) {
        if (!this.data) this.data = {};
        if (!this.data.data) this.data.data = [];
        if (this.app.settings.is_chat_disabled) {
            console.log('chat disabled by flag is_chat_disabled');
            if (callback) callback();
            return this;
        }
        const launchConnect = (force) => {
            if (force && this == this.app.current_view) this.loading(true);
            this.data.unreadMessages = this.data.unreadMessages || 0;
            this.connectWS();
        };
        if (forceUpdate) {
            if (this.ws) {
                this.ws.onclose = () => {
                };
                this.ws.close();
                this.ws = undefined;
            }
            launchConnect(true);
        } else if (!this.is_connected()) {
            if (!this.ws && !this.app.dataStorage.needRevalidate(this.uri)) {
                launchConnect();
            }
            this.app.dataStorage.get(this.wildcard_uri, cache => {
                if (!cache || !cache.flush_time || cache.flush_time < CHAT_FLUSH_TIME) {
                    console.log(`Flushing chat cache ${cache && cache.flush_time || ''}`);
                    this.data = {data: []};
                } else {
                    this.data = cache;
                }
                this.app.revalidateData(this);
            }, () => launchConnect(true));
        }
        if (callback) callback();
        return this;
    }

    getWidget(message, thread) {
        const message_widget = {
            buyCheckins: chat_widget_buy_checkins,
            bpass: chat_widget_bpass,
            request: chat_widget_request,
            invoice: chat_widget_invoice,
            seatmap: chat_widget_seatmap_request,
            seatprefs: chat_widget_seat_preferences,
            form: chat_widget_form
        }[message.widget.type];
        if (!message_widget) return null;
        const uri = `${this.uri}chat_${message.widget.type}/${message.clientId}/`;
        let widget = this.app.objects[uri];
        if (widget) return widget;

        const widget_message = deepcopy(message);
        widget_message.text = this.getTranslatedMessage(widget_message.text);
        widget = new message_widget(uri, this.app, null,
            {
                data: widget_message,
                thread,
                isThisLastMessage: () => {
                    return !thread.messages
                        || !thread.messages.length
                        || thread.messages.findIndex(
                            msg => msg.msgId == message.msgId
                        ) >= thread.messages.length - 1
                }
            }
        );
        widget.chat = this;
        this.app.setObject(widget.uri, widget);
        return widget;
    }

    findThread(clientThreadId) {
        return this.data.data && this.data.data.find(
            thread => thread.clientThreadId === clientThreadId
        );
    }

    findCheckinSeatmapThread(checkinId) {
        return this.data.data && this.data.data.find(thread => {
            return (
                thread.checkinId === checkinId
                && thread.type === 'seat_change'
            )
        });
    }

    findMessageInThread(thread, clientId) {
        return thread.messages && thread.messages.find(
            msg => msg.clientId === clientId
        );
    }

    findLastSeatmapRequestInThread(thread) {
        if (!thread.messages || !thread.messages.length) return null;
        return [].concat(thread.messages).reverse().find(
            msg => msg.widget && msg.widget.type === 'seatmap'
        )
    }

    findMessage(clientId, clientThreadId) {
        if (clientThreadId) {
            return this.findMessageInThread(this.findThread(clientThreadId), clientId)
        } else {
            return undefined;
        }
    }

    clearTimer(timer) {
        if (this.timers[timer]) {
            clearTimeout(this.timers[timer]);
            delete this.timers[timer];
        }
    }

    clearTimers(methodName) {
        this.clearTimer(methodName);
        this.clearTimer(`${methodName}_flush`);
    }

    delayed(methodName, args) {
        const call = () => {
            // console.log(`Calling method ${methodName} after ${
            //     time() - this.timers[`${methodName}_flush`]
            // } ms delay`);
            this[methodName].call(this, args);
        }
        // resesting main timer
        this.clearTimer(methodName);
        this.timers[methodName] = setTimeout(call, this.renderTimeoutDelta);
        if (!this.timers[`${methodName}_flush`]) {
            this.timers[`${methodName}_flush`] = time();
        }
        // flush time
        else if (is_expired(
            this.timers[`${methodName}_flush`],
            this.renderTimeoutFlush
        )) {
            call();
        }
    }

    loading(flag) {
        super.loading(flag);
        if (!flag && this.view) {
            const nonblockingLoading = this.view.querySelector(
                '[data-bind="nonblocking-loader"]'
            );
            if (nonblockingLoading) nonblockingLoading.remove();
        }
    }

    receive(json, event) {
        if (this.wsWaitingForFirstData) {
            this.wsReconnectTimeout = 1;
            this.wsReconnectTry = 0;
            this.ws.onerror = null;
            this.loading(false);
            this.app.revalidating(false);
            this.app.online(true);
            this.data.data = this.data.data || [];
            this.wsWaitingForFirstData = false;
        }

        let data = JSON.parse(json);
        data.messages.forEach(message => {
            this.receiveMessage(message);
        });
        this.delayed('save');

        if (this.wsOrder && this.wsOrder.length) {
            this.wsOrder.forEach(
                msg => this.ws.send(JSON.stringify(msg))
            );
            this.wsOrder = [];
        }
        ;
    }

    getThreadStateIcon(thread) {
        if (!thread.type || thread.type === CHAT_GENERAL_THREAD) {
            switch (thread.checkinState) {
                case CHAT_CHECKIN_STATE_SUCCESS:
                case CHAT_CHECKIN_STATE_FAILED:
                    return `generalThread-${thread.checkinState}`
                default:
                    return `generalThread-${CHAT_CHECKIN_STATE_DEFAULT}`
            }
            ;
        } else {
            return thread.type;
        }
    }

    processReceivedMessage(thread, message) {
        const isThreadActive = this.app.current_view === this
            && thread === this.data.activeThread;
        if (chat.isMessaageUnread(message)) {
            if (isThreadActive) {
                this.sendReaded(message);
                message.is_readed = true;
            } else {
                thread.unreadMessages++;
                // console.log('unreadMessages ++');
            }
        }
        if (message.inReplyTo) {
            const inReplyTo = thread.messages.find(
                msg => msg.clientId === message.inReplyTo
            );
            if (inReplyTo) {
                thread.messages.filter(msg => {
                    return (
                        msg.inReplyTo === message.inReplyTo
                        && msg.clientId != message.clientId
                    )
                }).forEach(
                    msg => msg.is_deleted = true
                )
                inReplyTo.has_reply = true;
            }
        }
        if (message.is_mine) message.is_delivered = true;
        if (message.timer) {
            thread.timer = {
                timeout: message.timer,
                tst: message.tst
            }
        }
        if (!thread.state || (message.state && message.state != thread.state)) {
            thread.state = message.state || CHAT_STATUS_WAITING_FOR_OPERATOR;
            this.calculateThreadFlags(thread);
            if (isThreadActive) this.delayed('render');
        }
        if (message.checkinState) {
            thread.checkinState = message.checkinState;
            thread.checkinStateIcon = this.getThreadStateIcon(thread);
        }
        ;
        if ('manual' in message && thread.manual != message.manual) {
            thread.manual = message.manual;
            if (isThreadActive) this.delayed('render');
        }
        ;
        if (isThreadActive) {
            this.delayed('renderMessages');
        }
        this.delayed('renderThreads');
    }

    processCreateThread(data) {
        if (!data.type || data.type === CHAT_GENERAL_THREAD) {
            data.checkinState = CHAT_CHECKIN_STATE_DEFAULT;
        }
        if (!data.type_name) {
            data.type_name = this[`${data.type}_translation`]
        }
        if (data.type !== CHAT_GENERAL_THREAD && this.isThreadMine(data)) {
            data.unreadMessages = 1;
        } else {
            data.unreadMessages = 0;
        }
        data.checkinStateIcon = this.getThreadStateIcon(data);
        this.calculateThreadFlags(data);
        this.data.data.splice(0, 0, data);
    }

    calculateThreadFlags(thread) {
        const threadType = thread.type || CHAT_GENERAL_THREAD;
        thread.is_closed = (thread.state === CHAT_STATUS_CLOSED);
        thread.waiting_for_user = thread.state === CHAT_STATUS_WAITING_FOR_USER
        thread.waiting_for_operator = thread.state === CHAT_STATUS_WAITING_FOR_OPERATOR;
        thread.is_idle = thread.state === CHAT_STATUS_IDLE;
        thread.can_delete = (threadType != CHAT_GENERAL_THREAD);
        thread.can_renew = thread.is_closed;
        thread.can_close = !thread.is_closed && !thread.idle && thread.can_delete;
        thread.can_make_idle = thread.waiting_for_operator
            && (threadType != CHAT_GENERAL_THREAD);
        thread.has_actions = (thread.can_delete || thread.can_close
            || thread.can_make_idle || thread.can_renew) && !thread.prepared;
        if (thread.is_closed || thread.waiting_for_user) {
            thread.timer = null;
        }
        if (thread.is_closed) {
            thread.manual = false;
        }
    }

    receiveMessage(data) {
        const msgId = data.id;
        const clientId = data.clientId || `${this.opPrefix}${data.id}`;
        const clientThreadId = data.clientThreadId;
        const tst = data.tst;
        const mesageType = data.type;

        if (data.json) {
            data = JSON.parse(data.json) || {};
            data.clientId = clientId;
            data.clientThreadId = clientThreadId;
            data.tst = tst;
        }
        if (msgId) data.msgId = msgId;

        let thread = this.findThread(clientThreadId);
        let message = thread ? this.findMessageInThread(thread, clientId) : null;

        switch (mesageType) {
            case CHAT_MESSAGE_CREATE_THREAD:
                // console.log(`Chat ${msgId} CREATE_THREAD ${clientId} received`);
                if (!thread) {
                    this.processCreateThread(data);
                    this.delayed('render');
                }
                break;

            case CHAT_MESSAGE_REMOVE_THREAD:
                // console.log(`Chat REMOVE_THREAD ${msgId} ${clientId} received`);
                if (thread) {
                    const i = this.data.data.findIndex(
                        thread => thread.clientThreadId === clientThreadId
                    );
                    this.data.data.splice(i, 1);
                    this.delayed('render');
                }
                break;

            case CHAT_MESSAGE_FILE_UPLOAD:
            case CHAT_MESSAGE_THREAD_MESSAGE:
                // console.log(`Chat ${msgId} THREAD_MESSAGE ${clientId} received`);
                if (!thread) {
                    console.error(`Thread not found for message ${clientId}`);
                    return;
                }
                if (!message) {
                    if (!thread.messages) thread.messages = [];
                    thread.messages.push(data);
                    message = data;
                } else {
                    console.log(`Chat message present ${message.text}`);
                }
                this.processReceivedMessage(thread, message);
                break;

            case CHAT_MESSAGE_MESSAGE_READ:
                if (!data.id) return;
                if (!thread) {
                    console.error(`Thread not found ${clientThreadId}`);
                    return;
                }

                if (thread == this.data.activeThread) {
                    console.log(`Chat ${msgId} MESSAGE_READ ${data.id} ${clientId} thread received`);
                }

                if (thread.msgId == data.id) {
                    thread.is_readed = true;
                } else {
                    message = thread.messages && thread.messages.find(
                        msg => msg.msgId == data.id
                    );
                    if (!message) {
                        console.log(`Got message delivery report for message ${data.id} not found in thread ${thread.name}`);
                        return
                    }
                    message.is_readed = true;
                }

                thread.unreadMessages--;
                if (thread.unreadMessages < 0) thread.unreadMessages = 0;
                this.delayed('renderThreads');

                break;

            default:
                console.error(`received unknown message type ${data.type}`);
                return;
        }
    }

    save() {
        this.clearTimers('save');
        this.delayed('setUnreadBadge');
        this.app.platform.setBadge(this.data.unreadMessages);
        this.app.dataStorage.set(this.wildcard_uri, {
            data: this.data.data,
            flush_time: CHAT_FLUSH_TIME
        });
    }

    static isMessageReadable(message) {
        return message.text || message.widget
            || (message.file && message.file.url);
    }

    static isMessaageUnread(msg) {
        return (
            !msg.is_mine && !msg.is_readed && msg.msgId
            && chat.isMessageReadable(msg)
        )
    }

    getThreadInitialMessage(thread) {
        if (!thread.text) return null;

        const tst = (typeof (thread.tst) === 'string')
            ? new Date(thread.tst)
            : thread.tst
        const msg = {
            text: this.getTranslatedMessage(thread.text),
            tst: tst,
            date: tst.toISOString(),
            is_readed: thread.is_readed,
            is_delivered: true,
            clientId: thread.clientId,
            clientThreadId: thread.clientThreadId,
            msgId: thread.msgId,
        };
        if (thread.widget) {
            msg.widget = thread.widget;
            msg.widget = this.getWidget(msg, thread);
        }
        return msg;
    }

    isThreadMine(thread) {
        return !(thread.clientId+'').startsWith(this.opPrefix);
    }

    renderMessages() {
        this.clearTimers('renderMessages');
        if (!this.view) {
            console.error('renderMessages called before view ready');
            return;
        }
        const chatBody = this.view.querySelector('[data-bind="chat-body"]');
        if (!chatBody) {
            console.error('renderMessages no chatBody');
            return;
        }

        const lang = this.app.settings.lang;
        const chat_data = [];
        const activeThread = this.data.activeThread;
        let is_mine = this.isThreadMine(activeThread);
        let currentUnreadMessages = 0;

        const initialMessage = this.getThreadInitialMessage(activeThread);
        if (initialMessage) {
            chat_data.push({ is_mine, messages: [initialMessage] });
        }
        if (activeThread.messages) {
            let lastDate = chat_data.length
                ? chat_data[0].messages[0].tst.getDate()
                : null;
            activeThread.messages.forEach(rawMsg => {
                const message = {};
                message.text = this.getTranslatedMessage(rawMsg.text);
                [
                    'msgId', 'clientId', 'clientThreadId', 'tst',
                    'is_mine', 'is_delivered', 'is_deleted', 'is_readed',
                    'widget', 'file'
                ].forEach(prop => message[prop] = rawMsg[prop]);
                if (typeof (message.tst) === 'string') {
                    message.tst = new Date(message.tst);
                }
                if (message.tst.getDate() != lastDate) {
                    lastDate = message.tst.getDate();
                    message.date = message.tst.toISOString();
                }
                if (rawMsg.widget) {
                    message.widget = this.getWidget(rawMsg, activeThread);
                }
                if (is_mine !== rawMsg.is_mine) {
                    chat_data.push({
                        is_mine: rawMsg.is_mine,
                        messages: []
                    });
                    is_mine = rawMsg.is_mine;
                }
                ;
                if (rawMsg.options) {
                    message.options = rawMsg.options.map(opt => {
                        return {
                            value: opt.value,
                            text: opt.text || opt[lang] || opt.en
                        }
                    });
                }
                if (chat.isMessageReadable(message)) {
                    chat_data[chat_data.length - 1].messages.push(message);
                }
                if (chat.isMessaageUnread(message)) {
                    currentUnreadMessages++;
                }
            });
            if (activeThread.unreadMessages != currentUnreadMessages) {
                activeThread.unreadMessages = currentUnreadMessages;
                this.delayed('save');
            }
        }
        this.data.chat = chat_data;

        const template = document.getElementById('chat-body');
        chatBody.innerHTML = this.app.Mustache.render(
            template.innerHTML, this.data
        );
        this.app.renderIcons(chatBody);
        this.bindMessagesEvents();
        this.app.bindLinksCollapse(chatBody);
        super.renderSubcomponents(chatBody, 1);
        this.adjustMessageInputSize();

        const isOnBottom = chatBody.scrollTop + chatBody.clientHeight >=
            chatBody.scrollHeight - IS_BOTTOM_THEASHOLD;
        if (isOnBottom) delete activeThread.scrollTop;
        if ('scrollTop' in activeThread) {
            chatBody.scrollTop = activeThread.scrollTop;
        } else {
            this.delayed('scrollToFirstUnreadMessage');
        }
        this.delayed('readActiveThreadMessages');
        this.updateTimers();
    }

    bindMessagesEvents() {
        const chatBody = this.view.querySelector('[data-bind="chat-body"]');
        addEvent(chatBody.querySelectorAll('[data-bind="option"]'), 'click',
            event => {
                this.sendMessage({
                    text: event.target.textContent.trim(),
                    value: event.target.dataset.value
                }, this.data.activeThreadId);
            }
        );
        addEvent(chatBody, 'scroll',
            event => this.delayed('readActiveThreadMessages')
        );
        addEvent(
            chatBody.querySelectorAll('[data-bind="thread-cancel"]'),
            'click',
            event => {
                this.data.data.splice(this.data.data.findIndex(
                    thread => thread.clientThreadId === this.data.activeThreadId
                ), 1);
                this.data.unreadMessages --;
                this.save();
                this.app.history_back();
            }
        );
        addEvent(
            chatBody.querySelectorAll('[data-bind="thread-accept"]'),
            'click',
            event => {
                const clientThreadId = this.data.activeThread.clientThreadId;
                delete this.data.activeThread.prepared;
                delete this.data.activeThread.clientThreadId;
                this.send(
                    CHAT_MESSAGE_CREATE_THREAD,
                    this.data.activeThread,
                    this.data.activeThreadId
                );
                this.render();
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="collapse-closeThreadForm"]'),
            'click',
            event => {
                event.preventDefault();
                toggleClass(
                    chatBody.querySelector('[data-bind="closeThreadForm"]'),
                    'in',
                );
                toggleClass(
                    chatBody.querySelector('[data-bind="btn-closeThreadForm"]'),
                    'in'
                );
                chatBody.scrollTop = chatBody.scrollHeight;
            }
        );
        addEvent(
            chatBody.querySelectorAll('[data-bind="close-thread"]'),
            'click',
            event => {
                const reasonControl = event.target.form.closeThreadReason;
                const confirmReason = reasonControl.options[reasonControl.selectedIndex]
                    .textContent.trim();
                const messageText = document.getElementById('chat-close-thread-text')
                    .textContent.trim() + confirmReason;
                this.sendMessage({
                    text: messageText,
                    state: CHAT_STATUS_CLOSED
                }, this.data.activeThreadId);
            }
        );
    }

    scrollToFirstUnreadMessage() {
        const chatBody = this.view.querySelector('[data-bind="chat-body"]');
        const firstUnreadMsg = this.data.activeThread.messages &&
            this.data.activeThread.messages.find(
                msg => !msg.is_mine && !msg.is_readed && chat.isMessageReadable(msg)
            );
        if (!firstUnreadMsg) {
            chatBody.scrollTop = chatBody.scrollHeight;
            return;
        }
        try {
            const view = chatBody.querySelector(`#${firstUnreadMsg.clientId}`);
            if (view) {
                chatBody.scrollTop = view.offsetTop;
            };
        } catch (err) {
            console.error(err);
            chatBody.scrollTop = chatBody.scrollHeight;
        }
    }

    readActiveThreadMessages() {
        this.clearTimers('readActiveThreadMessages');
        if (!this.data.activeThread) return;
        const chatBody = this.view.querySelector('[data-bind="chat-body"]');
        const readLine = chatBody.scrollTop + chatBody.clientHeight;
        try {
            const unreadMessages = this.data.chat.reduce((acc, group) => {
                return acc.concat(group.messages.map( msg => {
                    msg.is_mine = group.is_mine;
                    return msg
                }));
            }, []).filter(msg => {
                if (!chat.isMessaageUnread(msg)) return false;
                const view = chatBody.querySelector(`#${msg.clientId}`);
                return !view || (view.offsetTop < readLine);
            });
            unreadMessages.forEach(msg => this.sendReaded(msg));
        } catch (err) {
            console.error(err);
        }
    }

    getTranslatedMessage(msg) {
        const lang = this.app.settings.lang;
        let messageText = (msg && typeof (msg) === 'object')
            ? (msg[lang] || msg.en)
            : msg;

        if (messageText) {
            messageText = (messageText + '')
                .replace(/<mailto:([^\|>]+)\|?([^>]*)>/g, '$1');
        }

        return messageText;
    }

    isChatSeatmapRequestActive(thread, clientId) {
        const lastSeatmapRequest = [this.getThreadInitialMessage(thread)]
            .concat(thread.messages || [])
            .filter(msg => typeof(msg) === 'object')
            .reverse()
            .find(msg => msg.widget && msg.widget.type === 'seatmap');
        if (
            lastSeatmapRequest && clientId
            && lastSeatmapRequest.clientId !== clientId
        ) {
            return false;
        } else {
            // if this is last seatmap request, check if already replied
            return !thread.waiting_for_operator && !thread.is_closed;
        }
    }

    renderThreads() {
        this.clearTimers('renderThreads');
        if (!this.view) return;
        const threadsView = this.view.querySelector('[data-bind="threads"]');
        if (!threadsView) return;

        const template = document.getElementById('chat-threads');
        const getThreadLastMessage = (thread) => {
            let i = thread.messages ? thread.messages.length - 1 : -1;
            while (i > 0 && !thread.messages[i].text) {
                i--
            }
            return i >= 0
                ? thread.messages[i]
                : {tst: thread.tst, text: thread.text, is_delivered: true};
        }
        this.data.unreadMessagesAlerts = 0;
        this.data.unreadMessagesServices = 0;
        this.data.unreadMessages = 0;
        this.data.threads = this.data.data.map(thread => {
            const lastMsg = getThreadLastMessage(thread);
            lastMsg.text = this.getTranslatedMessage(lastMsg.text);
            if (!thread.type) {
                thread.type = CHAT_GENERAL_THREAD;
                thread.type_name = this.generalThread;
            }
            if (!thread.unreadMessages) {
                thread.unreadMessages = 0;
            }
            if (thread.type === CHAT_GENERAL_THREAD) {
                this.data.unreadMessagesAlerts += thread.unreadMessages;
            } else {
                this.data.unreadMessagesServices += thread.unreadMessages;
            }
            this.data.unreadMessages += thread.unreadMessages;

            return {
                is_active: this.data.activeThreadId === thread.clientThreadId,
                data: thread,
                is_closed: thread.is_closed,
                lastMsg
            };
        }).filter(
            thread => (thread.data.type !== CHAT_GENERAL_THREAD) === this.data.is_services
        ).sort((a, b) => a.lastMsg.tst < b.lastMsg.tst ? 1 : -1);
        threadsView.innerHTML = this.app.Mustache.render(
            template.innerHTML, this.data
        );

        Array.prototype.forEach.call(
            this.view.querySelectorAll('[data-bind="no-threads-message"]'),
            emptyMessage => toggleClass(
                emptyMessage, 'in', !this.data.threads.length
            )
        );
        this.app.renderIcons(threadsView);
        this.delayed('setUnreadBadge');
        addEvent(
            threadsView.querySelectorAll('[data-bind="activate_thread"]'),
            'click',
            event => {
                event.preventDefault();
                const target = event.target.closest('[data-bind="activate_thread"]');
                this.changeThread(target.dataset.id)
                this.render();
            }
        );
    }

    render(level) {
        this.clearTimers('render');
        this.clearTimers('renderThreads');
        this.clearTimers('renderMessages');

        const activeThread = this.uri.match(/clientThreadId\/([^\/]+)\/?$/);
        const alerts = this.uri.match(/alerts\/$/);
        const services = this.uri.match(/services\/$/);

        this.data.activeThreadId = activeThread && activeThread[1];
        if (services) {
            this.data.is_alerts = false;
            this.data.is_services = true;
        } else {
            this.data.is_services = false;
            this.data.is_alerts = true;
        }
        this.data.is_connected = this.is_connected();

        if (this.data.activeThreadId) {
            this.data.activeThread = this.findThread(this.data.activeThreadId) || {};
        } else {
            this.data.activeThread = null;
        }
        if (this.data.activeThread && this.data.activeThread.type) {
            this.data.is_services = this.data.activeThread.type !== CHAT_GENERAL_THREAD;
            this.data.is_alerts = !this.data.is_services;
        }
        this.app.toggleMainMenu(this.data.activeThread ? false : true);
        super.render(level);
        this.renderThreads();
        if (this.data.activeThread) this.renderMessages();
        toggleClass(this.view, 'active', !this.data.activeThread ? true : false);
        if (!this.resized) {
            this.resize();
            this.resized = true;
        }
    }

    resize() {
        this.clearTimers('resize');
        if (this.app.current_view !== this) return;
        this.setChatHeight();
        this.adjustMessageInputSize();
        setScrollTop(0);
    }

    setChatHeight() {
        this.data.chat_height = (
            window.innerHeight
            - (is_xs()
                    ? this.view.getBoundingClientRect().top + getScrollTop()
                    : 15 + document.querySelector('.cin-header').clientHeight
            )
        );
        const chats = this.view && this.view.querySelector('[data-bind="chats"]');
        if (chats) chats.style.height = this.data.chat_height + 'px';
    }

    setUnreadBadge() {
        [
            {selector: 'chat-badge', badgeType: 'unreadMessages'},
            {selector: 'chat-badge-alerts', badgeType: 'unreadMessagesAlerts'},
            {selector: 'chat-badge-services', badgeType: 'unreadMessagesServices'},
        ].forEach(i => {
            Array.prototype.forEach.call(
                window.document.querySelectorAll(`[data-bind="${i.selector}"]`),
                badge => {
                    badge.innerHTML = this.data[i.badgeType];
                    toggleClass(badge, 'in', this.data[i.badgeType] > 0);
                }
            )
        });
    }

    removeThread(threadId) {
        const index = this.data.data.findIndex(
            thread => thread.clientThreadId === threadId
        );
        if (index < 0) return;
        this.data.data.splice(index, 1);
        this.send(CHAT_MESSAGE_REMOVE_THREAD, null, threadId);
        this.save();
        if (threadId === this.data.activeThreadId) {
            this.data.activeThreadId = null;
            this.render();
        } else {
            this.renderThreads();
        }
    }

    getThreadURI(threadId) {
        return `${this.wildcard_uri}clientThreadId/${threadId}/`;
    }

    changeThread(threadId) {
        if (this.data.activeThread) {
            const chatBody = this.view.querySelector('[data-bind="chat-body"]');
            if (
                chatBody.scrollTop + chatBody.clientHeight >= chatBody.scrollHeight - 15
            ) {
                this.data.activeThread.scrollTop = chatBody.scrollTop;
            } else if ('scrollTop' in this.data.activeThread) {
                delete this.data.activeThread.scrollTop
            }
        }
        this.data.activeThreadId = threadId;
        this.uri = this.getThreadURI(threadId);
        this.data.activeThread = this.findThread(threadId);
        if (this.data.user.is_vip) {
            this.data.activeThread.manual = true;
        }
        this.app.processHistroy({
            uri: this.uri,
            length: this.app.history.length + 1
        });
    }

    generateUniqueID(type) {
        return `app${type}_${this.userData.id}_${Date.now()}`;
    }

    _send(msg) {
        if (!msg.tst) {
            msg.tst = (new Date()).toISOString()
        }
        ;
        if (this.is_connected()) {
            this.ws.send(JSON.stringify(msg));
        } else {
            if (!this.wsOrder) this.wsOrder = [];
            this.wsOrder.push(msg);
        }
    }

    formSubmit(form, event) {
        event.preventDefault();
        return;
    }

    send(mgsType, data, threadId) {
        const msg = {
            clientId: this.generateUniqueID(mgsType || 'g'),
            clientThreadId: threadId,
            type: mgsType,
            json: JSON.stringify(data || {}),
        };
        this._send(msg);
        const result = data || {};
        result.clientId = msg.clientId;
        result.clientThreadId = msg.clientThreadId;
        result.tst = msg.tst;
        return result;
    }

    reportBug(message, extra = {}) {
        console.log(`Trying to report chat bug ${message}`);
        this.app.reportBugSentry(message, extra)
    }

    sendMessage(data, threadId) {
        if (data && !('is_mine' in data)) data.is_mine = true;
        if (!data.state) data.state = CHAT_STATUS_WAITING_FOR_OPERATOR;
        const message = this.send(CHAT_MESSAGE_THREAD_MESSAGE, data, threadId);
        const thread = this.findThread(threadId);
        if (!thread.messages) thread.messages = [];
        thread.messages.push(message);
        // show message only if the same thread active
        // before it will be sent us back
        if (
            this == this.app.current_view &&
            threadId == this.data.activeThreadId
        ) {
            this.delayed('renderMessages');
        }
    }

    createNewThread(data) {
        if (data.checkinId && data.type && this.data.data) {
            const thread = this.data.data.find(thread => {
                return thread.checkinId === data.checkinId
                    && thread.type === data.type
            });
            if (thread) {
                this.changeThread(thread.clientThreadId);
                return;
            }
        }
        data.clientThreadId = this.generateUniqueID('thread');
        data.tst = (new Date()).toISOString();

        this.app.platform.sendEvent(`created_thread_${data.type}`);
        this.processCreateThread(data);
        this.changeThread(data.clientThreadId);
        this.render();
        this.delayed('save');
    }

    adjustMessageInputSize(noInput = false) {
        const messageControl = this.view.querySelector('[data-bind="message-text"]');
        if (!messageControl || !this.data.activeThread) {
            return;
        }
        const chatBody = this.view.querySelector('[data-bind="chat-body"]');
        const chatFooter = this.view.querySelector('[data-bind="chat-footer"]');
        const chatHeader = this.view.querySelector('[data-bind="chat-header"]');
        const chats = this.view.querySelector('[data-bind="chats"]');
        const messageText = messageControl.value;
        if (messageText) this.data.activeThread.saved_text = messageText;
        messageControl.style.height = '41px';
        if (messageControl.scrollHeight > messageControl.clientHeight) {
            messageControl.style.height = messageControl.scrollHeight + 'px';
        }
        if (noInput) {
            chatBody.style.height = chats.clientHeight;
        } else {
            chatBody.style.height = (
                chats.clientHeight - chatFooter.clientHeight - chatHeader.clientHeight
            ) + 'px';
        }
        ;
    }

    sendReaded(msg) {
        console.log(`sendReaded ${msg.msgId} ${msg.text}`);
        if (msg.is_readed) {
            console.error('trying to send readed to already readed message');
            return;
        }
        const readMsg = {
            type: CHAT_MESSAGE_MESSAGE_READ,
            json: JSON.stringify({id: msg.msgId}),
            clientId: this.generateUniqueID('read'),
            clientThreadId: msg.clientThreadId
        };
        this._send(readMsg);
        // waiting the same message from backend, but force delivery in 5 sec
        setTimeout(() => this.receiveMessage(readMsg), 5000);
    }

    sendTextMessage() {
        const messageControl = this.view.querySelector('[data-bind="message-text"]');
        const messageText = (messageControl.value + '').trim();
        if (!messageText) return;
        this.data.activeThread.saved_text = '';
        messageControl.value = '';
        messageControl.focus();
        const data = {text: messageText};
        if (messageText === 'testpay') {
            data.widget = {type: 'invoice', amount: 10, currency: 'RUB'}
        };
        this.sendMessage(data, this.data.activeThreadId);
    }

    sendFile(fileNode) {
        this.send(CHAT_MESSAGE_FILE_UPLOAD, {
            file: {
                name: fileNode.name,
                contentType: fileNode.type,
            }
        }, this.data.activeThreadId);

        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState === 2) {
                console.log('Starting message upload');
                this.ws.send(reader.result);
            } else {
                console.log(`File upload ready state ${reader.readyState}`);
            }
        }
        reader.onerror = (err) => {
            console.error('Error in fileu pload', err);
            reader.abort();
        }
        reader.readAsArrayBuffer(fileNode);
    }

    updateTimers() {
        if (!this.view) return;
        Array.prototype.forEach.call(
            this.view.querySelectorAll('[data-bind="timer"]'),
            container => {
                const timeout = new Date(container.dataset.tst);
                timeout.setTime(timeout.getTime() + parseInt(container.dataset.timeout) * 60 * 1000);
                const minutes = (timeout.getTime() - (new Date()).getTime()) / 60 / 1000;
                container.innerHTML = time_delta(minutes);
            }
        )
    }

    bindLinks() {
        if (this.timersInterval) clearInterval(this.timersInterval);
        this.timersInterval = setInterval(() => this.updateTimers(), 5000);
        if (!this.bindOnkeyboard) {
            this.bindOnkeyboard = true;
            this.app.platform.on('keyboardhide', () => this.delayed('resize'));
            addEvent(window, 'resize', () => this.delayed('resize'));
        }
        addEvent(
            this.view.querySelectorAll('[data-bind="back"]'), 'click', event => {
                this.app.toggleMainMenu(true);
                const histLen = this.app.history.length;
                if (histLen > 1 && !this.app.history[histLen - 2]
                        .startsWith(this.wildcard_uri)) {
                    // use app.histroy_back if we back to previous view
                    return;
                }
                // cancel app.history_back, to save animation
                event.stopImmediatePropagation();
                event.stopPropagation();
                event.preventDefault();
                this.view.classList.add('active');
                this.adjustMessageInputSize(false);
                // process app.histrory changes
                this.app.processHistroy({
                    uri: this.app.history[histLen - 2],
                    length: histLen - 1
                });
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="send"]'),
            'click',
            event => {
                event.preventDefault();
                this.sendTextMessage();
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="upload"]'),
            'click',
            () => this.app.platform.checkUploadFilesSupport()
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="upload"]'),
            'change',
            event => {
                Array.prototype.forEach.call(
                    event.target.files,
                    file => this.sendFile(file)
                )
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="message-text"]'),
            'input',
            event => this.adjustMessageInputSize()
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="message-text"]'),
            'keydown',
            event => {
                if (!event.shiftKey && event.code === 'Enter') {
                    event.preventDefault()
                    this.sendTextMessage()
                }
            }
        );

        const chatBody = this.view.querySelector('[data-bind="chat-body"]');
        addEvent(
            this.view.querySelectorAll('[data-bind="close-thread"]'),
            'click',
            event => {
                setTimeout(() => {
                    chatBody.scrollTop = chatBody.scrollHeight;
                }, 100);
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="remove-thread"]'),
            'click',
            event => {
                const message = this.deleteConfirmation
                    || this.app.lang.deleteConfirmation;
                if (!this.app.confirm(message)) return;
                this.removeThread(this.data.activeThreadId)
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="renew-thread"]'),
            'click',
            event => {
                const confirmText = document.getElementById('chat-renew-thread-confirm')
                    .textContent.trim();
                const messageText = document.getElementById('chat-renew-thread-text')
                    .textContent.trim();
                if (!this.app.confirm(confirmText)) return;
                this.sendMessage({
                    text: messageText,
                }, this.data.activeThreadId);
            }
        );
        super.bindLinks();
    }
}
