import {addClickEvent} from '../../helpers';
import wallet_helpers from '../wallet_helpers';

const MAX_PRICE_RANGES = 3;
const SEATS_EMPTY = 'empty';
const SEATS_OCCUPIED = 'occupied';
const SEATS_SELECTED = 'currrent';

export default class SeatMap {
    constructor(params) {
        this.params = params;
        this.container = params.container;
        this.seatmap = SeatMap.convert(this.params.seatmap);
        this.events = {};
        this.Mustache = params.Mustache || window.Mustache;
        if (!this.params.templates) {
            this.params.templates = {};
        }
        if (!this.params.templates.seatmap) {
            this.params.templates.seatmap = document.getElementById('seatmap').innerHTML;
        }
    }

    static convert(seatmap) {
        if ('sections' in seatmap) {
            return seatmap;
        }
        if ('seatmap' in seatmap) {
            const newSeatmap = seatmap;
            const seats = seatmap.seatmap;
            seats.filter(col => 'rows' in col).forEach(col => col.rows.forEach(
                cell => {
                    if (cell.isExists === false) {
                        cell.type = null;
                        delete cell.isExists;
                    }
                    if (cell.isEmpty === false) {
                        cell.type = SEATS_OCCUPIED;
                        delete cell.isEmpty;
                    } else if (cell.isEmpty === true) {
                        cell.type = SEATS_EMPTY;
                        delete cell.isEmpty;
                    }
                    if (cell.isCurrent) {
                        cell.type = SEATS_SELECTED;
                        delete cell.isCurrent;
                    }
                }
            ));
            delete newSeatmap.seatmap;
            newSeatmap.sections = [{seats}];
            return newSeatmap;
        }
        console.error(`Invalid seatmap format ${JSON.stringify(seatmap)}`);
        return null;
    }

    static currencySign(str) {
        return wallet_helpers.currencySign(str);
    }

    static checkExitRowLeft(cols) {
        const exitCol = cols.findIndex(col => col.isExit);
        const spaceCol = cols.findIndex(col => col.space);
        if (exitCol > 0 && exitCol < spaceCol) {
            cols[0].isExit = true;
            cols[0].classList = ['isExit'];
            return true;
        }
        return false;
    }

    static checkExitRowRight(cols) {
        cols.reverse();
        const result = SeatMap.checkExitRowLeft(cols);
        cols.reverse();
        return result;
    }

    renderSeatmap() {
        const root = this.container;
        if (!this.seatmap ||
            !this.seatmap.sections ||
            (!('length' in this.seatmap.sections))
        ) {
            root.innerHTML = this.Mustache.render(this.params.templates.seatmap, {});
            return;
        }

        const view = {
            is_selectable: this.params.is_selectable,
            is_example: this.params.is_example,
            sections: [],
            currency: this.seatmap.currency,
            currency_sign: wallet_helpers.currencySign(this.seatmap.currency)
        };
        const paidStatus = this.getSeatmapLegend();
        const paidClasses = paidStatus.seatPaidClasses;

        this.seatmap.sections.forEach((section, sectionIndex) => {
            const subview = {
                columns: [],
                rows: [],
                index: sectionIndex
            };
            let maxRow = 0;
            let startNum = section.seats[0] ? section.seats[0].startNum : 100;

            section.seats.forEach((column, index) => {
                subview.columns.push({
                    name: column.rows ? column.seatName : '',
                    index
                });
                if (column.seatsCount || column.rows) {
                    maxRow = Math.max(maxRow, column.seatsCount || 0, column.rows.length);
                    startNum = Math.min(startNum, column.startNum);
                }
            });

            for (let i = 0; i < maxRow; i += 1) {
                const row = {
                    i,
                    num: i + startNum,
                    cols: []
                };

                section.seats.forEach((column, j) => {
                    if (!column.rows) {
                        row.cols.push({space: true});
                    } else if (column.rows[i]) {
                        const seat = Object.assign({}, column.rows[i]);
                        seat.isPaid = seat.type !== SEATS_OCCUPIED && seat.isPaid;
                        if (seat.isPaid) {
                            if (paidClasses) {
                                if (seat.cost > 0) {
                                    seat.seatPaidClass = paidClasses.find(
                                        c => (seat.cost >= c.min) && (seat.cost <= c.max)
                                    ).num;
                                } else {
                                    seat.seatPaidClass = paidClasses.find(
                                        c => c.isUndefinedPrice === true
                                    ).num;
                                }
                            }
                        }
                        seat.classList = Object.keys(seat).filter(
                            key => key.match(/^is/) && seat[key]
                        );
                        seat.classList.push(`seat-${seat.type}`);
                        seat.index = j;
                        if (seat.type) {
                            seat.name = `${column.startNum + i}${column.seatName}`;
                        }
                        seat.isOccupied = seat.type === SEATS_OCCUPIED;
                        seat.isCurrent = seat.type === SEATS_SELECTED;
                        seat.isEmpty = seat.type === SEATS_EMPTY;
                        seat.isExists = seat.type;
                        row.cols.push(seat);
                    } else {
                        row.cols.push({});
                    }
                });

                if (!row.cols[0].isExit) {
                    SeatMap.checkExitRowLeft(row.cols);
                }
                if (!row.cols[row.cols.length - 1].isExit) {
                    SeatMap.checkExitRowRight(row.cols);
                }
                if (row.cols.some(col => col.type)) {
                    subview.rows.push(row);
                }
            }
            view.sections.push(subview);
        });

        root.innerHTML = this.Mustache.render(
            this.params.templates.seatmap,
            view
        );

        addClickEvent(
            root, 'tbody td',
            event => this.seatmapClick(event.currentTarget, event)
        );

        if (this.params.is_example) {
            const container = root.querySelector('[data-bind="seatmap"]');
            const svg_text = window.document.getElementById('text-example')
                .innerHTML
                .replace(/>\s+</g, '><')
                .replace(/#/g, '%23').trim();
            container.style.backgroundImage =
                `url('data:image/svg+xml;utf8,${svg_text}')`;
        }
    }

    on(event, callback) {
        this.events[event] = callback;
    }

    off(event) {
        this.events[event] = null;
    }

    getCurrentSeats() {
        const seats = [];
        this.seatmap.sections.forEach(section => {
            section.seats.filter(col => col.rows && col.rows.length)
                .forEach(col => {
                    col.rows.forEach((seat, index) => {
                        if (seat.type === SEATS_SELECTED) {
                            seats.push((col.startNum + index) + col.seatName);
                        }
                    });
                });
        });
        return seats;
    }

    getSeat(name) {
        const m = `${name}`.match(/(\d+)(\w+)|(\w+)(\d+)/);
        if (!m) return null;
        let row;
        let seatName;

        if (parseInt(m[1], 10)) {
            seatName = m[2];
            row = parseInt(m[1], 10);
        } else {
            seatName = m[3];
            row = parseInt(m[4], 10);
        }
        if (isNaN(row) || !seatName) return null;

        return this.seatmap.sections.reduce((seatL1, section) => (
            seatL1 || section.seats.filter(
                col => col.seatName === seatName.toUpperCase()
            ).reduce(
                (seatL2, col) => seatL2 || col.rows[row - col.startNum],
                null
            )
        ), null);
    }

    removeActiveSeat() {
        this.seatmap.sections.forEach(section => (
            section.seats.filter(col => 'rows' in col).forEach(col => {
                col.rows.forEach(seat => {
                    if (seat.isActive) delete seat.isActive;
                });
            })
        ));
    }

    setActiveSeat(name) {
        this.removeActiveSeat();
        const seat = this.getSeat(name);
        if (!seat) return;
        seat.isActive = true;
    }

    setSeat(name, state) {
        const seat = this.getSeat(name);
        if (!seat) return;
        Object.keys(seat).forEach(key => delete seat[key]);
        Object.keys(state).forEach(key => {
            seat[key] = state[key];
        });
        return seat;
    }

    getSeatmapLegend(seatmap = this.seatmap) {
        const result = {
            hasPaid: false,
            hasFree: false,
            hasUndefinedPrice: false,
            minPaid: -1,
            maxPaid: 0,
            currency: seatmap.currency
        };
        const costs = {};
        const map = seatmap.sections;
        if (!map || !map.length) return result;
        map.forEach(section => {
            section.seats.filter(col => 'rows' in col).forEach(col => {
                col.rows.filter(
                    cell => [SEATS_EMPTY, SEATS_SELECTED].indexOf(cell.type) > -1
                ).forEach(cell => {
                    if (!cell.isPaid) {
                        result.hasFree = true;
                        return;
                    }
                    result.hasPaid = true;
                    if (cell.cost > 0) {
                        costs[cell.cost] = 1;
                        result.minPaid = result.minPaid > 0
                            ? Math.min(result.minPaid, cell.cost)
                            : cell.cost;
                        result.maxPaid = Math.max(result.maxPaid, cell.cost);
                    } else {
                        result.hasUndefinedPrice = true;
                    }
                });
            });
        });
        result.hasXL = map.some(section => section.seats.some(col => {
            if (col.rows && col.rows.length) {
                return col.rows.some(cell => cell.isXL);
            }
            return false;
        }));
        result.hasNorecline = map.some(section => section.seats.some(col => {
            if (col.rows && col.rows.length) {
                return col.rows.some(cell => cell.isNorecline);
            }
            return false;
        }));
        result.hasExit = map.some(section => section.seats.some(col => {
            if (col.rows && col.rows.length) {
                return col.rows.some(cell => cell.isExit);
            }
            return false;
        }));
        result.min = result.hasFree ? 0 : result.minPaid;
        result.max = result.maxPaid;
        if (result.hasPaid) {
            result.seatPaidClasses = [];
            const ranges = Object.keys(costs)
                .map(cost => parseFloat(cost))
                .sort((a, b) => a - b)
                .map(price => ({min: price, max: price}));
            while (
                ranges.length > MAX_PRICE_RANGES - (result.hasUndefinedPrice ? 1 : 0)
            ) {
                const minGapRange = ranges.reduce((minIndex, _, index) => {
                    if (index < 2) return 1;
                    return (
                        ranges[index].max - ranges[index - 1].min
                        < ranges[minIndex].max - ranges[minIndex - 1].min
                    ) ? index : minIndex;
                }, 1);
                ranges[minGapRange - 1].max = ranges[minGapRange].max;
                ranges.splice(minGapRange, 1);
            }
            if (result.hasUndefinedPrice) {
                ranges.unshift({isUndefinedPrice: true});
            }
            ranges.forEach((range, index) => (range.num = index + 1));
            if (result.hasFree) {
                ranges.unshift({isFree: true});
            }
            result.seatPaidClasses = ranges;
        }
        return result;
    }

    seatmapClick(target, event) {
        event.stopPropagation();
        event.preventDefault();

        const colNum = parseInt(target.dataset.index, 10);
        const rowNum = parseInt(target.closest('tr').dataset.index, 10);
        const sectionNum = parseInt(target.closest('table').dataset.index, 10);

        const section = (this.seatmap && this.seatmap.sections && !isNaN(sectionNum))
            ? this.seatmap.sections[sectionNum]
            : null;

        const col = section ? section.seats[colNum] : null;

        if (col) {
            const name = `${rowNum + col.startNum}${col.seatName}`;
            if (typeof (this.events.click) === 'function') {
                this.events.click.call(this, {
                    seat: this.getSeat(name),
                    name
                });
            }
        } else {
            console.log(`seatmapClick cell not found sections[${
                sectionNum}].seats[${colNum}].rows[${rowNum}]`);
        }
    }
}

SeatMap.SEATS_EMPTY = SEATS_EMPTY;
SeatMap.SEATS_OCCUPIED = SEATS_OCCUPIED;
SeatMap.SEATS_SELECTED = SEATS_SELECTED;
