import GenericComponent from '../../GenericComponent';
import {addClickEvent, toggleClass} from '../../helpers';

export default class error extends GenericComponent {
    constructor(uri, app, className, data = {}) {
        super(uri, app, (className || 'error'), data);
        this.data.data = this.data.data || {};
    }

    formSubmit() {
        const data = Object.assign(this.data.data, this.serialize());
        const error_name = this.data.message
            ? (this.data.message_code || this.data.message)
            : `Report from error dialog ${data.email || data.name}`;
        this.app.reportBugSentry(error_name, data);
        this.app.closeModal();
    }

    initForm() {
        super.initForm();
        addClickEvent(this.view, '[data-bind="report"]', () => {
            Array.prototype.forEach.call(
                this.view.querySelectorAll(
                    '[data-bind="reportBug"], [data-bind="report"]'
                ),
                el => toggleClass(el, 'in')
            );
        });
    }
}
