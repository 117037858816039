import GenericComponent from '../../GenericComponent';
import {addEvent} from '../../helpers';

export default class threads_add_flight extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'threads_add_flight'), data);
        this.template = 'threads_add_flight';
    }

    bindLinks() {
        super.bindLinks();
        addEvent(
            this.view.querySelectorAll('[data-bind="add-flight-manual"]'),
            'click', () => this.parent.addFlightManual()
        );
        addEvent(
            this.view.querySelectorAll('[href^="#youtube"]'),
            'click', event => {
                const container = this.view.querySelector(
                    event.target.getAttribute('href')
                );
                if (container) {
                    const status = container.classList.contains('in');
                    const video = container.querySelector('video')
                    if (status) video.play()
                    else video.pause();
                }
            }
        );
    }
}
