import {addClickEvent, addEvent, deepcopy} from '../helpers';
import GenericRestComponent from '../GenericRestComponent';
import wallet_helpers from './wallet_helpers';

const PROVIDER_BRAINTREE = 'braintree';
const PROVIDER_PLATRON = 'platron';
const BRAINTREE_JS = [
    'https://js.braintreegateway.com/web/dropin/1.23.0/js/dropin.min.js'
];
const COMMISSION = {
    EUR: 2,
    USD: 2,
    RUB: 150
};
const COMMISSION_MIN_PERCENT = 0.07;

export default class wallet extends GenericRestComponent {
    constructor(url = 'payment/wallet/', app, className = 'wallet', data = {}) {
        super(url, app, className, data);
        this.events = {};
        this.invoice = deepcopy(data);
        this.topup_balance_url = 'billing/topup_balance/';
        this.pay_order_endpoint = 'billing/pay_order/';
        this.endpoint_uri = 'user/cards/';
        this.buy_subscription_uri = 'user/buy_subscription/';
        this.buy_checkins_uri = 'user/packages/buy/';
        this.ping_transaction_uri = 'billing/transaction_status/';
        const template = document.getElementById(this.template);
        this.status_tranlations = {
            prepared: template.dataset.paymentPrepared,
            finished: template.dataset.paymentFinished,
            failed: template.dataset.paymentFailed,
            locked: template.dataset.paymentLocked
        };
    }

    static initWalletComponent(view, forceUpdate, callback, fail) {
        view.app.getUserData(userdata => {
            const currency = view.data.data && view.data.data[0]
                ? view.data.data[0].currency
                : userdata.currency;
            view.data.wallet = view.app.initObject(
                `${view.uri}wallet/`, 'wallet', {currency}, false
            );
            view.data.wallet.get(forceUpdate, callback);
        }, fail, forceUpdate);
    }

    static getCommission(amount, currency) {
        const COMMISSION = {
            EUR: 2,
            USD: 2,
            RUB: 150
        };
        const COMMISSION_MIN_PERCENT = 0.07;

        return Math.max(
            Math.round(amount * COMMISSION_MIN_PERCENT * 100) / 100,
            COMMISSION[currency] || COMMISSION.RUB
        );
    }

    static currencySign(currency) {
        return wallet_helpers.currencySign(currency);
    }

    static icons(ps) {
        const icons = {
            MasterCard: 'mc',
            Visa: 'visa',
            CA: 'mc',
            VI: 'visa',
            AX: 'ax'
        };
        return icons[ps] || 'other';
    }


    static parsePrice(str = '') {
        return wallet_helpers.parsePrice(str);
    }

    onNetworkError(status, data) {
        if (status === 403) {
            if (data.message_code === 'PaymentCurrencyNotAllowed') {
                this.app.alert(this.paymentErrorCurrency);
            } else {
                this.app.alert(this.paymentLocked);
            }
            this.loading(false);
        } else {
            super.onNetworkError(status, this.paymentError || data);
        }
    }

    on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }

    fire(event) {
        if (this.events[event]) {
            this.events[event].forEach(c => c())
        }
    }

    get(forceUpdate, callback, fail) {
        super.get(forceUpdate, () => {
            this.loading(false);
            this.data.data = this.data.data.filter(
                card => card.is_enabled // && card.currency === this.invoice.currency
            );
            const default_profile = this.data.data.find(card => card.is_default);
            this.data.profile_id = default_profile
                ? default_profile.id
                : this.data.data[0] && this.data.data[0].id;
            this.data.save_profile = true;
            if (callback) callback();
        }, fail);
    }

    render() {
        this.data.invoice = this.invoice;
        this.data.force_save_profile = this.force_save_profile;
        this.data.invoice.currency_sign = wallet.currencySign(this.data.invoice.currency);
        if (this.data.data && this.data.data.length) {
            this.data.data.forEach(card => {
                card.currency_sign = wallet.currencySign(card.currency);
                card.payment_system_icon = wallet.icons(card.payment_system);
                card.is_checked = this.data.profile_id === card.id;
            });
        }
        super.render();
    }


    resetTransactionCallbackFlags() {
        this.pingTransactionTimeoutIndex = 0;
        this.user_input_complete = false;
        this.waiting_for_user_input = false;
        this.is_success = false;
        if (this.pingTransactionPending) {
            clearTimeout(this.pingTransactionPending);
        }
    }

    resetTransaction() {
        this.resetTransactionCallbackFlags();
        delete(this.data.is_transaction_failed);
        delete(this.data.transaction);
    }

    bindLinks() {
        addEvent(this.view.querySelectorAll('input'), 'change', event => {
            if (event.target.name === 'save_profile') {
                this.data.save_profile = event.target.checked;
            }
        });
        addClickEvent(this.view, '[data-bind="try-again"]', event => {
            this.resetTransaction();
            this.render();
            this.fire('retry');
        });
        addClickEvent(this.view, '[data-profile]', event => {
            if (event.target.dataset.bind === 'checkbox') return;
            event.preventDefault();
            const target = event.target.closest('[data-profile]');
            const profile_id = parseInt(target.dataset.profile, 10) || undefined;
            if (profile_id === this.data.profile_id) return;
            this.data.profile_id = profile_id;
            if (!('save_profile' in this.data)) {
                this.data.save_profile = !this.data.profile_id;
            }
            this.render();
        });
        addClickEvent(this.view, '[data-bind="pay_order"]', event => {
            event.preventDefault();
            this.pay_order();
        });
        addClickEvent(this.view, '[data-bind="pay"]', event => {
            event.preventDefault();
            this.pay();
        });
        super.bindLinks();
    }

    pay_order(order_id, callback) {
        order_id = order_id || this.invoice.order_id;
        const data = {order_id, is_mobile: this.app.platform.is_mobile};
        if (this.data.profile_id) data.profile_id = this.data.profile_id;
        if (this.data.save_profile) data.save_profile = this.data.save_profile;
        this.resetTransactionCallbackFlags();
        this.is_payment_canceled = false;
        this.loading(true);
        this.app.ajax({
            url: `${this.app.settings.endpoints}${this.pay_order_endpoint}`,
            method: 'POST',
            data: {data},
            callback: transaction => {
                this.loading(false);
                this.transactionCallback(transaction.data, callback);
            },
            error: (status, error) =>  this.onNetworkError(status, error)
        });
        this.app.platform.sendEvent('pay_order');
    }

    pay(amount, currency, callback) {
        amount = amount || this.invoice.amount;
        currency = currency || this.invoice.currency;
        const data = {amount, currency, is_mobile: this.app.platform.is_mobile};
        if (this.data.profile_id) data.profile_id = this.data.profile_id;
        if (this.data.save_profile) data.save_profile = this.data.save_profile;
        this.resetTransactionCallbackFlags();
        this.is_payment_canceled = false;
        this.loading(true);
        this.app.ajax({
            url: `${this.app.settings.endpoints}${this.topup_balance_url}`,
            method: 'POST',
            data: {data},
            callback: transaction => {
                this.app.platform.sendEvent('pay');
                this.transactionCallback(transaction.data, callback);
            },
            error: (status, error) => this.onNetworkError(status, error)
        });
    }

    buySubscription(product_id, callback) {
        this.is_payment_canceled = false;
        this.resetTransactionCallbackFlags();
        this.loading(true);
        const data = {product_id, is_mobile: this.app.platform.is_mobile};
        if (this.data.profile_id) data.profile_id = this.data.profile_id;
        this.app.ajax({
            url: `${this.app.settings.endpoints}${this.buy_subscription_uri}`,
            method: 'POST',
            data: {data},
            callback: transaction => {
                this.app.platform.sendEvent('buy_subscription');
                this.loading(false);
                this.transactionCallback(transaction.data, callback);
            },
            error: (status, error) => this.onNetworkError(status, error)
        });
    }

    buyCheckins(product_id, callback) {
        const data = {product_id, is_mobile: this.app.platform.is_mobile};
        this.is_payment_canceled = false;
        this.resetTransactionCallbackFlags();
        if (this.data.profile_id) data.profile_id = this.data.profile_id;
        if (this.data.save_profile) data.save_profile = this.data.save_profile;
        this.loading(true);
        this.app.ajax({
            url: `${this.app.settings.endpoints}${this.buy_checkins_uri}`,
            method: 'POST',
            data: {data},
            callback: transaction => {
                this.app.platform.sendEvent('buy_checkins');
                this.loading(false);
                this.transactionCallback(transaction.data, callback);
            },
            error: (status, error) => this.onNetworkError(status, error)
        });
    }

    topup_balance(amount) {
        this.pay(amount, '');
    }

    pingTransactionTimeout() {
        const timeouts = [1, 1, 1, 1, 3, 3, 3, 3, 3, 3, 3];
        this.pingTransactionTimeoutIndex = this.pingTransactionTimeoutIndex || 0;
        this.pingTransactionTimeoutIndex += 1;

        if (this.pingTransactionTimeoutIndex <= timeouts.length) {
            return this.waiting_for_user_input
                ? timeouts[timeouts.length - 1] * 1000
                : null;
        }
        return timeouts[this.pingTransactionTimeoutIndex - 1] * 1000
    }

    pingTransaction(callback) {
        if (!this.data.transaction) {
            return;
        }
        this.app.ajax({
            url: `${this.app.settings.endpoints}${
                this.ping_transaction_uri
            }${this.data.transaction.id}/`,
            callback: data => this.transactionCallback(data.data, callback)
        });
    }

    static is_transaction_succes(status) {
        return new Set(['approved', 'finished']).has(status);
    }

    transactionCallback(data, callback) {
        if (this.is_payment_canceled) {
            console.log('transactionCallback ${tID}: Payment has been canceled');
            return;
        }
        data.status_text = this.status_tranlations[data.transaction_status];
        data.currency_sig = wallet.currencySign(data.currency);
        if (wallet.is_transaction_succes(data.transaction_status)) {
            data.transaction_status = 'finished';
        }
        this.data.transaction = data;
        const tID = data.id;

        const onFinishSuccess = () => {
            data.transaction_status = 'finished';
            data.status_text = this.status_tranlations[data.transaction_status];
            console.log(`transactionCallback ${tID}: onFinishSuccess`);
            const waitingForUserInput = this.waiting_for_user_input;
            this.resetTransaction();
            this.loading(false);
            if (waitingForUserInput) {
                if (callback) callback(data);
            } else {
                // for payments with presaved cards
                this.app.dialog({
                    name: data.status_text,
                    content: this.app.Mustache.render(
                        document.getElementById('payment_status_string').innerHTML,
                        data
                    ),
                    onClose: () => callback(data)
                });
            };
        };

        if (this.user_input_complete && data.transaction_status === 'prepared') {
            console.log(`Settings transaction status: canceled for ${tID}`);
            data.transaction_status = 'canceled';
        }

        if (data.transaction_status === 'prepared') {
            if (data.payment_url) {
                if (!this.waiting_for_user_input) {
                    this.waiting_for_user_input = true;
                    console.log(`transactionCallback ${tID}: transaction in progress, openning payment_url`);
                    this.app.platform.openPaymentWindow(data.payment_url, () => {
                        console.log(`openPaymentWindow ${tID}: paymentWindow closed`);
                        if (this.is_payment_canceled) {
                            console.log(`openPaymentWindow ${tID}: Already canceled`);
                        } else {
                            this.user_input_complete = true;
                            if (this.is_success) {
                                onFinishSuccess()
                            } else {
                                console.log(`openPaymentWindow ${tID}: Trying to update transaction`);
                                this.pingTransaction(callback);
                            }
                        }
                    });
                }
                const timeout = this.pingTransactionTimeout();
                if (timeout) {
                    console.log(`transaction ${tID} in progress, waiting ${timeout}`);
                    this.pingTransactionPending = setTimeout(
                        () => this.pingTransaction(callback),
                        timeout
                    );
                }
            } else {
                console.error('no url in transaction');
                onFinishSuccess();
            }
        } else if (data.transaction_status === 'finished') {
            console.log(`transaction completed ${tID}: status ${data.transaction_status}`);
            this.is_success = true;
            if (!this.waiting_for_user_input || this.user_input_complete) {
                onFinishSuccess();
            }
        } else if (data.transaction_status === 'canceled') {
            console.log(`transaction completed ${tID}: status ${data.transaction_status}`);
            this.is_payment_canceled = true;
            this.resetTransaction();
            this.loading(false);
            if (callback) callback(data);
        } else if (['refunded', 'failed'].indexOf(data.transaction_status) >= 0 ) {
            console.log(`transaction completed ${tID}: status ${data.transaction_status}`);
            this.data.is_transaction_failed = true;
            if (!this.waiting_for_user_input || this.user_input_complete) {
                this.resetTransactionCallbackFlags();
                this.loading(false);
                if (callback) callback(data);
            }
        } else {
            this.app.platform.sendEvent('payment_error');
            data.message = `unknown status for transaction ${data.id} ${data.status}`;
            this.app.error({
                title: 'payment transaction error',
                data
            });
            data.transaction_status = 'error';
            if (callback) callback(data);
        }
    }
}
