import {addEvent, deepcopy} from '../../helpers';
import GenericComponent from '../../GenericComponent';

export default class chat_widget_seatmap_request extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_seatmap_request'), data);

        if (!this.data.data.widget || !this.data.data.widget.seatmap) {
            console.error('No seatmap data');
            return;
        }

        return this;
    }

    get(forceUpdate, callback, fail) {
        const checkin_id = this.data.thread.checkinId;
        const {seatmap, passengers} = this.data.data.widget;

        if (!seatmap.sections || !seatmap.sections.length) {
            console.error('wrong seatmap format', seatmap, 'expected seatmap[]');
            if (fail) fail();
            return;
        }

        this.data.widget_uri = `checkins/${checkin_id}/chat_seatmap_request/${this.data.data.msgId}`;
        this.seatmap_request = this.app.initObject(
            this.data.widget_uri,
            'chat_seatmap_request',
            {
                data: {seatmap, passengers},
                checkin_id,
                isThisLastMessage: this.data.isThisLastMessage,
                chat: this.chat,
                clientId: this.data.data.clientId,
                clientThreadId: this.data.data.clientThreadId
            }
        );

        if (callback) callback();
    }

    bindLinks() {
        super.bindLinks();
        addEvent(
            this.view.querySelectorAll('[data-bind="show"]'),
            'click',
            event => {
                this.app.dialogComponent(
                    this.data.widget_uri,
                    'chat_seatmap_request',
                    {subclass: 'modal-dialog-seatmap-settings'},
                    this
                )
            }
        );
    }
}
