import GenericComponent from '../GenericComponent';
import passenger_document from './passenger_document';
import {addEvent, is_empty} from '../helpers';

export default class passenger extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'passenger'), data);
        this.init();
    }

    init() {
        this.is_initialized = true;
        this.types = passenger_document.documentTypes();
        if (!this.data || !this.data.data) return;
        this.types.forEach((type) => {
            if (!this.data.data[type] || !this.data.data[type].length) {
                this.data.data[type] = [{}];
            }
            this.data.data[type].forEach((doc, index) => {
                if (doc instanceof passenger_document) {
                    return;
                }
                if (this.data.data.user_request) {
                    const type_one = type.replace(/\w$/, '');
                    const requested_doc_id = this.data.data[`${type_one}_id`];
                    const ur = {};
                    Object.keys(this.data.data.user_request).forEach(key => {
                        if (key.match(type_one)) ur[key] = true;
                    });
                    if (!is_empty(ur)
                        && (!doc.id || doc.id === requested_doc_id)
                    ) {
                        doc.user_request = ur;
                    }
                }
                this.data.data[type].splice(index, 1,
                    this.create_document(type, {"data": doc})
                );
            });
        });
    }

    render(level) {
        this.data.has_email = this.data.data.email
            || this.data.data.email_in_verification;
        this.data.is_male = this.data.data.gender === 'male';
        this.data.is_female = this.data.data.gender === 'female';
        super.render(level);
        this.deleteConfirmation = this.deleteConfirmation
            && this.deleteConfirmation.replace(
                'PSG',
                `${this.data.data.last_name || ''} ${this.data.data.first_name || ''}`
                    .trim()
            );
        this.setDocumentViews();
    }

    setDocumentViews() {
        // setting default view for each docuemnt on the right side
        // of card
        const root = this.view.querySelector(
            this.data.edit_mode ? '.content.hidden' : '.content.visible'
        );
        if (!root) return;
        this.types.forEach(type => {
            if (this.data.data[type]) {
                this.data.data[type].forEach(doc => {
                    doc.view = root.querySelector(
                        `.subcomponent[data-uri="${doc.uri}"]`
                    );
                });
            }
        })
    }

    delete() {
        this.edit(false);
        super.delete();
    }

    edit(status = true) {
        const container = this.app.getObject('passengers_edit/');
        if (container) {
            container.data.passenger = status ? this : null;
            this.data.edit_mode = status;
            if (status) {
                container.render();
            } else {
                this.view = this.parent.parent.view.querySelector(
                    `[data-uri="${this.uri}"]`
                );
                this.render();
            }
        }
        this.parent.edit(status);
    }

    create_document(type, doc) {
        const url = doc.data.id
            ? `${this.uri}${type}/${doc.data.id}/`
            : this.new_document_url(type);
        if (!doc) {
            doc = {}
        }
        doc.document_type = type;
        const obj = this.app.initObject(url, 'passenger_document', doc);
        obj.passenger = this;
        obj.parent = this;
        return obj;
    }

    new_document_url(doc_type) {
        const uri = `${this.uri}${doc_type || ''}`;
        let new_uri;
        let i = 0;
        do {
            new_uri = `${uri}/new${i ? `-${i}` : ''}/`;
            i += 1;
        }
        while (this.app.getObject(new_uri) && i < 100);
        return new_uri;
    }

    bindLinks() {
        const that = this;
        super.bindLinks();
        addEvent(
            this.view.querySelectorAll('[data-bind="btn-add-document"]'), 'click',
            function onClick(event) {
                event.preventDefault();
                const doc_type = this.dataset.type || 'passports';
                that.data.data[doc_type].push(
                    that.create_document(doc_type, {
                        edit_mode: true,
                        is_new: true,
                        document_type_locked: false,
                        data: {}
                    })
                );
                that.render();
            }
        );
        addEvent(
            this.view.querySelectorAll('[data-bind="btn-stats"]'), 'click',
            () => this.app.platform.showPassengerStats(this.data.data.id)
        )
    }

    submitOnError(form, status, data) {
        super.submitOnError(form, status, data);
        if (
            status === 422 && data && (
                data.message_code === 'ValidationError'
                || data.message_code === 'FormError'
            )
        ) {
            // catch documents validation errors
            this.types.forEach(type => {
                if (!data.data[type]) return;
                Object.keys(data.data[type]).forEach(index => {
                    this.data.data[type][index].submitOnError(null, status, {
                        message_code: data.message_code,
                        data: data.data[type][index]
                    })
                });
            })
        }
    }

    formSubmit(form, event) {
        event.preventDefault();
        if (!this.validateForm(form)) return;
        const data = super.serializeChanges();
        const flags = {};
        // always need both last and first name
        if (data.last_name || data.first_name) {
            if (!data.last_name) data.last_name = this.data.data.last_name;
            if (!data.first_name) data.first_name = this.data.data.first_name;
        }
        this.types.forEach((type) => {
            if (this.data.data && this.data.data[type]) {
                this.data.data[type].forEach((doc) => {
                    const type = doc.get_document_type();
                    if (!data[type]) data[type] = [];
                    data[type].push(doc.serialize());
                    if (doc.changes && doc.changes.length) {
                        flags[type] = true;
                    }
                });
            }
        });
        this.types.forEach((type) => {
            if (!flags[type] && data[type]) {
                delete data[type];
            }
        });

        if (is_empty(data)) {
            console.log("No changes in psg skip POST/PUT");
            this.edit(false);
            this.update();
            return;
        }
        this.loading(true);
        const form_url = this.is_new ?
            this.uri.replace(/new.*$/, '') : this.uri;
        this.app.ajax({
            url: this.app.settings.endpoints + form_url,
            method: this.is_new ? 'POST' : 'PUT',
            data: {data},
            callback: (resp) => {
                this.loading(false);
                this.changes = [];
                this.data = resp;
                if (this.is_new) {
                    const new_uri = this.uri
                        .replace(/new\/\d*\/??$/, `${this.data.data.id}/`);
                    this.app.changeObjectURI(this, new_uri);
                    this.is_new = false;
                    this.app.platform.sendEvent('passenger_created');
                }
                this.init();
                this.update();
                this.edit(false);
            },
            error: (status, resp) => {
                this.submitOnError(form, status, resp);
            }
        });
    }
}