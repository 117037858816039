import {addClickEvent, toggleClass} from '../../helpers';
import wallet from '../wallet';
import GenericRestComponent from '../../GenericRestComponent';

export default class chat_widget_invoice extends GenericRestComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_invoice'), data);
    }

    loading(flag = true) {
        toggleClass(this.view, 'widget_loading', flag);
    }

    converter(data) {
        const widget = data.data;
        widget.is_paid = widget.status === 'paid';
        widget.amount = widget.amount || widget.price;
        widget.currency_sign = wallet.currencySign(widget.currency);
        if (widget.items) {
            widget.items.forEach(
                item => {
                    item.name = item.name
                        || this.chat.getTranslatedMessage(this.text);
                    item.currency_sign = wallet.currencySign(item.currency)
                }
            );
        }
        widget.type = this.data.data.widget.type;
        this.data.data.widget = widget;
        return this.data;
    }

    checkInvoiceLocked() {
        if (this.data.data.widget.is_paid) {
            this.data.wallet = null;
            this.data.locked = false;
            return true;
        }

        if (!this.data.isThisLastMessage()) {
            this.data.wallet = null;
            this.data.locked = true;
            return true;
        }
        return false;
    }


    render(level) {
        this.checkInvoiceLocked();
        super.render(level);
    }

    get(forceUpdate, callback, fail) {
        if (!this.data.data.widget) {
            console.error(`No invoice data in ${this.uri}`);
            return;
        }
        if (this.data.data.widget.id && (!this.updated || forceUpdate)) {
            this.text = this.data.data.widget.text;
            this.updated = true;
            this.endpoint_uri = `order/${this.data.data.widget.id}/`;
            console.log('invoice status before', this.data);
            super.get(forceUpdate, () => {
                console.log('updated invoice status ', this.data);
                this.initWallet(callback);
            }, fail)
        } else {
            this.data = this.converter({data: this.data.data.widget});
            this.initWallet(callback);
        }
        return this;
    }

    initWallet(cb) {
        if (this.checkInvoiceLocked()) {
            if (cb) cb()
            return;
        }
        const widget = this.data.data.widget;
        this.data.wallet = this.data.wallet
            || this.app.initObject(
                `order/${widget.id}/wallet/`, 'wallet', widget, false
            );
        this.data.wallet.get(false, () => {
            if (cb) cb()
        });
    }

    cancel(text) {
        this.chat.sendMessage(
            {text, inReplyTo: this.data.data.clientId},
            this.data.data.clientThreadId
        );
    }

    pay() {
        this.data.locked = true;
        this.render();

        this.data.wallet.pay_order(
            this.data.data.widget.id,
            ({transaction_status}) => {
                this.loading(false);
                switch (transaction_status) {
                    case 'finished':
                    case 'approved':
                        this.data.data.widget.is_paid = true;
                        this.data.data.widget.status = 'paid';
                        this.initWallet()
                        break;
                    case 'prepared':
                        break;
                    default:
                        this.data.locked = false;
                }
                this.render();
            }
        );
    }

    retry() {
        this.data.data.has_reply = false;
        this.data.locked = true;
        this.initWallet();
        this.render();
    }

    bindLinks() {
        addClickEvent(this.view, '[data-bind="pay"]', (event) => {
            event.preventDefault();
            this.pay();
        });
        addClickEvent(this.view, '[data-bind="cancel"]', (event) => {
            event.preventDefault();
            this.cancel(event.target.dataset.chatMessage)
        });
        addClickEvent(this.view, '[data-bind="retry"]', (event) => {
            event.preventDefault();
            this.retry()
        });
    }
}
