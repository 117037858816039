import GenericView from '../GenericView';
import flight from '../components/flight';

export default class threads_boardings extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'threads_boardings'), data);
        [, this.thread_id] = this.uri.match(/^\w+\/(\d+)\//);
        this.endpoint_uri = `threads/${this.thread_id}/`;
        this.template = 'threads';
    }

    converter(data) {
        return data.data.checkins;
    }

    get(forceUpdate, callback, fail) {
        super.get(forceUpdate, () => {
            this.data.is_single = true;
            const checkin = this.data[0];
            const checkinUri = `checkin_boardings/${checkin.id}/`;
            const obj = this.app.initObject(checkinUri, 'thread_boardings', {
                data: checkin,
                forceUpdate
            }, forceUpdate);
            this.data.threadTitle = flight.getFlightTitle(
                obj.data.data.first_flight
            );
            obj.parent = this;
            this.data.data = [obj];
            if (callback) callback();
        }, fail);
        return this;
    }
}
