import GenericComponent from '../GenericComponent';
import {toggleClass} from '../helpers';

export default class passengers_edit extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'passengers_edit'), data);
        this.template = 'passengers_edit';
    }

    edit(status = true) {
        this.parent && this.parent.edit(status);
        toggleClass(document.body, 'modal-open', status);
    }
}
