import thread from './thread';

export default class thread_new extends thread {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'thread_new'), data);
        this.template = 'thread';
        this.is_new = true;
        if (this.data) {
            this.data.edit_mode = true;
        }
    }

    get(forceUpdate, callback, fail) {
        if (!this.data.data) {
            this.data.data = {
                status: "new",
                is_in_progress: false,
                is_btn_psg_add: true,
                files: [],
                flights: [{}],
                passengers: [{}],
            };
        }

        let m = this.uri.match(/booking_ref=([^&]+)/);
        if (m) {
            this.data.data.flights[0].booking_ref = decodeURIComponent(m[1]);
        }
        m = this.uri.match(/last_name=([^&]+)/);
        if (m) {
            this.data.search_psg = decodeURIComponent(m[1]);
        }

        let callbackCount = this.data.data.flights.length
            + this.data.data.passengers.length + 1;
        const callbackController = () => {
            callbackCount--;
            if (!callbackCount && typeof (callback) === 'function') {
                this.loading(false);
                callback();
            }
        };
        this.loading(true);
        ['flights', 'passengers'].forEach(type => {
            this.data.data[type].forEach((el, index) => {
                const obj = this.app.initObject(
                    `${this.uri}${type}/new/`,
                    type === 'flights' ? this.flight_class : this.checkin_passenger_class,
                    {
                        edit_mode: true,
                        data: el
                    }
                )
                obj.parent = this;
                obj.is_new = true;
                this.data.data[type].splice(index, 1, obj);
                obj.get(false, callbackController, fail);
            });
        });
        callbackController();
        this.app.subscribe(`${this.uri}passengers/[^\\/]+/`, this);
        return this;
    }

    converter(data) {
        return data;
    }

    delete() {
        const message = this.deleteConfirmation
            || this.app.lang.deleteConfirmation;
        const flight = this.data.data.flights && this.data.data.flights[0];
        if (
            !this.is_new
            || (this.changes && this.changes.length)
            || (flight && flight.changes && flight.changes.length)
        ) {
            if (!this.app.confirm(message)) return;
        }
        ;
        this.changes = [];
        super.delete();
    }

    submitCallback(data) {
        this.loading(false);
        const parent = this.app.getObject('threads/');
        data.data.checkins.forEach(checkin => {
            const new_obj = this.app.initObject(
                `checkins/${checkin.id}/`,
                'thread',
                {data: checkin}
            );
            new_obj.parent = parent;
            parent.data.data.splice(0, 0, new_obj);
        });
        this.destroy();
        this.parent.edit(false);
        this.app.toggleMainMenu(true);
        parent.render();
        this.app.get('threads/');
    }

    formSubmit(form, event) {
        const that = this;
        let callbackCount = 1;
        if (!this.validateForm(form)) {
            const root = form || this.view;
            this.app.scrollTo(root.querySelector('.has-required'));
            return;
        }
        const waitCallbacks = () => {
            callbackCount--;
            console.log(`callback ${callbackCount}`);
            if (callbackCount) return;
            const data = {
                flights: [],
                passengers: []
            };
            this.data.data.flights.forEach(element => {
                data.flights.push(element.serialize());
            });
            this.data.data.passengers.forEach(element => {
                const psg = element.data.data;
                if (!psg.id) return;
                data.passengers.push({
                    id: psg.id,
                    eticket: psg.eticket,
                    visa_id: psg.visa_id,
                    passport_id: psg.passport_id,
                    ffn_id: psg.ffn_id
                });
            });
            this.data.need_passengers = data.passengers.length == 0;
            if (data.passengers.length) {
                this.app.ajax({
                    url: `${this.app.settings.endpoints}threads/`,
                    method: 'POST',
                    data: {data},
                    callback: data => {
                        this.loading(false);
                        this.app.platform.sendEvent('checkin_created_from_app');
                        this.submitCallback(data);
                    },
                    error: (status, data) => {
                        this.submitOnError(null, status, data);
                    }
                });
            } else {
                this.loading(false);
                this.render();
            }
        };
        this.data.data.passengers.forEach(psg => {
            if (psg.data.passenger) {
                callbackCount++;
                psg.on('update', waitCallbacks);
                psg.data.passenger.formSubmit(null, event);
                console.log(`passenger ${callbackCount}`);
            }
        });
        this.loading(true);
        waitCallbacks();
        console.log(`waitCallbacks ${callbackCount}`);
    }
}
