import GenericView from '../../GenericView';
import {addEvent} from '../../helpers';

export default class user_invite extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_invite'), data);
        this.auto_destroy = false;
        this.data = {
            data: {},
            link: this.app.settings.lang == 'ru'
                ? 'https://clck.ru/GYE9V'
                : 'https://bit.ly/2I9VFYq'
        }
    }

    get(forceUpdate, callback, fail) {
        this.app.getUserData(callback, fail, forceUpdate);
        return this;
    }

    formSubmit(form, event) {
        event.preventDefault();
        this.loading(true);
        const data = this.serialize(form);
        this.app.ajax({
            url: this.app.settings.endpoints + 'user/invite/',
            method: 'POST',
            data: {data},
            callback: (data) => {
                this.loading(false);
                this.formSaved();
            },
            error: (status, data) => {
                this.submitOnError(form, status, data);
            }
        });
    }

    bindLinks() {
        super.bindLinks();
        addEvent(
            this.view.querySelectorAll('[data-bind="share"]'),
            'click',
            event => {
                const textContainer = this.view.querySelector('[data-bind="promo-code"]');
                event.preventDefault();
                event.stopPropagation();
                this.app.platform.share(
                    textContainer.textContent.replace(/\s+/, ' ').trim(),
                    textContainer
                )
            }
        );
    }
}