import GenericComponent from '../GenericComponent';

export default class user_checkins_banner extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_checkins_banner'), data);
    }

    get(forceUpdate, callback, fail) {
        this.app.getUserData(callback, fail, forceUpdate);
        return this;
    }
}
