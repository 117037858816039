import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import App from './app';
import thread_new from './components/threads/thread_new';
import thread from './components/threads/thread';
import flight from './components/flight';
import checkin_passenger from './components/checkin_passenger';
import threads from './views/threads';
import passenger_document from './components/passenger_document';
import seatmap_strategies from './components/seatmap_strategies';
import seatmap_request from './components/seatmap_request';
import chat_seatmap_request from './components/chat/chat_seatmap_request';
import wallet from './components/wallet';
import about from './views/about';
import chat from './views/chat';
import lang_changed from './views/lang_changed';
import passenger_stats from './views/passenger_stats';
import tips from './components/tips';
import {
    configureFlatpickr,
    configureMetrika,
    configureMustache
} from './servicesConfigurators';
import passenger from './components/passenger';
import passengers from './views/passengers';
import passengers_edit from './components/passengers_edit';
import seatmap_preview from './components/seatmap_preview';
import seatmap_settings from './views/seatmap_settings';
import seatmap_settings_component from './components/seatmap_settings_component';
import thread_boardings from './components/threads/thread_boardings';
import threads_completed from './views/threads_completed';
import threads_upcoming from './views/threads_upcoming';
import single_thread from './views/single_thread';
import threads_boardings from './views/threads_boardings';
import threads_edit from './components/threads/threads_edit';
import threads_add_flight from './components/threads/threads_add_flight';
import login from './views/login';
import user_invite_help from './views/user/user_invite_help';
import user_invite from './views/user/user_invite';
import user_feedback from './views/user/user_feedback';
import user_checkins_banner from './components/user_checkins_banner';
import user_menu_view from './views/user/user_menu_view';
import user_subscription_banner from './components/user_subscription_banner';
import user_subscription_buy_modal from './components/user_subscription_buy_modal';
import user_checkins_help from './views/user/user_checkins_help';
import user_menu from './components/user_menu';
import user_buy_checkins from './views/user/user_buy_checkins';
import user_checkin_transactions from './views/user/user_checkin_transactions';
import user_checkins from './views/user/user_checkins';
import user_delete from './views/user/user_delete';
import user from './views/user/user';
import user_notifications from './views/user/user_notifications';
import user_after_delete from './views/user/user_after_delete';
import user_tos_and_privacy_policy from './views/user/user_tos_and_privacy_policy';
import user_seat_preferences from './views/user/user_seat_preferences';
import user_verification from './views/user/user_verification';
import user_password from './views/user/user_password';
import user_cards from './views/user/user_cards';
import error from './components/common/error';

const defaultClassRoutes = [
    {
        constructor: error, className: 'error', pattern: 'error-\\d+/'
    },
    {
        constructor: wallet, className: 'wallet', pattern: 'wallet/'
    },
    {
        constructor: chat, className: 'chat', pattern: 'chats/'
    },
    {
        constructor: user, className: 'user', pattern: 'user/$'
    },
    {
        constructor: user_password,
        className: 'user_password',
        pattern: 'user/password/'
    },
    {
        constructor: user_buy_checkins,
        className: 'user_buy_checkins',
        pattern: 'user/buy_checkins/'
    },
    {
        constructor: user_checkins,
        className: 'user_checkins',
        pattern: 'user/checkins/'
    },
    {
        constructor: user_checkin_transactions,
        className: 'user_checkin_transactions',
        pattern: 'user/checkin_transactions/(invites/)?$'
    },
    {
        constructor: user_checkins_help,
        className: 'user_checkins_help',
        pattern: 'user/checkins_help/'
    },
    {
        constructor: user_subscription_banner,
        className: 'user_subscription_banner',
        pattern: 'user_subscription_banner/'
    },
    {
        constructor: user_subscription_buy_modal,
        className: 'user_subscription_buy_modal',
        pattern: 'user_subscription_buy_modal/'
    },
    {
        constructor: user_checkins_banner,
        className: 'user_checkins_banner',
        pattern: 'user_checkins_banner/'
    },
    {
        constructor: user_cards,
        className: 'user_cards',
        pattern: 'user/cards/'
    },
    {
        constructor: user_verification,
        className: 'user_verification',
        pattern: 'user/verification/'
    },
    {
        constructor: user_password,
        className: 'user_password',
        pattern: 'user/password/'
    },
    {
        constructor: user_feedback,
        className: 'user_feedback',
        pattern: 'user/feedback/'
    },
    {
        constructor: user_invite,
        className: 'user_invite',
        pattern: 'user/invite/'
    },
    {
        constructor: user_invite_help,
        className: 'user_invite_help',
        pattern: 'user/invite_help/'
    },
    {
        constructor: user_tos_and_privacy_policy,
        className: 'user_tos_and_privacy_policy',
        pattern: 'user/tos_and_privacy_policy/'
    },
    {
        constructor: user_seat_preferences,
        className: 'user_seat_preferences',
        pattern: 'user/seat_preferences/'
    },
    {
        constructor: user_after_delete,
        className: 'user_after_delete',
        pattern: 'user/after_delete/'
    },
    {
        constructor: user_delete,
        className: 'user_delete',
        pattern: 'user/delete/'
    },
    {
        constructor: user_notifications,
        className: 'user_notifications',
        pattern: 'user/settings/'
    },
    {
        constructor: user_menu, className: 'user_menu', pattern: 'user_menu/'
    },
    {
        constructor: user_menu_view, className: 'user_menu_view', pattern: 'user/menu/'
    },
    {
        constructor: login, className: 'login', pattern: 'login/'
    },
    {
        constructor: about, className: 'about', pattern: 'user/about/'
    },
    {
        constructor: passenger, className: 'passenger',
    },
    {
        constructor: passengers, className: 'passengers', pattern: 'passengers/'
    },
    {
        constructor: passengers_edit, className: 'passengers_edit', pattern: 'passengers_edit/$'
    },
    {
        constructor: flight, className: 'flight', pattern: 'threads/\\d+/checkins/\\d+/flights'
    },
    {constructor: tips, className: 'tips', pattern: 'tips/'},
    {
        constructor: seatmap_request, className: 'seatmap_request',
    },
    {
        constructor: chat_seatmap_request, className: 'chat_seatmap_request',
    },
    {
        constructor: single_thread,
        className: 'single_thread',
        pattern: '(threads/|single_thread/)(completed/)?\\d+/$'
    },
    {
        constructor: single_thread,
        className: 'single_thread',
        pattern: '(threads/|single_thread/)(completed/)?\\d+/checkins/\\d+/$'
    },
    {
        constructor: threads_boardings,
        className: 'threads_boardings',
        pattern: '(threads/\\d+/)?checkins/\\d+/bpass/$'
    },
    {
        constructor: threads_add_flight,
        className: 'threads_add_flight',
    },
    {
        constructor: thread, className: 'thread',
    },
    {
        constructor: thread_boardings, className: 'thread_boardings',
    },
    {
        constructor: thread_new, className: 'thread_new',
    },
    {
        constructor: checkin_passenger,
        className: 'checkin_passenger',
        pattern: 'threads/.*?/passenger'
    },
    {
        constructor: passenger_document, className: 'passenger_document',
    },
    {
        constructor: seatmap_settings,
        className: 'seatmap_settings',
        pattern: '.*seatmap_preference'
    },
    {
        constructor: seatmap_settings_component,
        className: 'seatmap_settings_component',
        pattern: '.*seatmap_settings'
    },
    {
        constructor: seatmap_strategies,
        className: 'seatmap_strategies',
        pattern: '.*seatmap_preference/strategies/\\d+/'
    },
    {
        constructor: seatmap_preview,
        className: 'seatmap_preview',
        pattern: '.*seatmap_preview'
    },
    {
        constructor: threads_upcoming,
        className: 'threads_upcoming',
        pattern: 'threads/$'
    },
    {
        constructor: threads_completed,
        className: 'threads_completed',
        pattern: 'threads/completed/$'
    },
    {
        constructor: threads_edit,
        className: 'threads_edit',
        pattern: 'threads_edit/$'
    },
    {
        constructor: threads,
        className: 'threads',
    },
    {
        constructor: lang_changed,
        className: 'lang_changed',
        pattern: 'lang_changed'
    },
    {
        constructor: passenger_stats,
        className: 'passenger_stats',
        pattern: 'passenger_stats'
    }
];

function getJSON(list) {
    return list.reduce((a, v) => {
        try {
            a[v] = JSON.parse(document.getElementById(v).innerHTML);
        } catch (e) {
            a[v] = {};
        }
        return a;
    }, {});
}

export default function configure(config={}, classRoutes = defaultClassRoutes) {
    if (__CONFIG__.sentry.isEnabled) {
        console.log('configure sentry');
        const releasePrefix = `${
            __CONFIG__.mobile_app ? 'app' : 'web'
        }-${config.releasePrefix || 'profile-v1'}@`;

        Sentry.init({
            dsn: __CONFIG__.sentry.publicDSN,
            environment: __CONFIG__.sentry.environment,
            release: releasePrefix + __CONFIG__.sentry.release,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }

    const lang = config.lang || window.document.body.getAttribute('lang') || 'en';
    const template_url = config.template_url || __CONFIG__.template_url;
    const local = getJSON(['user_profile', 'user_request']);
    const Mustache = configureMustache();
    const flatpickr = configureFlatpickr(lang);
    if (__CONFIG__.mid) {
        console.log('configureMetrika');
        configureMetrika(__CONFIG__.mid);
    }
    const header = window.document.querySelector('header');
    const header_rect = header ? header.getBoundingClientRect() : {top: 0, height: 0};
    const scroll_top_shift = (header_rect.top < 10 ? header_rect.height : 42) + 10;
    const scroll_bottom_shift = (header_rect.top > 10 ? header_rect.height : 0) + 10;

    let views = document.querySelector('[data-bind="views"]');
    const createApp = () => {
        console.log(`starting profile widget token ${config.token}`);
        Object.assign(local, getJSON(['seatmap_strategies_dict', 'i18n']));
        const app = new App(
            'threads/',
            Object.assign(
                __CONFIG__,
                {
                    endpoints: __CONFIG__.endpoint,
                    xhr_timeout: 25000,
                    views,
                    token_header: 'X-Token',
                    user_request: local.user_request,
                    user_profile: local.user_profile,
                    seatmap_strategies: local.seatmap_strategies_dict,
                    lang,
                    Mustache,
                    flatpickr,
                    Sentry,
                    classRoutes,
                    scroll_top_shift,
                    scroll_bottom_shift,
                },
                config
            ),
            local.i18n
        );
        if (config.callback) {
            config.callback(app);
        }
        return app;
    };
    if (views) return createApp();

    if (config.root) {
        console.log(`downloading templates from ${template_url}`);
        const xhr = new XMLHttpRequest();
        xhr.open('GET', template_url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 299) {
                    console.log(`Template loaded ${template_url}`);
                    config.root.innerHTML = xhr.responseText;
                    views = config.root.querySelector('[data-bind="views"]');
                    createApp();
                } else if (xhr.status > 299) {
                    console.error(`Can't get template from ${
                        template_url}, http status: ${xhr.status}`);
                } else {
                    xhr.ontimeout();
                }
            }
        };
        xhr.ontimeout = () => {
            console.error(`Can't get template from ${template_url}, timeout`);
        };
        xhr.send();
        return true;
    }
    console.error('No root set');
    return null;
}
