import {addClickEvent} from '../../helpers';
import GenericComponent from '../../GenericComponent';

export default class chat_widget_form extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_form'), data);
    }

    render(level) {
        this.data.data.text = this.chat.getTranslatedMessage(this.data.data.text);
        this.data.data.widget.fields.forEach(field => {
            field.title = this.chat.getTranslatedMessage(field.title);
            if (field.options) {
                field.options.forEach(opt => {
                    opt.title = this.chat.getTranslatedMessage(opt);
                    opt.value = opt.value || opt.title;
                });
            }
            if (field.options) {
                field.is_select = true;
            } else if (field.type === 'textarea') {
                field.is_textarea = true
            } else {
                field.is_input = true;
            }
        });
        super.render(level);
    }

    formSubmit(formObj, event) {
        event.preventDefault();
        const form = this.serialize(formObj);
        const text = this.data.data.widget.fields.map(field => {
            const title = field.title || field.name;
            const value = typeof (form[field.name]) === 'object'
                ? form[field.name]
                : [form[field.name]];
            return `${title}: ${value.join('; ')}`
        }).join('\n');
        this.chat.sendMessage(
            {text, form, inReplyTo: this.data.data.clientId},
            this.data.data.clientThreadId
        );
    }

    bindLinks() {
        addClickEvent(this.view, '[data-bind="cancel"]', (event) => {
            event.preventDefault();
            this.chat.sendMessage(
                {
                    text: event.target.textContent,
                    value: 'Cancel',
                    inReplyTo: this.data.data.clientId
                },
                this.data.data.clientThreadId
            );
        });
    }
}
