import seatmap_request from '../seatmap_request';

export default class chat_seatmap_request extends seatmap_request {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_seatmap_request'), data);
        this.template = 'seatmap_request';
        this.isInChat = true;
        this.getTemplateTranslations();
    }

    get(forceUpdate, callback, fail) {
        this.getCheckinThread(() => {
            this.initSeatmap();
            if (callback) callback();
        }, fail);
    }

    render(level) {
        const passengers = this.data.data.passengers || [];
        super.render(level);
        this.data.submitDisabled = !passengers.every(psg => psg.seat);
        this.formSubmitToggle(this.data.submitDisabled);
    }
}
