import GenericView from '../../GenericView';
import wallet from '../../components/wallet';
import {addEvent, addClickEvent} from '../../helpers';

export default class user_cards extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_cards'), data);
    }

    get(forceUpdate, callback, fail) {
        super.get(forceUpdate, () => {
            if (!this.data.data || !this.data.data.length) {
                this.app.getUserData(() => {
                    const {billing} = this.app.settings.user_profile;
                    this.data.is_default_cur_eur = billing.currency === 'EUR';
                    this.data.is_default_cur_usd = billing.currency === 'USD';
                    this.data.is_default_cur_rub = billing.currency === 'RUB';
                }, fail);
            } else {
                this.data.data = this.data.data.filter(card => card.is_enabled);
                const lastCard = this.data.data[this.data.data.length - 1];
                this.data.is_default_cur_eur = lastCard.currency === 'EUR';
                this.data.is_default_cur_usd = lastCard.currency === 'USD';
                this.data.is_default_cur_rub = lastCard.currency === 'RUB';
            }
            callback();
        }, fail);
    }

    render() {
        if (this.data.data) {
            this.data.data.forEach(card => {
                card.payment_system_icon = wallet.icons(card.payment_system);
            });
        }
        this.app.platform.sendEvent('user_cards_shown');
        super.render();
    }

    initForm() {
        if (!this.data.data) {
            this.data.data = [];
        }
        addClickEvent(this.view, '[data-bind="btn-default"]', event => {
            const card_id = parseInt(event.target.dataset.id, 10);
            this.loading(true);
            this.app.ajax({
                url: `${this.app.settings.endpoints}${this.uri}${card_id}/`,
                method: 'PUT',
                callback: () => {
                    this.loading(false);
                    this.data.data.forEach(
                        card => card.is_default = (card.id == card_id)
                    );
                    this.render();
                },
                error: (status, data) => {
                    this.submitOnError(null, status, data);
                }
            });
        });
        addClickEvent(this.view, '[data-bind="btn-delete-card"]', event => {
            const card_id = parseInt(event.target.dataset.id, 10);
            const card = this.data.data.find(c => c.id == card_id);
            if (!confirm(`${event.target.dataset.confirm} ${card.payment_system} ${card.name}`)) {
                return;
            }
            this.loading(true);
            this.app.ajax({
                url: `${this.app.settings.endpoints}${this.uri}${card_id}/`,
                method: 'DELETE',
                callback: () => {
                    this.loading(false);
                    this.data.data.splice(this.data.data.findIndex(
                        c => c.id == card_id
                    ), 1);
                    this.render();
                },
                error: (status, data) => {
                    this.submitOnError(null, status, data);
                }
            });
        });
        addEvent(this.view.querySelectorAll('form'), 'submit', event => {
            event.preventDefault();
            const form = event.target;
            const data = {
                currency: form.currency.value,
                is_mobile: this.app.platform.is_mobile
            };
            this.loading(true);
            this.app.ajax({
                url: this.app.settings.endpoints + this.uri,
                method: 'POST',
                data: {data},
                callback: transaction => {
                    this.app.platform.sendEvent('link_card');
                    this.loading(false);
                    const w = new wallet(null, this.app, 'wallet', {
                        save_profile: true
                    });
                    w.transactionCallback(
                        transaction.data.inital_transaction,
                        result => {
                            this.get(true, () => {
                                this.data.failed_to_add_new_card = !wallet.is_transaction_succes(
                                    result.transaction_status
                                );
                                this.render();
                            }, this.onNetworkError);
                        }
                    );
                },
                error: (status, error) => {
                    this.submitOnError(form, status, error);
                }
            });
        });
        addEvent(this.view.querySelectorAll('input'), 'change', event => {
            this.data[event.target.name] = event.target.value;
        });
    }
}
