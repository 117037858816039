import GenericView from '../../GenericView';
import {addClickEvent} from '../../helpers';

export default class user_checkin_transactions extends GenericView {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'user_checkin_transactions'), data);
        this.endpoint_uri = 'user/checkin_transactions/';
        if (this.uri.match('/invites/')) {
            this.filter = true;
            this.uri = this.endpoint_uri;
        }
        this.translations = {
            topup: 'transaction_status_topup',
            package_topup: 'transaction_status_package_topup',
            reserve: 'transaction_status_reserve',
            free_subscription: 'transaction_status_free_subscription',
            success: 'transaction_status_success',
            release: 'transaction_status_release',
            monthly_free: 'transaction_status_monthly_free',
            bonus: 'transaction_status_bonus',
        };
        Object.keys(this.translations).forEach(key => {
            this.translations[key] = document.getElementById(this.translations[key])
                .innerHTML.trim();
        });
    }

    render(level) {
        const referral_code = this.app.settings.user_profile.referral_code;
        this.data.is_all = !this.filter;
        this.data.is_invites = this.filter;
        this.data.transactions = this.data.data.transactions.filter(t => {
            return this.filter
                ? (t.comment && t.comment.match(referral_code))
                : ['reserve', 'release'].indexOf(t.transaction_type) === -1
        }).reverse().map(t => {
            return {
                amount: t.amount, comment: t.comment,
                type: this.translations[t.transaction_type],
                created: t.created
            }
        });
        super.render(level);
    }

    bindLinks() {
        addClickEvent(this.view, '[data-bind="nofilter"]', () => {
            this.filter = false;
            this.render();
        });
        addClickEvent(this.view, '[data-bind="filter"]', () => {
            this.filter = true;
            this.render();
        });

        super.bindLinks();
    }
}