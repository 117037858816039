import GenericComponent from '../../GenericComponent';

export default class chat_widget_buy_checkins extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'chat_widget_buy_checkins'), data);
    }

    get(forceUpdate, callback, fail) {
        if (!this.data.isThisLastMessage()) delete this.data.data.widget;
        super.get(forceUpdate, callback, fail);
    }
}
